"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoadedSummary = exports.PageAnalyticsSummary = void 0;
var react_1 = __importStar(require("react"));
var luxon_1 = require("luxon");
var inversify_react_1 = require("inversify-react");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var event_tracker_types_1 = require("@qwilr/event-tracker-types");
var UsePageAnalytics_1 = require("./UsePageAnalytics");
var UseInteractionCounts_1 = require("../TimeSpentAndInteractions/UseInteractionCounts");
var Utils_1 = require("../Utils");
var TimeSpentAndInteractions_1 = require("../TimeSpentAndInteractions/TimeSpentAndInteractions");
var ActivityChart_1 = require("./ActivityChart/ActivityChart");
var NoViews_1 = require("../Components/EmptyState/NoViews");
var TimeZonePrompt_1 = require("./TimeZonePrompt");
var TimelineAndViewers_1 = require("./TimelineAndViewers");
var commonStyles = __importStar(require("../CommonStyles.css"));
var styles = __importStar(require("./PageAnalyticsSummary.css"));
var timeDurationOptions = {
    "1w": { label: "1w", durationObject: luxon_1.Duration.fromObject({ weeks: 1 }), timeUnit: "day" },
    "1m": { label: "1m", durationObject: luxon_1.Duration.fromObject({ months: 1 }), timeUnit: "day" },
    "1y": { label: "1y", durationObject: luxon_1.Duration.fromObject({ years: 1 }), timeUnit: "month" },
};
var timeDurationsOrder = ["1w", "1m", "1y"];
var LOCALSTORAGE_TIME_PERIOD_SELECTION = "page-analytics-timePeriodSelection";
var PageAnalyticsSummary = function (_a) {
    var pageId = _a.pageId, pageStatus = _a.pageStatus, isPageEditable = _a.isPageEditable, pageCreationDate = _a.pageCreationDate, allTimeViewCount = _a.allTimeViewCount, timeZone = _a.timeZone, timePeriodOffset = _a.timePeriodOffset, isAdmin = _a.isAdmin, setTimePeriodOffset = _a.setTimePeriodOffset, renderSharePopover = _a.renderSharePopover, openSession = _a.openSession, closeModal = _a.closeModal, openAuditTrailOverlay = _a.openAuditTrailOverlay, setSummaryEmptyState = _a.setSummaryEmptyState;
    var serverTelemetry = (0, inversify_react_1.useInjection)("serverTelemetry");
    var _b = __read((0, react_1.useState)(function () {
        var storedValue = localStorage.getItem(LOCALSTORAGE_TIME_PERIOD_SELECTION);
        if (storedValue && (storedValue === "1w" || storedValue === "1m" || storedValue === "1y")) {
            return storedValue;
        }
        return "1m";
    }), 2), timePeriodSelection = _b[0], setTimePeriodSelection = _b[1];
    (0, react_1.useEffect)(function () {
        localStorage.setItem(LOCALSTORAGE_TIME_PERIOD_SELECTION, timePeriodSelection);
    }, [timePeriodSelection]);
    var _c = getTimeInterval(timePeriodSelection, timeZone, timePeriodOffset), periodStart = _c.periodStart, periodEnd = _c.periodEnd, timeUnit = _c.timeUnit;
    var timeParams = { periodStart: periodStart, periodEnd: periodEnd, timeZone: timeZone, timeUnit: timeUnit };
    var pageAnalyticsSummary = (0, UsePageAnalytics_1.usePageAnalyticsSummary)(pageId, timeParams).pageAnalyticsSummary;
    return (react_1.default.createElement("div", { className: commonStyles.scrollContainer },
        react_1.default.createElement("div", { className: commonStyles.wrapper },
            react_1.default.createElement(kaleidoscope_1.Stack, { className: commonStyles.rootLayoutStack, gap: "l" },
                react_1.default.createElement(TimeZonePrompt_1.TimeZonePrompt, { timeZone: timeZone, closeModal: closeModal, isAdmin: isAdmin }),
                react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.stickyFiltersRow, direction: "horizontal", align: "center", justify: "spaceBetween", paddingTop: "s", paddingBottom: "s", gap: "xs" },
                    react_1.default.createElement(kaleidoscope_1.Heading, { className: styles.stickyFiltersHeading, strong: true, level: "3" }, "Page analytics"),
                    react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.filters, direction: "horizontal", align: "center", gap: "s" },
                        react_1.default.createElement(DatePicker, { periodStart: periodStart, periodEnd: periodEnd, timeZone: timeZone, timePeriodOffset: timePeriodOffset, pageCreationDate: pageCreationDate, onOffsetChange: setTimePeriodOffset }),
                        react_1.default.createElement(kaleidoscope_1.SegmentedControl, { className: styles.timePeriodSegmentedControl, label: "Time period selection", labelHidden: true, selectedValue: timePeriodSelection, onClickHandler: function (value) {
                                setTimePeriodOffset(0);
                                setTimePeriodSelection(value);
                                serverTelemetry.sendPageAnalyticsTelemetry({
                                    eventName: event_tracker_types_1.EventName.TimeFilterSelected,
                                    eventOrigin: event_tracker_types_1.EventOrigin.PageAnalytics,
                                });
                            }, options: timeDurationsOrder.map(function (t) { return ({ value: t, label: timeDurationOptions[t].label }); }) }))),
                !pageAnalyticsSummary ? (react_1.default.createElement(kaleidoscope_1.Spinner, { primary: true, size: kaleidoscope_1.SpinnerSize.Large })) : (react_1.default.createElement(exports.LoadedSummary, { pageId: pageId, pageAnalyticsSummary: pageAnalyticsSummary, pageStatus: pageStatus, isPageEditable: isPageEditable, allTimeViewCount: allTimeViewCount, timePeriodSelection: timePeriodSelection, timeParams: timeParams, renderSharePopover: renderSharePopover, openSession: openSession, serverTelemetry: serverTelemetry, openAuditTrailOverlay: openAuditTrailOverlay, setSummaryEmptyState: setSummaryEmptyState }))))));
};
exports.PageAnalyticsSummary = PageAnalyticsSummary;
var LoadedSummary = function (_a) {
    var pageId = _a.pageId, _b = _a.pageAnalyticsSummary, blockViewDurations = _b.blockViewDurations, overview = _b.overview, viewers = _b.viewers, activityChartData = _b.activityChartData, pageStatus = _a.pageStatus, isPageEditable = _a.isPageEditable, timePeriodSelection = _a.timePeriodSelection, timeParams = _a.timeParams, allTimeViewCount = _a.allTimeViewCount, openSession = _a.openSession, renderSharePopover = _a.renderSharePopover, serverTelemetry = _a.serverTelemetry, openAuditTrailOverlay = _a.openAuditTrailOverlay, setSummaryEmptyState = _a.setSummaryEmptyState;
    var noViewsYet = allTimeViewCount === 0 && overview.totalViews === 0;
    setSummaryEmptyState(noViewsYet);
    var noViewsComponent = undefined;
    if (noViewsYet) {
        noViewsComponent = (react_1.default.createElement(NoViews_1.NoViewsYet, { isPageEditable: isPageEditable, pageStatus: pageStatus, renderSharePopover: renderSharePopover }));
    }
    else if (overview.totalViews === 0) {
        noViewsComponent = react_1.default.createElement(NoViews_1.NoViewsInRange, null);
    }
    var period = { start: timeParams.periodStart.toString(), end: timeParams.periodEnd.toString() };
    return (react_1.default.createElement(kaleidoscope_1.Stack, { gap: { default: "m", tablet: "xxl" } },
        react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", justify: "spaceBetween", gap: { default: "xs", tablet: "m" } },
            react_1.default.createElement(OverviewCard, { heading: "Page views", icon: react_1.default.createElement(icons_1.Preview, { color: "default" }), data: "".concat(overview.totalViews, " views") }),
            react_1.default.createElement(OverviewCard, { heading: "Average time spent viewing", icon: react_1.default.createElement(icons_1.Clock, { color: "default" }), data: "".concat((0, Utils_1.formatViewDuration)(overview.averageViewDuration)) }),
            react_1.default.createElement(InteractionsCountCard, { pageId: pageId, period: period })),
        react_1.default.createElement(kaleidoscope_1.Card, { className: styles.activityChartContainer },
            react_1.default.createElement(ActivityChart_1.ActivityChart, { chartData: activityChartData, timePeriodSelection: timePeriodSelection, timeParams: timeParams, noViewsYetComponent: noViewsYet ? noViewsComponent : undefined, openSession: function (viewer, pageViewId) {
                    openSession(viewer, pageViewId);
                    serverTelemetry.sendPageAnalyticsTelemetry({
                        eventName: event_tracker_types_1.EventName.PageAnalyticsSessionViewViewed,
                        eventOrigin: event_tracker_types_1.EventOrigin.QwilrChart,
                    });
                } })),
        react_1.default.createElement(TimelineAndViewers_1.TimelineAndViewers, { pageId: pageId, timeParams: timeParams, viewers: viewers, noViewsComponent: noViewsComponent, openSession: openSession, openAuditTrailOverlay: openAuditTrailOverlay }),
        react_1.default.createElement(TimeSpentAndInteractions_1.TimeSpentAndInteractions, { blockViewDurations: blockViewDurations, noViewsYetComponent: noViewsComponent, interactionsTableProps: {
                pageId: pageId,
                period: period,
            } })));
};
exports.LoadedSummary = LoadedSummary;
var InteractionsCountCard = function (_a) {
    var pageId = _a.pageId, period = _a.period;
    var interactionCounts = (0, UseInteractionCounts_1.useInteractionCounts)({
        pageId: pageId,
        periodStart: period.start,
        periodEnd: period.end,
    }).interactionCounts;
    var data = !!interactionCounts
        ? "".concat(interactionCounts.reduce(function (totalCount, interaction) { return totalCount + interaction.count; }, 0))
        : undefined;
    return react_1.default.createElement(OverviewCard, { heading: "Interactions", icon: react_1.default.createElement(icons_1.SelectionType, { color: "default" }), data: data });
};
var OverviewCard = function (_a) {
    var heading = _a.heading, icon = _a.icon, data = _a.data;
    return (react_1.default.createElement(kaleidoscope_1.Card, { className: styles.overviewCard },
        react_1.default.createElement(kaleidoscope_1.Stack, { gap: "xs", justify: "spaceBetween" },
            react_1.default.createElement(kaleidoscope_1.Text, { strong: true, size: "xs", element: "div", align: "left" }, heading),
            react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", gap: { default: "xxs", tablet: "xs" }, align: "center" },
                icon,
                !!data ? react_1.default.createElement(kaleidoscope_1.Heading, { level: "4" }, data) : react_1.default.createElement(kaleidoscope_1.GhostBlock, { className: styles.overviewLoadingGhost })))));
};
var DatePicker = function (_a) {
    var periodStart = _a.periodStart, periodEnd = _a.periodEnd, timeZone = _a.timeZone, timePeriodOffset = _a.timePeriodOffset, pageCreationDate = _a.pageCreationDate, onOffsetChange = _a.onOffsetChange;
    var isBeforePageCreation = periodStart.toMillis() < pageCreationDate.getTime();
    return (react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal" },
        react_1.default.createElement(kaleidoscope_1.IconButton, { icon: react_1.default.createElement(icons_1.ChevronLeft, null), type: kaleidoscope_1.ButtonType.Secondary, disabled: isBeforePageCreation, disabledReason: "Page was created ".concat((0, Utils_1.formatDatetime)(pageCreationDate, timeZone, true)), tooltip: isBeforePageCreation ? { content: "", size: kaleidoscope_1.TooltipSize.Large } : undefined, onClick: function () { return onOffsetChange(timePeriodOffset - 1); } }),
        react_1.default.createElement(kaleidoscope_1.Button, { type: kaleidoscope_1.ButtonType.Secondary },
            react_1.default.createElement(icons_1.Calendar, { color: "default" }),
            formatDatePicker(periodStart.toJSDate(), periodEnd.minus({ milliseconds: 1 }).toJSDate(), timeZone)),
        react_1.default.createElement(kaleidoscope_1.IconButton, { icon: react_1.default.createElement(icons_1.ChevronRight, null), type: kaleidoscope_1.ButtonType.Secondary, disabled: timePeriodOffset >= 0, onClick: function () { return onOffsetChange(timePeriodOffset + 1); } })));
};
function formatDatePicker(periodStart, periodEnd, timeZone) {
    var isDifferentYear = periodStart.getFullYear() !== periodEnd.getFullYear();
    return "".concat(formatDatePickerDate(periodStart, isDifferentYear, timeZone), " \u2014 ").concat(formatDatePickerDate(periodEnd, true, timeZone));
}
function formatDatePickerDate(date, showYear, timeZone) {
    return new Intl.DateTimeFormat(undefined, {
        day: "numeric",
        month: "short",
        year: showYear ? "numeric" : undefined,
        timeZone: timeZone,
    }).format(date);
}
function getTimeInterval(timePeriod, timeZone, timePeriodOffset) {
    var _a;
    var _b = timeDurationOptions[timePeriod], durationObject = _b.durationObject, timeUnit = _b.timeUnit;
    var offsetDuration = durationObject.mapUnits(function (x) { return x * timePeriodOffset; });
    var periodEndDateTime = luxon_1.DateTime.now()
        .setZone(timeZone)
        .startOf(timeUnit)
        .plus((_a = {}, _a[timeUnit] = 1, _a))
        .plus(offsetDuration);
    var periodStartDateTime = periodEndDateTime.minus(durationObject);
    return {
        periodStart: periodStartDateTime,
        periodEnd: periodEndDateTime,
        timeUnit: timeUnit,
    };
}
