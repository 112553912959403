"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Prompt = void 0;
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var react_1 = __importStar(require("react"));
var react_transition_group_1 = require("react-transition-group");
var styles = __importStar(require("./Prompt.css"));
var utils_1 = require("@qwilr/kaleidoscope/utils");
var react_dom_1 = require("react-dom");
var dynamic_1 = require("@vanilla-extract/dynamic");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var CARET_SIZE = 8;
var Prompt = function (_a) {
    var show = _a.show, children = _a.children, targetRef = _a.targetRef, onDismiss = _a.onDismiss;
    var _b = (0, utils_1.useOverlayPosition)({
        offset: 4,
        caretSize: CARET_SIZE,
        position: "right",
    }), overlayProps = _b.overlayProps, overlayRef = _b.overlayRef, setTargetRefForOverlay = _b.targetRef;
    (0, react_1.useEffect)(function () {
        setTargetRefForOverlay(targetRef.current);
    }, [targetRef]);
    var portalRoot = (0, utils_1.getPortalRoot)("prompt");
    if (!portalRoot) {
        return null;
    }
    return (0, react_dom_1.createPortal)(react_1.default.createElement(react_transition_group_1.Transition, { mountOnEnter: true, unmountOnExit: true, in: show, timeout: kaleidoscope_1.AnimationDuration.Medium }, function (transitionStatus) {
        var _a;
        return (react_1.default.createElement("div", { className: styles.overlay({ status: transitionStatus }), ref: overlayRef, style: __assign({}, overlayProps === null || overlayProps === void 0 ? void 0 : overlayProps.overlay) },
            react_1.default.createElement(kaleidoscope_1.Card, { className: styles.card, elevation: 2 },
                react_1.default.createElement("div", { className: styles.closeButton },
                    react_1.default.createElement(kaleidoscope_1.IconButton, { "aria-label": "Dismiss", onClick: onDismiss, icon: react_1.default.createElement(icons_1.Close, null), isRound: true })),
                children,
                react_1.default.createElement("div", { className: styles.caret({ position: overlayProps === null || overlayProps === void 0 ? void 0 : overlayProps.modifier, status: transitionStatus }), style: (0, dynamic_1.assignInlineVars)((_a = {},
                        _a[styles.caretSize] = "".concat(CARET_SIZE, "px"),
                        _a[styles.caretTop] = "".concat(overlayProps === null || overlayProps === void 0 ? void 0 : overlayProps.caret.top, "px"),
                        _a[styles.caretLeft] = "".concat(overlayProps === null || overlayProps === void 0 ? void 0 : overlayProps.caret.left, "px"),
                        _a)) }))));
    }), portalRoot);
};
exports.Prompt = Prompt;
