import "Application/Onboarding/CensusCollector/MakeQwilrYourOwn/LogoUploader.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Onboarding/CensusCollector/MakeQwilrYourOwn/LogoUploader.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VWQY/TOhC+768YIa3ULHibdGlZgvQE3LjB4wInNEmcxq1j59lOm4L4709xnMbtpt0tIHFq47G/+WbmG4/frukuV1hSDerlbHcXwY8rgPDa/gDIClNmdjGEb64Afl4BROFDY9QZf17dWoyZtW9ZZorY7m/NFWYZE8sYNqgmhOj2rFiSRn/7FiX81WoVRSxo3fQodxalZII4pNliXjWD/aW1p5JLFcO2YIa2bkpUSyZIIo2R5bEzz9cqGJDmI0i9bWFtjjzhNDcxpMjTySFyOSCvA3gOs6oJHqVzGPvg85WfP+tsdreoGngOkye4hRuYBdZ5QdmyMDFEi/s2cV4NwktikBuqci63MRQsy6gYiN77RC3HA7d95aVmhkkRg6IcDdt46X1tEQ5VNuzHREted4X9TpjIaOPEpjoXZwJodxlZPbonkQ3RBWZtfG6r/SKU0w22PGbDkdQdURlVMQgpvFDu46LNFIwGFg378PdCXoRdlv9ibHgytuQXY7PBzDu5/DZpAKNQ6FyqMu7+cjT064TMw+tgb3acHFfnKauV9eDfFDpwRoq6zXKO2sjafOZyy8SwrQpeeNT/BN6p9Ccn059ai3dJhVUD5JxKPCeZPexUdvFp+qDyvSU/aVmNzYnj2wMFK9FdH92ACm8X+uIUrp/iK2O64riLIefUNtmq1oblO5JKYaho80KFocry4mwpCDO01P5yL0UtOcsgqhrH1E4XUilWotq9t5sGoh5P3s2iWul2GFWSdcC9uRxpsH1Q16fbbR/ZYV8LC/doGsb66cvEamRC7IPgxoVpUcMgsLPqacPq6OANkCgIHvTpnsJxZ/EBtb68swT8Azdj0vDeHd3SwYlYSDOJOWpD0oLxLOjeKt2w91voKRO2R5ZjPDo9EYUZq3V/IXaL/9o1omuVY0oH6Dz4A33z60oGSDBdL5WsRRZDrfjk2fSd1tTo6YcSl1RPpUgkqvYlMi1xTT9tGVdfZa301Fa5QkWFIQPKrd4snwWgaEXRDPmqRsV7lD+ZrGhqSM7aikhhkIkzr9ETD8T/rKdLbgcnhceH9N6Fu+LPdr86VMjxc6v77uvWVqyrnZfVEUm5ses3G3IO0TwsNVDUlMjaHJaVuLeyLwhDG/ORY0oLyX1JJF6Q2vI/j8NSOUjRtur/KbAzXKMMAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var logoIndex = 'var(--r42y30)';
export var root = 'r42y32';
export var logoTooltip = 'r42y33';
export var tooltipHeading = 'r42y34';
export var tooltipText = 'r42y35';
export var label = 'r42y36';
export var container = 'r42y37';
export var content = 'r42y38';
export var deleteButton = 'r42y39';
export var smallUploadButton = 'r42y3a';
export var sliderButton = _7a468({defaultClassName:'r42y3b',variantClassNames:{position:{left:'r42y3c',right:'r42y3d'},status:{exiting:'r42y3e',exited:'r42y3f',entering:'r42y3g',entered:'r42y3h',unmounted:'r42y3i'}},defaultVariants:{},compoundVariants:[]});
export var emptyCardWrapper = 'r42y3j';
export var emptyCard = 'r42y3k';
export var uploadButton = 'r42y3l';
export var uploadInput = 'r42y3m';
export var logoSlider = 'r42y3n';
export var logoCard = 'r42y3o';
export var logo = 'r42y3p';
export var sliderCount = 'r42y3q';
export var sliderCountElement = _7a468({defaultClassName:'r42y3r',variantClassNames:{position:{current:'r42y3s','default':'r42y3t'}},defaultVariants:{},compoundVariants:[]});