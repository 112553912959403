import "Application/Library/AutomationLibrary/CreateOrEditAutomationModal.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Library/AutomationLibrary/CreateOrEditAutomationModal.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61Uy07DMBC89ytWQkhwcJUAJTQ99kMq13bcLY5t+dGmIP4dOYWQQCitxClSvDM7O7P2dJVXcb/bvWQwDXtDLNVCES9CQC09qQ2narVyKDcBXicAa8qepTNRc8KMMq6Eq4pWrOKLCcAeediUkGfZ9WLyNpl+cuct9KN+R90NOYJJEE1YOgzIUpd8rYrtNp/fDsB3LZijt4oeSqiUaFKv9CUcnWABjS4Te6x1OpHUfnbxljLUknyR52rIfv//7J0PxSyzLVtNnURdQgY0BjPo//AP/Rv/JQCH481+cx6Z0R3KDkGPx6SN48KVkNsGvFHIYdH9JSOMx5OO83DbK3eUY/Q/dPeFb9p6SzlHLf+acMQxqlBqgkHUvgQmdBDucruK8TROcRcp4h7F0y/XpG+Vj66iTCxpTOl2atT4ZJ0nhW0gvzuu1Gljf9h1gTu9ba5b7Db6gNWBMKOD0KGEVCrIWoS9EHow/Pxy/87Ohp58RL5Z+TTErs8IJd2I7zRxbIln6XUbVfKRaweXScU7Azo4rGIFAAA=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var modalWrapper = '_1fuwvvz0';
export var errorText = '_1fuwvvz1';
export var leftPanelWrapper = '_1fuwvvz2';
export var rightPanelWrapper = '_1fuwvvz3';
export var automationDetails = '_1fuwvvz4';
export var chevron = '_1fuwvvz5';
export var automationIcons = '_1fuwvvz6';
export var iconGroup = '_1fuwvvz7';
export var integrationAlert = '_1fuwvvz8';
export var integrationAlertLeft = '_1fuwvvz9';
export var integrationAlertLabel = '_1fuwvvza';
export var alertIcon = '_1fuwvvzb';