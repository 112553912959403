(function () {
  var CancelSubscription,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = CancelSubscription = (function () {
    var cancelScreen;

    function CancelSubscription(
      $http,
      apiBaseUrl,
      internalSlack,
      environment,
      user,
      make,
      account,
      telemetry,
      SubscriptionSequencer,
      publicApiBaseUrl,
      createUi,
      showAcknowledgeModal,
      overlayStack,
    ) {
      this.$http = $http;
      this.apiBaseUrl = apiBaseUrl;
      this.internalSlack = internalSlack;
      this.environment = environment;
      this.user = user;
      this.make = make;
      this.account = account;
      this.telemetry = telemetry;
      this.SubscriptionSequencer = SubscriptionSequencer;
      this.publicApiBaseUrl = publicApiBaseUrl;
      this.createUi = createUi;
      this.showAcknowledgeModal = showAcknowledgeModal;
      this.overlayStack = overlayStack;
      this.cancel = bind(this.cancel, this);
    }

    cancelScreen = require("SettingsAngular/Subscription/Cancel/attach")["default"];

    CancelSubscription.prototype.cancel = function () {
      var sequence;
      this.telemetry.trackOnly("Subscription Cancellation Flow Started");
      return (sequence = this.make(this.SubscriptionSequencer, {
        steps: [cancelScreen],
        options: {
          onHitEnd: (function (_this) {
            return function () {
              window.location = "#";
              return window.location.reload();
            };
          })(this),
        },
      }));
    };

    return CancelSubscription;
  })();
}.call(this));
