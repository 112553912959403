"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableTheme = exports.TableStyleStore = void 0;
var mobx_1 = require("mobx");
var GetBlockStyle_1 = require("@CommonFrontendBackend/StyleServiceV2/GetBlockStyle");
var ColorUtils_1 = require("@CommonFrontendBackend/StyleServiceV2/ColorUtils");
var TableTheme;
(function (TableTheme) {
    TableTheme[TableTheme["Light"] = 0] = "Light";
    TableTheme[TableTheme["Dark"] = 1] = "Dark";
})(TableTheme || (exports.TableTheme = TableTheme = {}));
var TableStyleStore = (function () {
    function TableStyleStore(brandStore, blockStyleStore) {
        this.brandStore = brandStore;
        this.blockStyleStore = blockStyleStore;
    }
    TableStyleStore.prototype.determineTheme = function (background) {
        var color = (0, GetBlockStyle_1.GetColor)(this.brandStore.allColors)(background);
        return (0, ColorUtils_1.shouldUseDarkText)(color) ? TableTheme.Light : TableTheme.Dark;
    };
    Object.defineProperty(TableStyleStore.prototype, "backgroundTheme", {
        get: function () {
            var tableBackground = this.blockStyleStore.getElementStyle("tableBackground");
            if (tableBackground === null || tableBackground === void 0 ? void 0 : tableBackground.on) {
                return this.determineTheme(tableBackground.color);
            }
            var blockBackground = this.blockStyleStore.getElementStyle("background");
            if (blockBackground) {
                return this.determineTheme(blockBackground);
            }
            return TableTheme.Light;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TableStyleStore.prototype, "headerTheme", {
        get: function () {
            var tableHeader = this.blockStyleStore.getElementStyle("tableHeader");
            if (tableHeader === null || tableHeader === void 0 ? void 0 : tableHeader.on) {
                return this.determineTheme(tableHeader.color);
            }
            var blockBackground = this.blockStyleStore.getElementStyle("background");
            if (blockBackground) {
                return this.determineTheme(blockBackground);
            }
            return TableTheme.Light;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], TableStyleStore.prototype, "backgroundTheme", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], TableStyleStore.prototype, "headerTheme", null);
    return TableStyleStore;
}());
exports.TableStyleStore = TableStyleStore;
