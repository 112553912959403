import "Application/Onboarding/CensusCollector/MakeQwilrYourOwn/MakeQwilrYourOwn.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Onboarding/CensusCollector/MakeQwilrYourOwn/MakeQwilrYourOwn.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VUzY7bIBi85ymQqkrxgQhnsxsvuVTqI/TQQ1VFGLBNgsECvEla5d0r4/wY7M226skyzMw38A3fYpsKo2XzisDvGQBM2EaSEwaF5MfNDPgvZMJw6oRWGFAt21p1O0SKUkHheG0xoFw5bjaz82xxFUy9YE1MKRTMtXO6xuCNmDmEtiFUqBLK7TbN5Xq3S1OZBOSlJx8EcxUGKUKfN1Pmdq11ojhBqpXjyk3aeOptCAUvak8pajy7IYwJVcamjgNXdQJQoLbyark2jBsMlFa8U6q4KCuHQdoLXwpRIuk8RejtAGBcY1hEJUnHygndl0a3ikGqpTYYfMpSssyXWWjh2Vt4z/zR3oWrJN4dbApf9NIep5tYaQDdDaHTnazv6L1HO3500Edksisv/T2Ojtyr+h9oW1MQyr+1lHJ7t7MPk7L+e6WvpO1CfFOKMkf6zFXCcX8ujkFjODwY0gS43OMmynRnjt2uwxr0ETf2l4VcFr5QoaTo4xd1Bq66GA6I/F8NA1Bo5eDhEuue0i199yvQ8lrkWrIbafkrtFoM377khcMAPqPIVvkoyMFoiB5h9fAFBBmPmOJ/ZpIAi9yQa8LsdttfIiSMDaYCtO4kOQZWS8EC/m5YfOLFvV9ZYaXd/AcTluSSs59J39DW2K6jjBekla5jfKk5EwTMB+NujVBz7AnRTAynYnaZip3D42j1PKSvrvQ7MHuZAmY3YHDhL6sJ7GuI9feDNlN0NOIWIbcP3Bgmpu2MgfsJL/GcHU5wnYwk5IcSowF7np3/AEmsZiSOBwAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var root = '_1irolp90';
export var heading = '_1irolp91';
export var urlInputContainer = '_1irolp92';
export var urlInput = '_1irolp93';
export var separator = '_1irolp94';
export var informationSection = _7a468({defaultClassName:'_1irolp95',variantClassNames:{status:{success:'_1irolp96',failed:'_1irolp97'},style:{relaxed:'_1irolp98',compact:'_1irolp99'}},defaultVariants:{},compoundVariants:[]});
export var informationSectionText = _7a468({defaultClassName:'_1irolp9a',variantClassNames:{status:{success:'_1irolp9b',failed:'_1irolp9c'}},defaultVariants:{},compoundVariants:[]});
export var informationSectionIcon = '_1irolp9d';
export var tryItAgainLink = '_1irolp9e';
export var logoUploader = '_1irolp9f';
export var brandSetup = '_1irolp9g';
export var brandColorsAndSubdomain = '_1irolp9h';
export var brandColors = '_1irolp9i';
export var buttonGroup = _7a468({defaultClassName:'_1irolp9j',variantClassNames:{style:{relaxed:'_1irolp9k',compact:'_1irolp9l'}},defaultVariants:{},compoundVariants:[]});
export var submitButton = _7a468({defaultClassName:'_1irolp9m',variantClassNames:{status:{disabled:'_1irolp9n',enabled:'_1irolp9o'}},defaultVariants:{},compoundVariants:[]});