"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Status = exports.StyleAccessType = exports.TeamType = void 0;
var TeamType;
(function (TeamType) {
    TeamType["Account"] = "account";
    TeamType["Normal"] = "normal";
})(TeamType || (exports.TeamType = TeamType = {}));
var StyleAccessType;
(function (StyleAccessType) {
    StyleAccessType["Block"] = "block";
    StyleAccessType["Text"] = "text";
    StyleAccessType["Motion"] = "motion";
    StyleAccessType["StyleLibrary"] = "styleLibrary";
    StyleAccessType["BlockCustomColors"] = "blockCustomColors";
    StyleAccessType["Toolbar"] = "toolbar";
    StyleAccessType["ToolbarTable"] = "toolbarTable";
    StyleAccessType["ToolbarQuote"] = "toolbarQuote";
    StyleAccessType["ToolbarAccept"] = "toolbarAccept";
    StyleAccessType["CSS"] = "css";
    StyleAccessType["Accordion"] = "accordion";
    StyleAccessType["ContentCustomColors"] = "contentCustomColors";
})(StyleAccessType || (exports.StyleAccessType = StyleAccessType = {}));
var Status;
(function (Status) {
    Status["Closed"] = "closed";
    Status["Open"] = "open";
    Status["Expired"] = "expired";
    Status["Blocked"] = "blocked";
})(Status || (exports.Status = Status = {}));
