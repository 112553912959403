"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentSidebarTelemetry = void 0;
var ContentSidebarTelemetry = (function () {
    function ContentSidebarTelemetry(telemetry, editorState) {
        var _this = this;
        this.telemetry = telemetry;
        this.editorState = editorState;
        this.sidebarAssetAdded = function (assetType, insertMethod) {
            _this.telemetry.trackOnly("Sidebar Asset Added", {
                sidebar_asset_added_asset_type: assetType,
                sidebar_asset_added_origin: insertMethod,
                sidebar_asset_added_sidebar_session_duration: _this.editorState.getSidebarSessionTime(),
            }, { blockIntercom: true });
        };
    }
    return ContentSidebarTelemetry;
}());
exports.ContentSidebarTelemetry = ContentSidebarTelemetry;
