// Generated by CoffeeScript 1.12.7

/*
  This module defines the Block Menu for a Text Block.
 */

(function () {
  module.exports = function (make, StyleButton, BlockControls, usingFeature) {
    var blockControls, styleButton, superpowers;
    styleButton = make(StyleButton);
    blockControls = make(BlockControls);
    superpowers = [
      {
        name: "Style library",
        ctrl: styleButton,
        selectable: false,
        select: styleButton.onClick,
        deselect: styleButton.onClose,
        overflow: true,
      },
      {
        name: "Block Controls",
        ctrl: blockControls,
        doNotWrapControl: true,
        visibleOnViewOnlyBlocks: true,
      },
    ];
    return {
      settings: {
        useItems: ["foldable", "navigationMenu"],
        superpowers: superpowers,
      },
    };
  };
}.call(this));
