"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeMark = exports.addMark = exports.getCurrentMarks = void 0;
var slate_1 = require("slate");
var Text_1 = require("ContentTokens/Models/Text");
var lodash_1 = require("lodash");
var CommentMark_1 = require("../Comments/CommentMark");
var getCurrentMarks = function (editor) {
    var _a, _b;
    var selection = editor.selection;
    if (!selection) {
        return [];
    }
    if (slate_1.Range.isExpanded(selection)) {
        var nodes = slate_1.Editor.nodes(editor, {
            match: Text_1.Text.isText,
        });
        if (!nodes) {
            return [];
        }
        var nodeMarks = Array.from(nodes).map(function (nodeEntry) {
            var _a = __read(nodeEntry, 1), node = _a[0];
            return node.marks;
        });
        var appliedMarks = lodash_1.intersectionBy.apply(void 0, __spreadArray(__spreadArray([], __read(nodeMarks), false), [getMarkKey], false));
        return appliedMarks;
    }
    var marks = (_b = (_a = slate_1.Editor.marks(editor)) === null || _a === void 0 ? void 0 : _a.marks) !== null && _b !== void 0 ? _b : [];
    return marks;
};
exports.getCurrentMarks = getCurrentMarks;
var addMark = function (mark, editor) {
    var selection = editor.selection;
    if (selection) {
        if (slate_1.Range.isExpanded(selection)) {
            slate_1.Editor.withoutNormalizing(editor, function () {
                slate_1.Transforms.setNodes(editor, null, { match: Text_1.Text.isText, split: true });
                Array.from(slate_1.Editor.nodes(editor, {
                    match: Text_1.Text.isText,
                })).forEach(function (entry) {
                    var _a = __read(entry, 2), text = _a[0], path = _a[1];
                    var marks = text.marks;
                    if (!marks.some(function (m) { return isSameMark(mark, m); })) {
                        var newMarks = marks.concat(mark);
                        slate_1.Transforms.setNodes(editor, { marks: newMarks }, {
                            at: path,
                        });
                    }
                });
            });
        }
        else {
            var currentMarks = getCurrentMarks(editor);
            var newMarks = __spreadArray(__spreadArray([], __read(currentMarks), false), [mark], false);
            editor.marks = { marks: newMarks };
        }
    }
};
exports.addMark = addMark;
var removeMark = function (mark, editor) {
    var selection = editor.selection;
    if (selection) {
        if (slate_1.Range.isExpanded(selection)) {
            slate_1.Editor.withoutNormalizing(editor, function () {
                slate_1.Transforms.setNodes(editor, null, { match: Text_1.Text.isText, split: true });
                Array.from(slate_1.Editor.nodes(editor, {
                    match: Text_1.Text.isText,
                })).forEach(function (entry) {
                    var _a = __read(entry, 2), text = _a[0], path = _a[1];
                    var marks = text.marks;
                    if (marks.some(function (m) { return isSameMark(mark, m); })) {
                        var newMarks = marks.filter(function (m) { return !isSameMark(mark, m); });
                        slate_1.Transforms.setNodes(editor, { marks: newMarks }, {
                            at: path,
                        });
                    }
                });
            });
        }
        else {
            var marks = getCurrentMarks(editor);
            var newMarks = marks.filter(function (f) { return !isSameMark(mark, f); });
            editor.marks = { marks: newMarks };
        }
    }
};
exports.removeMark = removeMark;
var isSameMark = function (markA, markB) {
    if (CommentMark_1.CommentMark.isComment(markA) && CommentMark_1.CommentMark.isComment(markB)) {
        return markA.data.id === markB.data.id;
    }
    return markA.type === markB.type;
};
var getMarkKey = function (mark) { return (CommentMark_1.CommentMark.isComment(mark) ? "comment:".concat(mark.data.id) : mark.type); };
