import "Application/Library/AutomationLibrary/AssignmentModal.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Library/AutomationLibrary/AssignmentModal.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VVXW/bIBR9z69AmiY1D0R2mqwdedtf6KQ9RhiubVICCHDjdNp/n8COA53bTtX2ZBnu5znnXlb78uCa9VNboJ8LhAzlXKiGoC+F6XcLhE6C+5agsig+7xa/FquLeRnNK8oeG6s7xTHTUluCnqi9wcMPdp2tKYPvYL2g9rzfl5W8OxzKwzJErrTlYLGlXHSOoO2Qr9I9di3l+kRQpxz4cKgNZcKfCSpW211a5db06P7tQtd/X+gDMK14WqlYZunLLPJtjMyFM5KeCaolxEIOnfOiPmOmlQflCXKGMsAV+BOAChZUikZh4eHoCGKgPNgXgVesBfYYsGD6aLQC5fd7SSuQMaeH3mNvqXK1tkeClFYQAs+0FkyndjaxnVorj514hotlOHgQzxCNce8m+7WLDqdWeMCxjZDrZKnZXarQT2BrGdgCKYVxwoWrI+3xSMi6GJU0AVVJzR4jrpNvKzgfsDlS2wiFJdSeXKiV4D3YWEBkfdRFgthmnorwxVxYYF5oRQI+3TGmaagJpQ3x3+NjG6PPgNsCDTqc8IJl5vcl+r0Dd4K2zd3vrlzHCgkKqPxHnqPDCUTT+tTlRzzBFppOUnt16tMx9toQVJoeOS0FTy5mag2TYbX8Fg2meM/LK/8EFaaf+JnGvdyYHuWLiVHJbuLUI4w2wWs5J7YE1vtcK5MaX4crDl5K1Ckn6utHF0GmwtcWGB1UFKTc2DAs+Raq3r5mw1rXTgwjQCunZefjuoic3Y7p7UD78JsE4B8arXGbt6OY1rd5k5tiM/xfVgA+E0Q7r7Oz/roYkoJgWOiDupw/SyCIU9dCqroLmHkZQrVghU8KSU4+ptcXr9jgNm6qdMTiU5J0Uc/DOkn9/vIchvD/ZLJeVDomSNmqhfQhWWPp2TEq4abM37/4/CZNNK8tRsG0mtKbvPN2EOyY6883vtzNvtef6rrO4oh5BGNDm7cW+29X3ULV8wgAAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var assignmentModalBody = '_1jsg2vh0';
export var automationItem = '_1jsg2vh1';
export var enabled = '_1jsg2vh2';
export var cardHeader = '_1jsg2vh3';
export var content = '_1jsg2vh4';
export var title = '_1jsg2vh5';
export var subtitle = '_1jsg2vh6';
export var description = '_1jsg2vh7';
export var descriptionInfo = '_1jsg2vh8';
export var footer = '_1jsg2vh9';
export var confirmButton = '_1jsg2vha';
export var cancelButton = '_1jsg2vhb';
export var closeButton = '_1jsg2vhc';
export var automationsList = '_1jsg2vhd';
export var emptyAutomationsList = '_1jsg2vhe';
export var integrationIcons = '_1jsg2vhf';
export var chevronRight = '_1jsg2vhg';
export var selectedAutomation = '_1jsg2vhh';
export var rightOptions = '_1jsg2vhi';