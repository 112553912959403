// Generated by CoffeeScript 1.12.7

/*
Plan Service
Used for matching the UI-defined plans with the database stored planService
 */

(function () {
  var FeatureFlag, PlanService;

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  module.exports = PlanService = (function () {
    function PlanService($q, $http, apiBaseUrl, $log, usingFeature) {
      this.usingFeature = usingFeature;

      /*
        Asynchronous function that gets the available plans from the server
        @returns [list] - the internal plans
       */
      this.fetchPlans = (function (_this) {
        return function () {
          return $http
            .get(apiBaseUrl + "/plans")
            .then(function (result) {
              return result.data.message;
            })
            ["catch"](function (err) {
              if (err.message != null) {
                $log.error(err.message);
              }
              return $q.reject(new Error("Server error: Could not get plans from server"));
            });
        };
      })(this);

      /*
         Function finding the internal plan details from a displayName
         @param [list] internalPlans -  A list of internal plans (generated by @fetchPlans)
         @param [string] name -  The lowercase display name for a plan
         @param [boolean] - The payment frequency of a planName
         @returns [object] - the internal plan object
       */
      this.getInternalPlan = function (internalPlans, displayName, isMonthly) {
        var internalPlan;
        internalPlan = _.find(
          internalPlans,
          (function (_this) {
            return function (plan) {
              var checkFrequency, checkName, isEnterpriseFrequencyAmbiguous, isFrequencyAmbiguous;
              if (displayName === "trial") {
                checkName = plan.internalName.endsWith(displayName);
              } else {
                checkName = plan.internalName.startsWith(displayName);
              }
              checkFrequency = plan.internalName.endsWith(isMonthly ? "m" : "y");
              isEnterpriseFrequencyAmbiguous =
                displayName === "enterprise" && !_this.usingFeature(FeatureFlag.EnterpriseSelfServe);
              isFrequencyAmbiguous =
                displayName === "starter" || displayName === "trial" || isEnterpriseFrequencyAmbiguous;
              if ((isFrequencyAmbiguous && checkName) || (checkFrequency && checkName)) {
                return true;
              } else {
                return false;
              }
            };
          })(this),
        );
        return internalPlan || null;
      };
    }

    return PlanService;
  })();
}.call(this));
