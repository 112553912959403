"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SavedBlockDragDrop = void 0;
var mobx_1 = require("mobx");
var DropzonesStore_1 = require("Project/BlockNew/SavedBlockDropzone/DropzonesStore");
var DragStore_1 = require("Project/Stores/DragStore/DragStore");
var SavedBlockDragDrop = (function () {
    function SavedBlockDragDrop(dragStore, dropzonesStore) {
        var _this = this;
        this.dragStore = dragStore;
        this.dropzonesStore = dropzonesStore;
        this.handleDragOver = function (event) {
            if (_this.activeDropzoneExists) {
                event.preventDefault();
            }
        };
        this.handleDrop = function (event) {
            event.preventDefault();
            if (_this.activeDropzoneExists) {
                _this.dropzonesStore.onDropInDropzone();
            }
        };
    }
    SavedBlockDragDrop.prototype.bind = function () {
        var _this = this;
        this.disposeReaction = (0, mobx_1.autorun)(function () {
            if (_this.dragStore.findDragItemFromCurrentDrag(DragStore_1.DragType.SavedBlock)) {
                _this.startListening();
            }
            else {
                _this.stopListening();
            }
        });
    };
    SavedBlockDragDrop.prototype.unbind = function () {
        this.disposeReaction();
        this.stopListening();
    };
    SavedBlockDragDrop.prototype.startListening = function () {
        window.addEventListener("dragover", this.handleDragOver);
        window.addEventListener("drop", this.handleDrop);
    };
    SavedBlockDragDrop.prototype.stopListening = function () {
        window.removeEventListener("dragover", this.handleDragOver);
        window.removeEventListener("drop", this.handleDrop);
    };
    Object.defineProperty(SavedBlockDragDrop.prototype, "activeDropzoneExists", {
        get: function () {
            return this.dropzonesStore.currentDropzone.status === DropzonesStore_1.CurrentDropzoneState.Active;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], SavedBlockDragDrop.prototype, "activeDropzoneExists", null);
    return SavedBlockDragDrop;
}());
exports.SavedBlockDragDrop = SavedBlockDragDrop;
