"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlgorithmCard = void 0;
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var LibraryCard_1 = require("../LibraryCard");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var styles = __importStar(require("./AlgorithmCard.css"));
var StringManipulater_1 = require("@CommonFrontend/Utils/StringManipulater");
var UpgradeTooltip_1 = require("Common/Upgrade/UpgradeTooltip");
var AlgorithmCard = function (_a) {
    var algorithm = _a.algorithm, isEnterprisePlan = _a.isEnterprisePlan, isAtEnabledLimit = _a.isAtEnabledLimit, enabledAlgorithmCount = _a.enabledAlgorithmCount, maxEnabledAlgorithms = _a.maxEnabledAlgorithms, clone = _a.clone, openEditModal = _a.openEditModal, deleteWithModal = _a.deleteWithModal, onEnableToggle = _a.onEnableToggle;
    var pagesMonitored = algorithm.pagesMonitored;
    var _b = __read((0, react_1.useState)(false), 2), isUpdating = _b[0], setIsUpdating = _b[1];
    function onToggle(isActive) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsUpdating(true);
                        return [4, onEnableToggle(algorithm, isActive)];
                    case 1:
                        _a.sent();
                        setIsUpdating(false);
                        return [2];
                }
            });
        });
    }
    var disabledBecauseOfEnabledLimit = isAtEnabledLimit && !algorithm.enabled;
    function textForToggleTooltip() {
        var text = "";
        if (!disabledBecauseOfEnabledLimit) {
            text = "Turn this algorithm ".concat(algorithm.enabled ? "off" : "on", ". ");
        }
        text += "You have ".concat(enabledAlgorithmCount, " / ").concat(maxEnabledAlgorithms, " algorithms on for your current plan.");
        if (!isEnterprisePlan) {
            text += " Upgrade to use more.";
        }
        return text;
    }
    return (react_1.default.createElement(LibraryCard_1.LibraryCard, { onClick: function () { return openEditModal(algorithm); } },
        react_1.default.createElement(kaleidoscope_1.Stack, { justify: "spaceBetween", className: styles.cardContent },
            react_1.default.createElement("div", { className: styles.cardHeadingBlock },
                react_1.default.createElement("div", { className: styles.cardHeadingRow },
                    react_1.default.createElement(kaleidoscope_1.Heading, { className: styles.algorithmName, level: "4", strong: true }, algorithm.name),
                    react_1.default.createElement("div", { style: { pointerEvents: "auto" } },
                        react_1.default.createElement(kaleidoscope_1.OptionMenu, { button: react_1.default.createElement(kaleidoscope_1.IconButton, { icon: react_1.default.createElement(icons_1.OptionsVertical, null), size: kaleidoscope_1.ButtonSize.Small }), size: "small" },
                            react_1.default.createElement(kaleidoscope_1.OptionMenuItem, { onClick: function () {
                                    openEditModal(algorithm);
                                } }, "Edit"),
                            react_1.default.createElement(kaleidoscope_1.OptionMenuItem, { onClick: function () { return clone(algorithm); } }, "Clone"),
                            react_1.default.createElement(kaleidoscope_1.OptionMenuSeparator, null),
                            react_1.default.createElement(kaleidoscope_1.OptionMenuItem, { destructive: true, onClick: function () {
                                    deleteWithModal(algorithm);
                                } }, "Delete")))),
                react_1.default.createElement(kaleidoscope_1.Text, { className: styles.description, size: "s" }, algorithm.description)),
            react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.bottomRow, direction: "horizontal", justify: "spaceBetween" },
                react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "center", gap: "xs" },
                    react_1.default.createElement(icons_1.Engagement, { color: "default" }),
                    react_1.default.createElement(kaleidoscope_1.Text, { size: "xs" }, "".concat((0, StringManipulater_1.pluralise)(pagesMonitored.total, "page")))),
                react_1.default.createElement("div", null,
                    react_1.default.createElement(ToggleTooltip, { tooltipText: textForToggleTooltip(), isEnterprisePlan: isEnterprisePlan, disabledBecauseOfEnabledLimit: disabledBecauseOfEnabledLimit },
                        react_1.default.createElement(kaleidoscope_1.Toggle, { label: "Enable or disable algorithm", value: algorithm.enabled, onChange: onToggle, labelHidden: true, disabled: isUpdating || disabledBecauseOfEnabledLimit })))))));
};
exports.AlgorithmCard = AlgorithmCard;
var ToggleTooltip = function (_a) {
    var tooltipText = _a.tooltipText, isEnterprisePlan = _a.isEnterprisePlan, disabledBecauseOfEnabledLimit = _a.disabledBecauseOfEnabledLimit, children = _a.children;
    if (!isEnterprisePlan && disabledBecauseOfEnabledLimit) {
        return (react_1.default.createElement(UpgradeTooltip_1.UpgradeTooltip, { telemetryOrigin: "engagement_library_algorithm_toggle", texts: { heading: "Upgrade to turn on more than three algorithms" } }, children));
    }
    return (react_1.default.createElement(kaleidoscope_1.Tooltip, { content: tooltipText, size: kaleidoscope_1.TooltipSize.Large, wrapTarget: true }, children));
};
