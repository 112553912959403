"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LockedPageModalComponent = exports.showLockedPageModal = void 0;
var react_1 = __importStar(require("react"));
var luxon_1 = require("luxon");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var Types_1 = require("@CommonFrontendBackend/Projects/Types");
var NumberFormatter_1 = require("Utils/NumberFormatter");
var PageStateChecklist_1 = require("Common/Modal/PageModals/PageStateChecklist");
var showLockedPageModal = function (modal, $state, useHistoryDataStore, projectStore) { return __awaiter(void 0, void 0, void 0, function () {
    var lockedDate, dt, formattedDate;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!!((_a = $state.params) === null || _a === void 0 ? void 0 : _a.skipLockedPageModal)) return [3, 3];
                return [4, projectStore.getLockedDate()];
            case 1:
                lockedDate = _b.sent();
                dt = luxon_1.DateTime.fromISO(lockedDate);
                formattedDate = "".concat(dt.toFormat("MMM"), " ").concat((0, NumberFormatter_1.toOrdinalNumber)(dt.day), ", ").concat(dt.year);
                return [4, modal.showSimpleModal({
                        isEscapable: false,
                        body: (react_1.default.createElement(exports.LockedPageModalComponent, { pageStatus: projectStore.status, pageSubStatus: projectStore.subStatus, lockedDate: formattedDate, handleOpenPage: modal.closeAnyModals, useHistoryDataStore: useHistoryDataStore, handleGoToPages: function () {
                                $state.go("dashboard", { nav: "pages" });
                                modal.closeAnyModals();
                            } })),
                    })];
            case 2:
                _b.sent();
                _b.label = 3;
            case 3: return [2];
        }
    });
}); };
exports.showLockedPageModal = showLockedPageModal;
var LockedPageModalComponent = function (_a) {
    var handleOpenPage = _a.handleOpenPage, handleGoToPages = _a.handleGoToPages, pageStatus = _a.pageStatus, pageSubStatus = _a.pageSubStatus, lockedDate = _a.lockedDate, useHistoryDataStore = _a.useHistoryDataStore;
    var _b = __read((0, react_1.useState)(false), 2), isChecked = _b[0], setIsChecked = _b[1];
    var onSecondaryButtonClick = function () {
        handleGoToPages();
    };
    var onPrimaryButtonClick = function () {
        if (isChecked) {
            if (pageStatus === Types_1.ProjectStatus.Declined) {
                useHistoryDataStore.hidePageStateModal(pageSubStatus);
            }
            else {
                useHistoryDataStore.hidePageStateModal(pageStatus);
            }
        }
        handleOpenPage();
    };
    return (react_1.default.createElement("div", { className: "locked-page-modal" },
        react_1.default.createElement(ModalHeader, { pageStatus: pageStatus, pageSubStatus: pageSubStatus, lockedDate: lockedDate }),
        react_1.default.createElement(PageStateChecklist_1.PageStateChecklist, { pageStatus: pageStatus }),
        react_1.default.createElement("div", { className: "button-container" },
            react_1.default.createElement(kaleidoscope_1.Button, { size: kaleidoscope_1.ButtonSize.Large, type: kaleidoscope_1.ButtonType.Secondary, onClick: onSecondaryButtonClick }, "Go to pages"),
            react_1.default.createElement(kaleidoscope_1.Button, { size: kaleidoscope_1.ButtonSize.Large, onClick: onPrimaryButtonClick }, "OK")),
        react_1.default.createElement(kaleidoscope_1.Checkbox, { item: { id: pageStatus, value: pageStatus, label: getCheckboxLabel(pageStatus, pageSubStatus) }, isSelected: isChecked, onChange: function () { return setIsChecked(!isChecked); } })));
};
exports.LockedPageModalComponent = LockedPageModalComponent;
var getCheckboxLabel = function (pageStatus, pageSubStatus) {
    switch (pageStatus) {
        case Types_1.ProjectStatus.Declined:
            return pageSubStatus === Types_1.ProjectSubStatus.DeclinedManual
                ? "Don't show for declined pages again"
                : "Don't show for expired pages again";
        case Types_1.ProjectStatus.Accepted:
            return "Don't show for accepted pages again";
        default:
            throw new Error("Unhandled page status: ".concat(pageStatus));
    }
};
var ModalHeader = function (modalHeaderProps) {
    switch (modalHeaderProps.pageStatus) {
        case Types_1.ProjectStatus.Accepted:
            return renderAcceptedHeader(modalHeaderProps);
        case Types_1.ProjectStatus.Declined:
            return renderDeclinedHeader(modalHeaderProps);
    }
};
function renderAcceptedHeader(_a) {
    var lockedDate = _a.lockedDate;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(icons_1.Tick, { className: "modal-icon modal-icon-accepted" }),
        react_1.default.createElement("div", { className: "locked-badge locked-badge-accepted" },
            react_1.default.createElement(icons_1.ListMarkerCircle, { className: "accepted-badge-marker" }), "Accepted ".concat(lockedDate)),
        react_1.default.createElement("div", { className: "modal-title" }, "This page is accepted!"),
        react_1.default.createElement("div", { className: "modal-explainer" }, "When a page is accepted, it is still accessible via the public link, still tracked but locked to edits.")));
}
function renderDeclinedHeader(_a) {
    var lockedDate = _a.lockedDate, pageSubStatus = _a.pageSubStatus;
    if (pageSubStatus === Types_1.ProjectSubStatus.DeclinedManual) {
        return (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(icons_1.Close, { className: "modal-icon modal-icon-declined" }),
            react_1.default.createElement("div", { className: "locked-badge locked-badge-declined" },
                react_1.default.createElement(icons_1.ListMarkerCircle, { className: "declined-badge-marker" }), "Declined ".concat(lockedDate)),
            react_1.default.createElement("div", { className: "modal-title" }, "This page is declined"),
            react_1.default.createElement("div", { className: "modal-explainer" }, "When a page is declined, it is no longer accessible and locked to edits.")));
    }
    else {
        return (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(icons_1.Clock, { className: "modal-icon modal-icon-declined" }),
            react_1.default.createElement("div", { className: "locked-badge locked-badge-declined" },
                react_1.default.createElement(icons_1.ListMarkerCircle, { className: "declined-badge-marker" }), "Expired ".concat(lockedDate)),
            react_1.default.createElement("div", { className: "modal-title" }, "This page is expired"),
            react_1.default.createElement("div", { className: "modal-explainer" }, "When a page is expired, it is no longer accessible and locked to edits.")));
    }
}
