// Generated by CoffeeScript 1.12.7

/*
  The top-level style editor button for the block menu
 */

(function () {
  var StyleButton, StyleButtonStore, cloneDeep, isEqual, isUsingTCDC;

  cloneDeep = require("lodash").cloneDeep;

  isEqual = require("lodash").isEqual;

  isUsingTCDC = require("@CommonFrontendBackend/FeatureFlag").isUsingTCDC;

  StyleButtonStore = require("./StyleButtonStore")["default"];

  module.exports = StyleButton = (function () {
    function StyleButton(
      invoke,
      createUi,
      block,
      make,
      projectEvents,
      usingFeature,
      Tooltip,
      blockStore,
      stylePermissionStore,
      $rootScope,
      $timeout,
      blockList,
    ) {
      var onLibraryClick,
        onShortcutClick,
        openBrandSetup,
        savedStyleControls,
        savedStyleMenu,
        shortcutMenu,
        shortcutStyleControls,
        usingTCDC;
      usingTCDC = isUsingTCDC(usingFeature);
      if (usingTCDC) {
        openBrandSetup = invoke(require("DreamCoat/BrandSetUp/OpenBrandSetup")["default"]);
        shortcutStyleControls = invoke(require("../../StyleControls/attachShortcutControls"), null, {
          openBrandSetup: openBrandSetup,
        });
        savedStyleControls = invoke(require("../../StyleControls/attachSavedStyleControls"));
        shortcutMenu = make(Tooltip, {
          targetSelector: "#" + block.$id + " .style-button__icon--block-style",
          options: {
            position: "bottom",
            contents: shortcutStyleControls,
            spacing: 7,
            container: ".project-block--id-" + block.$id,
            ignoreClickAwayElems: [".style-button", ".style-shortcut__option-menu"],
            closeOnClickAway: true,
          },
        });
        shortcutMenu.setOnHide(function () {
          return blockStore.turnOffAnimation();
        });
        savedStyleMenu = make(Tooltip, {
          targetSelector: "#" + block.$id + " .style-button__icon--style-library",
          options: {
            position: "bottom",
            contents: savedStyleControls,
            spacing: 7,
            container: ".project-block--id-" + block.$id,
            ignoreClickAwayElems: [".style-button"],
            closeOnClickAway: true,
          },
        });
        onShortcutClick = function (event) {
          if (event != null) {
            event.stopPropagation();
          }
          if (shortcutMenu != null ? shortcutMenu.isCurrentlyShowing() : void 0) {
            return shortcutMenu.hide();
          } else if (savedStyleMenu != null ? savedStyleMenu.isCurrentlyShowing() : void 0) {
            savedStyleMenu.hide(true);
            return shortcutMenu.show();
          } else {
            return shortcutMenu.show();
          }
        };
        onLibraryClick = function (event) {
          if (savedStyleMenu != null ? savedStyleMenu.isCurrentlyShowing() : void 0) {
            return savedStyleMenu.hide();
          } else if (shortcutMenu != null ? shortcutMenu.isCurrentlyShowing() : void 0) {
            shortcutMenu.hide(true);
            return savedStyleMenu.show();
          } else {
            return savedStyleMenu.show();
          }
        };
        this.ui = invoke(require("./StyleButton")["default"], null, {
          styleButtonStore: new StyleButtonStore(stylePermissionStore),
          onLibraryClick: onLibraryClick,
          onShortcutClick: onShortcutClick,
          isFirstBlock: blockList.getIndex(block) === 0,
        });
      } else {
        this.ui = createUi(require("./StyleButton.html"));
        this.onClick = function () {
          return projectEvents.emit("bigComponentOpened", {
            type: "Style",
            blockId: block._id,
          });
        };
      }
    }

    return StyleButton;
  })();
}.call(this));
