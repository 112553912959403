"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropzoneViewportState = exports.CurrentDropzoneState = exports.DropzonesStore = void 0;
var mobx_1 = require("mobx");
var DragStore_1 = require("Project/Stores/DragStore/DragStore");
var TelemetryEnums_1 = require("Telemetry/TelemetryEnums");
var Block_1 = require("../Block");
var DropzonesStore = (function () {
    function DropzonesStore(dragStore, savedBlockDataStore, blockList, insertBlock, editorState, rootDataStore, contentSidebarTelemetry) {
        var _this = this;
        this.dragStore = dragStore;
        this.savedBlockDataStore = savedBlockDataStore;
        this.blockList = blockList;
        this.insertBlock = insertBlock;
        this.editorState = editorState;
        this.rootDataStore = rootDataStore;
        this.contentSidebarTelemetry = contentSidebarTelemetry;
        this._currentDropzoneIndex = null;
        this._isPersistent = false;
        this.setPersistent = function (dropzoneIndex, scrollToPersistentDropzone) {
            _this._isPersistent = true;
            _this._currentDropzoneIndex = dropzoneIndex;
            _this._currentDropzoneState = CurrentDropzoneState.Idle;
            if (scrollToPersistentDropzone && dropzoneIndex) {
                _this._scrollToDropzoneIndex = dropzoneIndex;
            }
        };
        this.clearPersistent = function () {
            _this._isPersistent = false;
        };
        this.setCurrentDropzone = function (insertionIndex, dropzoneState) {
            if (dropzoneState === void 0) { dropzoneState = CurrentDropzoneState.Idle; }
            _this._currentDropzoneIndex = insertionIndex;
            _this._currentDropzoneState = dropzoneState;
        };
        this.onOverDropzone = function (insertionIndex) {
            _this._currentDropzoneIndex = insertionIndex;
            _this._currentDropzoneState = CurrentDropzoneState.Active;
        };
        this.onDropInDropzone = function () { return __awaiter(_this, void 0, Promise, function () {
            var dragItem, savedBlockId, savedBlock, insertionIndex;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.rootDataStore.userDataStore.useHistoryDataStore.dismissBlockLibraryExplainer();
                        this._currentDropzoneState = CurrentDropzoneState.Inserting;
                        dragItem = this.dragStore.findDragItemFromCurrentDrag(DragStore_1.DragType.SavedBlock);
                        savedBlockId = dragItem.id;
                        savedBlock = this.savedBlockDataStore.getSavedBlockById(savedBlockId);
                        insertionIndex = this._currentDropzoneIndex;
                        return [4, this.insertBlock(function (blockToInsert) { return __awaiter(_this, void 0, void 0, function () {
                                var savedAgreement, savedAgreementBlock;
                                var _this = this;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            this.editorState.incrementBlockAddedCountInSidebarSession();
                                            this.contentSidebarTelemetry.sidebarAssetAdded(TelemetryEnums_1.SidebarAssetAddedType.Block, TelemetryEnums_1.SidebarAssetAddedInsertMethod.Drag);
                                            savedAgreement = blockToInsert.savedAgreement;
                                            delete blockToInsert.savedAgreement;
                                            return [4, this.blockList.insertBlockAtIndex(blockToInsert, insertionIndex, { skipScrolling: true })];
                                        case 1:
                                            _a.sent();
                                            if (!savedAgreement) return [3, 3];
                                            savedAgreementBlock = this.savedBlockDataStore.getSavedBlockById(savedAgreement);
                                            return [4, this.insertBlock(function (agreement) { return __awaiter(_this, void 0, void 0, function () {
                                                    return __generator(this, function (_a) {
                                                        this.blockList.insertAgreementBlock(agreement);
                                                        return [2];
                                                    });
                                                }); })(savedAgreementBlock)];
                                        case 2:
                                            _a.sent();
                                            _a.label = 3;
                                        case 3: return [2];
                                    }
                                });
                            }); })(savedBlock)];
                    case 1:
                        _a.sent();
                        this.clearActiveDropzone();
                        if (this.isPersistent) {
                            setTimeout(function () {
                                _this.setCurrentDropzone(insertionIndex + 1, CurrentDropzoneState.Idle);
                            }, Block_1.BLOCK_SCROLL_DURATION_MS);
                        }
                        return [2];
                }
            });
        }); };
        this.onDragEnd = function () {
            if (_this._currentDropzoneState !== CurrentDropzoneState.Inserting) {
                if (_this.isPersistent) {
                    _this.setCurrentDropzone(_this.currentDropzone.index, CurrentDropzoneState.Idle);
                }
                else {
                    _this.clearActiveDropzone();
                }
            }
        };
        this.clearActiveDropzone = function () {
            _this._currentDropzoneIndex = null;
            _this._currentDropzoneState = CurrentDropzoneState.Idle;
            _this._currentDropzoneViewportState = null;
        };
        this.setCurrentDropzoneViewportStatus = function (inViewport, isAboveViewport) {
            if (inViewport) {
                _this._currentDropzoneViewportState = DropzoneViewportState.InViewport;
                return;
            }
            if (isAboveViewport) {
                _this._currentDropzoneViewportState = DropzoneViewportState.AboveViewport;
                return;
            }
            _this._currentDropzoneViewportState = DropzoneViewportState.BelowViewport;
        };
    }
    Object.defineProperty(DropzonesStore.prototype, "isPersistent", {
        get: function () {
            return this._isPersistent;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DropzonesStore.prototype, "scrollToDropzoneIndex", {
        get: function () {
            return this._scrollToDropzoneIndex;
        },
        enumerable: false,
        configurable: true
    });
    DropzonesStore.prototype.clearScrollToDropzoneIndex = function () {
        this._scrollToDropzoneIndex = null;
    };
    Object.defineProperty(DropzonesStore.prototype, "currentDropzone", {
        get: function () {
            return {
                index: this._currentDropzoneIndex,
                status: this._currentDropzoneState,
                viewportStatus: this._currentDropzoneViewportState,
            };
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Number)
    ], DropzonesStore.prototype, "_currentDropzoneIndex", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", String)
    ], DropzonesStore.prototype, "_currentDropzoneState", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", String)
    ], DropzonesStore.prototype, "_currentDropzoneViewportState", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], DropzonesStore.prototype, "_isPersistent", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Number)
    ], DropzonesStore.prototype, "_scrollToDropzoneIndex", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], DropzonesStore.prototype, "isPersistent", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], DropzonesStore.prototype, "scrollToDropzoneIndex", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], DropzonesStore.prototype, "clearScrollToDropzoneIndex", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], DropzonesStore.prototype, "setPersistent", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], DropzonesStore.prototype, "setCurrentDropzone", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], DropzonesStore.prototype, "onOverDropzone", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], DropzonesStore.prototype, "onDropInDropzone", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], DropzonesStore.prototype, "onDragEnd", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], DropzonesStore.prototype, "clearActiveDropzone", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], DropzonesStore.prototype, "currentDropzone", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], DropzonesStore.prototype, "setCurrentDropzoneViewportStatus", void 0);
    return DropzonesStore;
}());
exports.DropzonesStore = DropzonesStore;
var CurrentDropzoneState;
(function (CurrentDropzoneState) {
    CurrentDropzoneState["Idle"] = "idle";
    CurrentDropzoneState["Active"] = "active";
    CurrentDropzoneState["Inserting"] = "inserting";
})(CurrentDropzoneState || (exports.CurrentDropzoneState = CurrentDropzoneState = {}));
var DropzoneViewportState;
(function (DropzoneViewportState) {
    DropzoneViewportState["AboveViewport"] = "aboveviewport";
    DropzoneViewportState["BelowViewport"] = "belowviewport";
    DropzoneViewportState["InViewport"] = "inviewport";
})(DropzoneViewportState || (exports.DropzoneViewportState = DropzoneViewportState = {}));
