import "Application/Library/AutomationLibrary/AutomationCard.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Library/AutomationLibrary/AutomationCard.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71Ty3LjIBC8+yu4pGp9wGU5m8fi2/5ICsFYHi1mKBjJ8qby7ynQRpZjH5LLnoBp6O6helZ0z63t1uJ1IUSghIzklYjgNGMP24UQOgUwLKNmJCWq1eYxV4O2Fn2jRK/jDylT0AZ9I93LS1W7p7atKrfcLt4Wq1GgKgJ7wGbPSlTr9d0M3BTQYgpOn5TYORiyRF6lxQhmNGXIdQefkUaHz8JDOivjXPn+NnnbJcbdSRryDJ6VyEQga+AjQBHRDhsvkeGQxmcysY48o/55SS2PUP9BljUVhan+7/wBO/QgjdOHoMQmA1eFGZGkiMVeD5HRaJdxhoEl9RB3jo5KgHMYEqYMnat7tDY3Mrl9+Jbba6L/0sHk9rG4rSlaiJIpKFGFQSRyaLdnwJCj+BGFcpAjMoXhtJyldSS6DM4sN+14l9AzRAk9eE5K6I7pKg8G8p2Z36fbMZvG5DkMZ99fbianM5L7fdnT3+XsVdQWuzQJlNF4yPvJ2nOxdoMdDfmJNMyH5teXfp8pfM/02zvlOqV+bwQAAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var cardWrapper = 'o3tjdu0';
export var cardContent = 'o3tjdu1';
export var cardHeadingBlock = 'o3tjdu2';
export var cardHeadingRow = 'o3tjdu3';
export var automationName = 'o3tjdu4';
export var description = 'o3tjdu5';
export var bottomRow = 'o3tjdu6';
export var integrationIcons = 'o3tjdu7';
export var chevron = 'o3tjdu8';
export var destructiveOption = 'o3tjdu9';