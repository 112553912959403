"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SlateSelectionStore = void 0;
var mobx_1 = require("mobx");
var SlateSelectionStore = (function () {
    function SlateSelectionStore(initialSelection) {
        var _this = this;
        this._selection = null;
        this._focussed = false;
        this.setSelection = function (selection) {
            _this._selection = selection;
        };
        this.setFocussed = function (focussed) {
            _this._focussed = focussed;
        };
        this._selection = initialSelection;
    }
    Object.defineProperty(SlateSelectionStore.prototype, "focussed", {
        get: function () {
            return this._focussed;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SlateSelectionStore.prototype, "currentSelection", {
        get: function () {
            return this._selection;
        },
        enumerable: false,
        configurable: true
    });
    var _a, _b;
    __decorate([
        mobx_1.observable.deep,
        __metadata("design:type", Object)
    ], SlateSelectionStore.prototype, "_selection", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], SlateSelectionStore.prototype, "_focussed", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], SlateSelectionStore.prototype, "focussed", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], SlateSelectionStore.prototype, "currentSelection", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], SlateSelectionStore.prototype, "setSelection", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], SlateSelectionStore.prototype, "setFocussed", void 0);
    return SlateSelectionStore;
}());
exports.SlateSelectionStore = SlateSelectionStore;
