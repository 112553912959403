// Generated by CoffeeScript 1.12.7

/*
  This module defines the Block Menu for Source Block.
 */

(function () {
  var isUsingTCDC;

  isUsingTCDC = require("@CommonFrontendBackend/FeatureFlag").isUsingTCDC;

  module.exports = function (make, viewState, StyleButton, BlockControls, usingFeature) {
    var blockControls, styleButton, superpowers;
    styleButton = make(StyleButton);
    blockControls = make(BlockControls);
    superpowers = [];
    if (isUsingTCDC(usingFeature)) {
      superpowers.push({
        name: "Style library",
        ctrl: styleButton,
        selectable: false,
        select: styleButton.onClick,
        deselect: styleButton.onClose,
        overflow: true,
        visible: function () {
          return viewState.displayMode;
        },
      });
    } else {
      superpowers.push({
        name: "Style library",
        ctrl: styleButton,
        selectable: false,
        select: styleButton.onClick,
        overflow: true,
      });
    }
    superpowers.push({
      name: "Block Controls",
      ctrl: blockControls,
      doNotWrapControl: true,
      visibleOnViewOnlyBlocks: true,
    });
    return {
      settings: {
        useItems: ["foldable", "navigationMenu"],
        superpowers: superpowers,
      },
    };
  };
}.call(this));
