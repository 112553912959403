(function () {
  var CreditCard, FeatureFlag, StripeElements, eligibleForEntMonthly;

  StripeElements = require("CreditCardForm/StripeElements")["default"];

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  eligibleForEntMonthly = require("../eligibleForEntMonthly").eligibleForEntMonthly;

  module.exports = CreditCard = (function () {
    function CreditCard(
      createUi,
      make,
      $q,
      $http,
      QwilrStripe,
      telemetry,
      apiBaseUrl,
      onComplete,
      goBack,
      overlayStack,
      di,
      paymentService,
      Tooltip,
      $log,
      simpleDropdownFactory,
      $timeout,
      planService,
      planInfo,
      seatsService,
      account,
      usingFeature,
    ) {
      var calFutureSeats,
        calculateMonthlyDiscount,
        checkCoupon,
        clearUi,
        getInitStateOfFrequencyDropdown,
        getInitialUIData,
        init,
        makeDropdown,
        makeInvalidCouponTooltip,
        monthlyCost,
        selectedPlan,
        showCouponField,
        showError,
        submitPayment,
        updateFields;
      this.entMonthlyEnabled = usingFeature(FeatureFlag.EnterpriseSelfServe) && eligibleForEntMonthly(account);
      this.menuVisible = null;
      this.currentCoupon = null;
      showCouponField = null;
      selectedPlan = null;
      init = (function (_this) {
        return function () {
          telemetry.trackOnly("Upgrade Form Viewed", planInfo.telemetryProperties);
          _this.menuVisible = false;
          overlayStack.hideCloseButton();
          showCouponField = di.has("allowCoupon") ? di.get("allowCoupon") : true;
          _this.ui = createUi(require("./CreditCard.html"), getInitialUIData());
          selectedPlan = planInfo.isMonthly ? planInfo.monthly : planInfo.yearly;
          if (selectedPlan.displayName === "Starter") {
            $timeout(onComplete);
            return;
          }
          if (selectedPlan.displayName === "Enterprise" && !_this.entMonthlyEnabled) {
            planInfo.isMonthly = false;
            selectedPlan = planInfo.yearly;
          }
          updateFields();
          _this.ui.frequencyDropdown = makeDropdown();
          clearUi();
          return (_this.ui.loading = false);
        };
      })(this);
      makeDropdown = (function (_this) {
        return function () {
          return simpleDropdownFactory.create(
            [
              {
                name: "Paid monthly",
                id: 0,
              },
              {
                name: "Paid yearly",
                id: 1,
              },
            ],
            {
              name: getInitStateOfFrequencyDropdown(),
            },
            function (selected) {
              planInfo.isMonthly = selected.name === "Paid monthly";
              return updateFields();
            },
          );
        };
      })(this);
      calculateMonthlyDiscount = (function (_this) {
        return function () {
          if (planInfo.isMonthly || (selectedPlan.displayName === "Enterprise" && !_this.entMonthlyEnabled)) {
            return "";
          } else {
            return String(100 - Math.round((100 * monthlyCost()) / planInfo.monthly.price));
          }
        };
      })(this);
      monthlyCost = (function (_this) {
        return function () {
          if (planInfo.isMonthly) {
            return selectedPlan.price;
          } else {
            return Number(selectedPlan.price) / 12;
          }
        };
      })(this);
      calFutureSeats = (function (_this) {
        return function () {
          var newPlanSeats, numUsers;
          newPlanSeats = account.minimumSeats || selectedPlan.seats.min || selectedPlan.seats.included;
          numUsers = seatsService.currentlyOccupied();
          return Math.max(numUsers, newPlanSeats);
        };
      })(this);
      updateFields = (function (_this) {
        return function () {
          var amountToPay, coupon;
          if (planInfo.isMonthly) {
            selectedPlan = planInfo.monthly;
          } else {
            selectedPlan = planInfo.yearly;
          }
          amountToPay =
            selectedPlan.price + (calFutureSeats() - selectedPlan.seats.included) * selectedPlan.seats.price;
          _this.ui.payToday = amountToPay;
          _this.ui.payMonthly = monthlyCost();
          _this.ui.discount = calculateMonthlyDiscount();
          _this.ui.payFrequency = planInfo.isMonthly ? "monthly" : "yearly";
          _this.ui.couponDiscount = null;
          _this.ui.acceptedTermsofService = false;
          _this.ui.planValue = null;
          _this.ui.planName = selectedPlan.displayName;
          if (_this.currentCoupon != null) {
            coupon = _this.currentCoupon;
            _this.ui.couponDiscount = coupon.percent_off;
            _this.ui.payToday -= (coupon.percent_off / 100) * amountToPay;
            return (_this.ui.planValue = amountToPay);
          }
        };
      })(this);
      checkCoupon = (function (_this) {
        return function (couponCode) {
          return paymentService.checkCoupon(couponCode)["catch"](function (error) {
            $log.error(error.message);
            return $q.reject(new Error("Error: could not check coupon code"));
          });
        };
      })(this);
      showError = (function (_this) {
        return function (message) {
          return (_this.ui.error = message);
        };
      })(this);
      clearUi = (function (_this) {
        return function () {
          var ccForm;
          ccForm = make(StripeElements, {
            stripeKey: QwilrStripe,
            stripeUserId: null,
            showEmailField: false,
            useUpdatedStyling: false,
            setErrorMsg: function (message) {
              return (_this.ui.error = message);
            },
          });
          ccForm.getEventEmitter().once("detailsChanged", function () {
            return telemetry.trackOnly("Card Details Entered");
          });
          _this.ui.ccForm = ccForm;
          _this.ui.coupon = "";
          return (_this.ui.error = null);
        };
      })(this);
      submitPayment = (function (_this) {
        return function (token) {
          var paymentDetails;
          if (di.has("paymentDetails")) {
            paymentDetails = di.get("paymentDetails");
            paymentDetails.token = token;
            paymentDetails.coupon = _this.currentCoupon || null;
          } else {
            paymentDetails = {
              token: token,
              coupon: _this.currentCoupon || null,
            };
            di.value("paymentDetails", paymentDetails);
          }
          return onComplete();
        };
      })(this);
      makeInvalidCouponTooltip = (function (_this) {
        return function () {
          return make(Tooltip, {
            targetSelector: ".apply-button",
            options: {
              container: ".credit-card .credit-card-form.coupon",
              position: "top",
              spacing: 0,
              stringContent:
                "This coupon either doesn't exist or is no longer valid. Please double check details and try again.",
              hideAfter: 5000,
              customTheme: "error-theme",
            },
          });
        };
      })(this);
      getInitialUIData = (function (_this) {
        return function () {
          return {
            loading: false,
            ccForm: null,
            error: null,
            acceptTermsOfServiceReminder: null,
            showCouponField: showCouponField,
            coupon: "",
            payToday: null,
            payMonthly: null,
            discount: null,
            payFrequency: null,
            couponDiscount: null,
            planValue: null,
            planName: null,
            checkCoupon: false,
            couponError: makeInvalidCouponTooltip(),
            submit: function () {
              _this.ui.loading = true;
              _this.ui.error = null;
              _this.ui.acceptTermsOfServiceReminder = null;
              if (!_this.ui.acceptedTermsofService) {
                _this.ui.acceptTermsOfServiceReminder = "Please accept the terms of service to continue";
                _this.ui.loading = false;
                return;
              }
              if (!_this.ui.ccForm.validateForm()) {
                _this.ui.loading = false;
                return;
              }
              return paymentService
                .submitCard(_this.ui.ccForm)
                .then(function (token) {
                  clearUi();
                  submitPayment(token);
                  return (_this.ui.loading = false);
                })
                ["catch"](function (error) {
                  $log.error(error.message);
                  _this.ui.error = "Error: could not submit card details";
                  _this.ui.loading = false;
                  return $q.resolve();
                });
            },
            applyCoupon: function () {
              var amountToPay;
              amountToPay =
                selectedPlan.price + (calFutureSeats() - selectedPlan.seats.included) * selectedPlan.seats.price;
              if (!_this.ui.coupon) {
                _this.currentCoupon = null;
                _this.ui.couponDiscount = null;
                return (_this.ui.payToday = amountToPay);
              } else {
                _this.ui.checkCoupon = true;
                return checkCoupon(_this.ui.coupon)
                  .then(function (coupon) {
                    if (coupon) {
                      if (_this.ui.couponError.isCurrentlyShowing()) {
                        _this.ui.couponError.hide();
                      }
                      _this.currentCoupon = coupon;
                      _this.ui.couponDiscount = coupon.percent_off;
                      _this.ui.payToday = ((100 - coupon.percent_off) / 100) * amountToPay;
                      _this.ui.planValue = amountToPay;
                      return (_this.ui.checkCoupon = false);
                    } else {
                      _this.ui.couponError.show();
                      _this.currentCoupon = null;
                      _this.ui.couponDiscount = null;
                      return (_this.ui.checkCoupon = false);
                    }
                  })
                  ["catch"](function (error) {
                    _this.currentCoupon = null;
                    _this.ui.couponDiscount = null;
                    _this.ui.checkCoupon = false;
                    return (_this.ui.error = error.message);
                  });
              }
            },
            isMonthlyOptionEnabled: function () {
              return _this.ui.planName !== "Enterprise" || _this.entMonthlyEnabled;
            },
            frequencyDropdown: null,
            onClickBack: function () {
              return goBack();
            },
            close: function () {
              return overlayStack.closeCurrent();
            },
          };
        };
      })(this);
      getInitStateOfFrequencyDropdown = function () {
        if (planInfo.isMonthly) {
          return "Paid monthly";
        } else {
          return "Paid yearly";
        }
      };
      init();
    }

    return CreditCard;
  })();
}.call(this));
