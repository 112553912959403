"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VelocityHistoryFilter = void 0;
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var Types_1 = require("Reports/Types");
var VELOCITY_HISTORY_FILTER_ITEMS = [
    { id: "averageTimeLive", value: Types_1.SelectedVelocityHistoryFilter.AverageClose, label: "AV. TIME LIVE" },
    { id: "averagePageValue", value: Types_1.SelectedVelocityHistoryFilter.AveragePageValue, label: "AV. PAGE VALUE" },
    { id: "closeAcceptRate", value: Types_1.SelectedVelocityHistoryFilter.CloseAcceptRate, label: "ACCEPT RATE" },
];
var actionTypeTelemetryMap = Object.freeze({
    "pageVelocity.averagePageValue": "velocity_history_select_avg_page_value",
    "pageVelocity.closeAcceptRate": "velocity_history_select_accept_rate",
    "pageVelocity.pageCycleTimes.averageClose": "velocity_history_select_avg_time_live",
});
function VelocityHistoryFilter(_a) {
    var onSelect = _a.onSelect, firePipelineTelemetry = _a.firePipelineTelemetry;
    var _b = __read((0, react_1.useState)(VELOCITY_HISTORY_FILTER_ITEMS[0].value), 2), checked = _b[0], setChecked = _b[1];
    var onChangeHandler = function (selected) {
        var selectedFilter = selected;
        setChecked(selectedFilter);
        onSelect([selectedFilter]);
        firePipelineTelemetry(actionTypeTelemetryMap[selectedFilter]);
    };
    (0, react_1.useEffect)(function () {
        onSelect([VELOCITY_HISTORY_FILTER_ITEMS[0].value]);
    }, []);
    return (react_1.default.createElement("div", { className: "history-chart-view__velocity-history-filter" }, VELOCITY_HISTORY_FILTER_ITEMS.map(function (item) { return (react_1.default.createElement(kaleidoscope_1.Checkbox, { key: item.id, item: item, isSelected: checked === item.value, onChange: onChangeHandler, toggleKey: item.value })); })));
}
exports.VelocityHistoryFilter = VelocityHistoryFilter;
