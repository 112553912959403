"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonWidget = void 0;
var react_1 = __importStar(require("react"));
var react_transition_group_1 = require("react-transition-group");
var inversify_react_1 = require("inversify-react");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var kaleidoscope_2 = require("@qwilr/kaleidoscope");
var WidgetManager_1 = require("../../WidgetManager");
var BehaviourBuilder_1 = require("Project/QwilrEditor/Behaviours/BehaviourBuilder");
var ButtonWidgetCommon_1 = require("./ButtonWidgetCommon");
var ButtonWidget_1 = require("@CommonFrontend/ContentTokens/SharedWidgetComponents/Button/ButtonWidget");
var maxTextLength = 50;
var ButtonWidget = function (_a) {
    var children = _a.children, element = _a.element;
    var widgetManager = (0, inversify_react_1.useInjection)(WidgetManager_1.WidgetManager);
    var _b = __read((0, react_1.useState)(false), 2), inputFocussed = _b[0], setInputFocussed = _b[1];
    var telemetry = (0, inversify_react_1.useInjection)("editorTelemetry");
    var placeholder = "Name your button";
    var text = element.data.text;
    var textBeforeModification;
    var handleKeyPress = function (event) {
        switch (event.key) {
            case BehaviourBuilder_1.KeyType.Enter: {
                event.preventDefault();
                if (event.shiftKey) {
                    break;
                }
                (0, ButtonWidgetCommon_1.triggerEventWithPath)(widgetManager, element, "addParagraphBelow");
                break;
            }
            case BehaviourBuilder_1.KeyType.ArrowUp: {
                event.preventDefault();
                if (event.currentTarget) {
                    event.currentTarget.blur();
                    (0, ButtonWidgetCommon_1.triggerEventWithPath)(widgetManager, element, "arrowUpInLabel");
                }
                break;
            }
            case BehaviourBuilder_1.KeyType.ArrowDown: {
                event.preventDefault();
                if (event.currentTarget) {
                    event.currentTarget.blur();
                    (0, ButtonWidgetCommon_1.triggerEventWithPath)(widgetManager, element, "arrowDownInLabel");
                }
                break;
            }
        }
    };
    var onInputFocusChange = function (isFocussed) {
        if (isFocussed) {
            textBeforeModification = text;
        }
        else {
            if (textBeforeModification !== text) {
                telemetry.buttonTextEdited(text);
            }
            textBeforeModification = undefined;
        }
        setInputFocussed(isFocussed);
    };
    return (react_1.default.createElement(ButtonWidget_1.ButtonWidgetContainer, { className: "project-block__block-element" },
        react_1.default.createElement(ButtonWidget_1.ButtonWidget, { contentEditable: false },
            react_1.default.createElement("div", { className: "button-widget__button user-font", "data-slate-editor": true },
                react_1.default.createElement("div", { className: "button-widget__button-expander" }, !!text ? text : placeholder),
                react_1.default.createElement("div", { style: { width: "100%" }, "data-interactive-zone": true },
                    react_1.default.createElement(kaleidoscope_1.TextInput, { label: "Button text", labelHidden: true, className: "button-widget__button-text", value: text, placeholder: placeholder, onChange: function (text) { return (0, ButtonWidgetCommon_1.updateButton)(widgetManager, element, { text: text }); }, onKeyDown: handleKeyPress, variant: kaleidoscope_1.TextInputVariant.Borderless, elementSize: 1, autoComplete: "none", maxLength: maxTextLength, onFocus: function () { return onInputFocusChange(true); }, onBlur: function () { return onInputFocusChange(false); } }))),
            react_1.default.createElement(Warnings, { element: element, inputFocused: inputFocussed })),
        children));
};
exports.ButtonWidget = ButtonWidget;
var Warnings = function (_a) {
    var element = _a.element, inputFocused = _a.inputFocused;
    var showLengthWarning = element.data.text.length >= maxTextLength - 10 && inputFocused;
    var showNoLinkWarning = !element.data.href;
    return (react_1.default.createElement("div", { className: "button-widget__warnings" },
        react_1.default.createElement(react_transition_group_1.CSSTransition, { in: showNoLinkWarning, timeout: kaleidoscope_2.AnimationDuration.Short, classNames: "button-widget__warnings--transition", mountOnEnter: true, unmountOnExit: true },
            react_1.default.createElement(WarningNoLink, null)),
        react_1.default.createElement(react_transition_group_1.CSSTransition, { in: showLengthWarning, mountOnEnter: true, unmountOnExit: true, timeout: kaleidoscope_2.AnimationDuration.Short, classNames: "button-widget__warnings--transition" },
            react_1.default.createElement(WarningLength, { element: element }))));
};
var WarningNoLink = function () {
    return (react_1.default.createElement("div", { className: "button-widget__warnings-no-link" },
        react_1.default.createElement(icons_1.Unlink, { size: icons_1.IconSize.Small }),
        react_1.default.createElement("span", { className: "button-widget__warnings-no-link-text", contentEditable: false }, "NO LINK ADDED")));
};
var WarningLength = function (_a) {
    var element = _a.element;
    var remaining = maxTextLength - element.data.text.length;
    return (react_1.default.createElement("div", { className: "button-widget__warnings-max-length" }, "".concat(remaining, " characters left (max ").concat(maxTextLength, ")")));
};
