// Generated by CoffeeScript 1.12.7

/*
  DI wiring for a Block in a project.
 */

(function () {
  var BlockStore,
    CommentService,
    CommentThreadStore,
    CommentsCollaborationConsumer,
    WidgetAdderStore,
    assign,
    getOrigin;

  BlockStore = require("Project/Stores/BlockStore.ts")["default"];

  CommentService = require("Project/Comments/CommentService.ts")["default"];

  CommentThreadStore = require("Project/Comments/CommentThreadStore.ts")["default"];

  CommentsCollaborationConsumer = require("Project/Comments/Collaboration/CommentsCollaborationConsumer")["default"];

  WidgetAdderStore = require("Project/QwilrEditor/Widgets/WidgetAdder/WidgetAdderStore")["default"];

  getOrigin = require("Utils/Location").getOrigin;

  assign = require("lodash").assign;

  module.exports = function (block, di) {
    var wiringObject;
    wiringObject = {
      block: block,
      blockStore: new BlockStore(block, di.get("$timeout")),
      viewState: {
        currentTab: {
          name: null,
          ui: null,
        },
        activeBlock: false,
      },
      blockEvents: function () {
        var Emitter;
        Emitter = require("events").EventEmitter;
        return new Emitter();
      },
      blockElemSelector: ".block-container[id=" + block.$id + "]",
      hasWidgets: function (block) {
        return function () {
          var contentArea, contentAreaName, ref;
          ref = block.contentAreas;
          for (contentAreaName in ref) {
            contentArea = ref[contentAreaName];
            if (_.keys(block.contentAreas[contentAreaName].widgets).length) {
              return true;
            }
          }
          return false;
        };
      },
      deleteBlock: require("./DeleteBlock"),
      widgetAdderStore: function (usingFeature, blockStore, projectStore) {
        return new WidgetAdderStore(usingFeature, blockStore, projectStore);
      },
      commentService: function (apiBaseUrl, project) {
        return new CommentService(apiBaseUrl, getOrigin(), project._id, block._id);
      },
      commentThreadStore: function (
        activeCommentThreadStore,
        commentService,
        user,
        telemetry,
        block,
        rootDataStore,
        blockList,
        projectStore,
      ) {
        return new CommentThreadStore(
          activeCommentThreadStore,
          commentService,
          user,
          telemetry,
          block.type,
          rootDataStore.userDataStore.useHistoryDataStore,
          blockList,
          projectStore,
        );
      },
    };

    /*
      In the legacy Angular code, the UI for creating a new block is instantiated using this
      `BlockWiring` - even though it is not really a block! The block._id is undefined when this
      happens.

      Another messy situation where block._id is undefined is when editing a saved block. We're not
      *supposed* to have comments in saved blocks, so it's also ok not to instantiate the
      `commentsCollaborationConsumer` in this case.

      @NOTE (som, 2020-03-23): this check was added because the `Block.js` was telling the
      `commentsCollaborationConsumer` to subscribe and unsubscribe even when the block _id was
      undefined. This meant that whenenver the 'create new block' UI was closed, we would unsubscribe
      from the Pusher channel for the entire project, and stop receiving Pusher events!

      @NOTE (som, 2020-03-24): ideally, `commentService` and `commentThreadStore` should not be
      instantiated for the saved block editor either. When I attempted this however, I needed a lot of
      branching existential checks for `commentThreadStore` in *many* editor modules (AttachReact,
      CommentWithMarker, InitialiseEditorModules, QwilrEditor, QwilrEditorComponent). This
      technically worked, but it added so much complexity for a simple behaviour, that it undeniably
      made the code worse.

      This is a sign that there's some decoupling that can be done between Comments and the Editor.
      i.e there should be a simple way to 'turn off' Comments at a high level in the Editor, without
      having to drill down into all these different modules.
     */
    if (block._id != null) {
      assign(wiringObject, {
        commentsCollaborationConsumer: function (pusher, commentThreadStore, project, userList, user) {
          return new CommentsCollaborationConsumer(
            pusher,
            commentThreadStore,
            project._id,
            userList,
            user._id,
            block._id,
          );
        },
      });
    }
    return wiringObject;
  };
}.call(this));
