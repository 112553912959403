"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetType = exports.Category = void 0;
var Models_1 = require("ContentTokens/Models");
var Category;
(function (Category) {
    Category["Business"] = "Business";
    Category["Marketing"] = "Marketing";
    Category["Gaming"] = "Gaming";
    Category["Music"] = "Music";
    Category["Weather"] = "Weather";
})(Category || (exports.Category = Category = {}));
var AssetType;
(function (AssetType) {
    AssetType["UnsplashImage"] = "unsplashImage";
    AssetType["Video"] = "video";
    AssetType["Block"] = "block";
    AssetType["UnsplashCollection"] = "unsplashCollection";
})(AssetType || (exports.AssetType = AssetType = {}));
var unsplashCollectionList = [
    {
        name: "Cyberpunk City",
        tags: [],
        type: AssetType.UnsplashCollection,
        content: {
            collectionId: "574331",
            thumbnailUrl: "https://images.unsplash.com/photo-1662499840292-0805bbc95005?ixlib=rb-4.0.3&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max",
            coverId: "zCZVcRBn0Cc",
            color: "#f3f3f3",
            title: "Cyberpunk City",
            count: 969,
        },
    },
    {
        name: "ANCIENT ROME",
        tags: [],
        type: AssetType.UnsplashCollection,
        content: {
            collectionId: "77458385",
            thumbnailUrl: "https://images.unsplash.com/photo-1590749126478-3134d2d2fbc9?ixlib=rb-4.0.3&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max",
            coverId: "2qpJllgSXCs",
            color: "#a68cf3",
            title: "ANCIENT ROME",
            count: 569,
        },
    },
];
var unsplashImageList = [
    {
        name: "",
        tags: [],
        type: AssetType.UnsplashImage,
        content: {
            id: "zCZVcRBn0Cc",
            color: "#f3f3f3",
            thumbnailUrl: "https://images.unsplash.com/photo-1662499840292-0805bbc95005?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wxMDE5OXwwfDF8Y29sbGVjdGlvbnwxfDU3NDMzMXx8fHx8Mnx8MTcyMzc2ODE4Mnw&ixlib=rb-4.0.3&q=80&w=400",
            urls: {
                raw: "https://images.unsplash.com/photo-1662499840292-0805bbc95005?ixid=M3wxMDE5OXwwfDF8Y29sbGVjdGlvbnwxfDU3NDMzMXx8fHx8Mnx8MTcyMzc2ODE4Mnw&ixlib=rb-4.0.3",
                full: "https://images.unsplash.com/photo-1662499840292-0805bbc95005?crop=entropy&cs=srgb&fm=jpg&ixid=M3wxMDE5OXwwfDF8Y29sbGVjdGlvbnwxfDU3NDMzMXx8fHx8Mnx8MTcyMzc2ODE4Mnw&ixlib=rb-4.0.3&q=85",
                regular: "https://images.unsplash.com/photo-1662499840292-0805bbc95005?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wxMDE5OXwwfDF8Y29sbGVjdGlvbnwxfDU3NDMzMXx8fHx8Mnx8MTcyMzc2ODE4Mnw&ixlib=rb-4.0.3&q=80&w=1080",
                small: "https://images.unsplash.com/photo-1662499840292-0805bbc95005?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wxMDE5OXwwfDF8Y29sbGVjdGlvbnwxfDU3NDMzMXx8fHx8Mnx8MTcyMzc2ODE4Mnw&ixlib=rb-4.0.3&q=80&w=400",
                thumb: "https://images.unsplash.com/photo-1662499840292-0805bbc95005?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wxMDE5OXwwfDF8Y29sbGVjdGlvbnwxfDU3NDMzMXx8fHx8Mnx8MTcyMzc2ODE4Mnw&ixlib=rb-4.0.3&q=80&w=200",
            },
            userName: "Yerko Lucic",
            userPortfolio: "https://www.non.cl/",
            unsplashProfile: "https://unsplash.com/@ylucic",
            downloadUrl: "https://images.unsplash.com/photo-1662499840292-0805bbc95005?crop=entropy&cs=srgb&fm=jpg&ixid=M3wxMDE5OXwwfDF8Y29sbGVjdGlvbnwxfDU3NDMzMXx8fHx8Mnx8MTcyMzc2ODE4Mnw&ixlib=rb-4.0.3&q=85",
            downloadLocation: "https://api.unsplash.com/photos/zCZVcRBn0Cc/download?ixid=M3wxMDE5OXwwfDF8Y29sbGVjdGlvbnwxfDU3NDMzMXx8fHx8Mnx8MTcyMzc2ODE4Mnw",
            altDescription: "a person sitting on the floor",
        },
    },
    {
        name: "",
        tags: [],
        type: AssetType.UnsplashImage,
        content: {
            id: "wyl4NFElEGw",
            color: "#0c2626",
            thumbnailUrl: "https://images.unsplash.com/photo-1662943523548-373718f22124?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wxMDE5OXwwfDF8Y29sbGVjdGlvbnwyfDU3NDMzMXx8fHx8Mnx8MTcyMzc2ODE4Mnw&ixlib=rb-4.0.3&q=80&w=400",
            urls: {
                raw: "https://images.unsplash.com/photo-1662943523548-373718f22124?ixid=M3wxMDE5OXwwfDF8Y29sbGVjdGlvbnwyfDU3NDMzMXx8fHx8Mnx8MTcyMzc2ODE4Mnw&ixlib=rb-4.0.3",
                full: "https://images.unsplash.com/photo-1662943523548-373718f22124?crop=entropy&cs=srgb&fm=jpg&ixid=M3wxMDE5OXwwfDF8Y29sbGVjdGlvbnwyfDU3NDMzMXx8fHx8Mnx8MTcyMzc2ODE4Mnw&ixlib=rb-4.0.3&q=85",
                regular: "https://images.unsplash.com/photo-1662943523548-373718f22124?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wxMDE5OXwwfDF8Y29sbGVjdGlvbnwyfDU3NDMzMXx8fHx8Mnx8MTcyMzc2ODE4Mnw&ixlib=rb-4.0.3&q=80&w=1080",
                small: "https://images.unsplash.com/photo-1662943523548-373718f22124?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wxMDE5OXwwfDF8Y29sbGVjdGlvbnwyfDU3NDMzMXx8fHx8Mnx8MTcyMzc2ODE4Mnw&ixlib=rb-4.0.3&q=80&w=400",
                thumb: "https://images.unsplash.com/photo-1662943523548-373718f22124?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wxMDE5OXwwfDF8Y29sbGVjdGlvbnwyfDU3NDMzMXx8fHx8Mnx8MTcyMzc2ODE4Mnw&ixlib=rb-4.0.3&q=80&w=200",
            },
            userName: "Javier Miranda",
            userPortfolio: "https://nuvaproductions.com/",
            unsplashProfile: "https://unsplash.com/@nuvaproductions",
            downloadUrl: "https://images.unsplash.com/photo-1662943523548-373718f22124?crop=entropy&cs=srgb&fm=jpg&ixid=M3wxMDE5OXwwfDF8Y29sbGVjdGlvbnwyfDU3NDMzMXx8fHx8Mnx8MTcyMzc2ODE4Mnw&ixlib=rb-4.0.3&q=85",
            downloadLocation: "https://api.unsplash.com/photos/wyl4NFElEGw/download?ixid=M3wxMDE5OXwwfDF8Y29sbGVjdGlvbnwyfDU3NDMzMXx8fHx8Mnx8MTcyMzc2ODE4Mnw",
            altDescription: "a close up of the moon",
        },
    },
];
var blockList = [
    {
        name: "First cool block",
        tags: [Category.Business, Category.Marketing],
        type: AssetType.Block,
        content: {
            _id: "5e72ea8c837bdc0009d8bf36",
            name: "First cool block",
            type: Models_1.BlockType.Any,
            cssBlockName: "splash-module",
            settings: {},
            contentAreas: {},
            style: {},
        },
    },
];
void unsplashImageList;
void unsplashCollectionList;
void blockList;
