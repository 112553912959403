"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Types_1 = require("@CommonFrontendBackend/AssetLibrary/Types");
var MediaUsageReporter = (function () {
    function MediaUsageReporter(recentlyUsedService, imageService) {
        this.recentlyUsedService = recentlyUsedService;
        this.imageService = imageService;
    }
    MediaUsageReporter.prototype.reportUsage = function (mediaSource, mediaData) {
        if (Types_1.NonImageMediaSourceList.includes(mediaSource)) {
            return;
        }
        this.recentlyUsedService.addRecentlyUsed(mediaSource, mediaData);
        if (mediaSource === Types_1.MediaSource.Unsplash) {
            this.imageService.reportDownload(mediaData);
        }
    };
    return MediaUsageReporter;
}());
exports.default = MediaUsageReporter;
