// Generated by CoffeeScript 1.12.7
(function () {
  var SubscriptionController;

  module.exports = SubscriptionController = (function () {
    function SubscriptionController(
      createUi,
      make,
      account,
      initSubscription,
      cancelSubscription,
      waitFor,
      mountReactComponent,
      manualTooltip,
    ) {
      var article, createPaymentUI, plan;
      createPaymentUI = (function (_this) {
        return function () {
          var haveCard, ref, ref1, subscribed;
          subscribed = ((ref = account.stripe) != null ? ref.subToken : void 0) != null;
          haveCard = ((ref1 = account.stripe) != null ? ref1.customerToken : void 0) != null;
          if (_this.ui.paymentUI == null && (subscribed || haveCard)) {
            return (_this.ui.paymentUI = make(require("./Payment/Payment")));
          }
        };
      })(this);
      if (account.plan.displayName === "Trial") {
        plan = "Trial";
        article = "a";
      } else {
        plan = account.plan.displayName;
        article = "the";
      }
      this.ui = createUi(require("./Subscription.html"), {
        loadingInvoices: true,
        invoices: null,
        subscribed: function () {
          var ref;
          return ((ref = account.stripe) != null ? ref.subToken : void 0) != null;
        },
        haveCard: function () {
          var ref;
          return ((ref = account.stripe) != null ? ref.customerToken : void 0) != null;
        },
        plan: plan,
        article: article,
        selectPlan: function () {
          return initSubscription.init();
        },
        disableCancelSubscription: function () {
          var ref;
          return !(
            ((ref = account.stripe) != null ? ref.subToken : void 0) != null &&
            account.plan.displayName !== "Enterprise" &&
            !account.hasCustomPlanAgreement
          );
        },
        cancelSubscription: cancelSubscription.cancel,
        payment: (function (_this) {
          return function () {
            if (_this.ui.paymentUI != null) {
              return _this.ui.paymentUI.ui;
            }
          };
        })(this),
        viewInvoices: (function (_this) {
          return function () {
            if (_this.ui.invoices != null) {
              return _this.ui.invoices.open();
            }
          };
        })(this),
        $onElementRendered: (function (_this) {
          return function (element) {
            var ref;
            if (((ref = account.stripe) != null ? ref.customerToken : void 0) != null) {
              return waitFor({
                type: "check",
                checkFunction: function () {
                  return window.AccountDock != null;
                },
              }).then(function () {
                _this.ui.loadingInvoices = false;
                return (_this.ui.invoices = window.AccountDock.configure({
                  key: window.QwilrConfig.accountDockKey,
                  customer: account.stripe.customerToken,
                }));
              });
            }
          };
        })(this),
        maybeShowTooltip: (function (_this) {
          return function () {
            var selector, tooltipConfig;
            if (!_this.ui.disableCancelSubscription()) {
              return;
            }
            selector = ".settings-page .cancel-subscription-tooltip";
            tooltipConfig = {
              message: "Please contact support to cancel your subscription",
              trigger: "manual",
              placement: "bottom",
              container: $(selector),
              autoClose: true,
            };
            manualTooltip("Please contact support to cancel your subscription", selector, tooltipConfig);
          };
        })(this),
      });
      createPaymentUI();
      if (!this.ui.subscribed()) {
        this.ui.selectPlan();
      }
    }

    return SubscriptionController;
  })();
}.call(this));
