"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.maybeInsertVideoFromSidebar = exports.maybeUpdateVideoDropLocation = void 0;
var lodash_1 = require("lodash");
var slate_1 = require("slate");
var slate_react_1 = require("slate-react");
var Utils_1 = require("../Utils");
var DragStore_1 = require("Project/Stores/DragStore/DragStore");
var IsDomElement_1 = require("Utils/IsDomElement/IsDomElement");
var DropLocationStore_1 = require("Project/Stores/DropLocationStore/DropLocationStore");
var DragUtils_1 = require("./DragUtils");
var AccordionActions_1 = require("./Accordion/AccordionActions");
var Video_1 = require("../WidgetRegistry/Widgets/Video");
var ImageActions_1 = require("./ImageActions");
var maybeUpdateVideoDropLocation = function (editor, event, dragStore, dropLocationStore) {
    if (!(0, IsDomElement_1.isDOMElement)(event.target)) {
        return;
    }
    var isVideoDragEvent = (0, DragUtils_1.checkIsVideoDragEvent)(dragStore);
    if (!isVideoDragEvent) {
        return;
    }
    var eventTargetNode = slate_react_1.ReactEditor.toSlateNode(editor, event.target);
    var eventTargetPath = slate_react_1.ReactEditor.findPath(editor, eventTargetNode);
    var isContentNode = function (node) { return slate_1.Element.isElement(node) && slate_1.Editor.isBlock(editor, node); };
    var dropTargetNodeEntry = (0, Utils_1.checkSelfAndAncestors)(editor, eventTargetPath, function (node) { return slate_1.Element.isElement(node) && node.type === "accordionHeading"; });
    if (dropTargetNodeEntry) {
        dropTargetNodeEntry = (0, Utils_1.checkSelfAndAncestors)(editor, eventTargetPath, AccordionActions_1.accordionMatcher);
    }
    if (!dropTargetNodeEntry) {
        dropTargetNodeEntry = (0, Utils_1.checkSelfAndAncestors)(editor, eventTargetPath, function (node) { return slate_1.Element.isElement(node) && node.type === "table"; });
    }
    if (!dropTargetNodeEntry) {
        dropTargetNodeEntry = (0, Utils_1.checkSelfAndAncestors)(editor, eventTargetPath, isContentNode);
    }
    if (!dropTargetNodeEntry) {
        return true;
    }
    var _a = __read(dropTargetNodeEntry, 2), dropTargetNode = _a[0], dropTargetPath = _a[1];
    if (["sideBySideColumn", "accordionBody"].includes(dropTargetNode.type)) {
        var lastChildPath = __spreadArray(__spreadArray([], __read(dropTargetPath), false), [dropTargetNode.children.length - 1], false);
        var _b = __read(slate_1.Editor.node(editor, lastChildPath), 1), lastChildNode = _b[0];
        var lastChildBottom = slate_react_1.ReactEditor.toDOMNode(editor, lastChildNode).getBoundingClientRect().bottom;
        var eventIsBelowLastChild = lastChildBottom < event.clientY;
        if (eventIsBelowLastChild) {
            dropLocationStore.setDropLocation({
                editorId: editor.uniqueId,
                path: slate_1.Path.next(lastChildPath),
                type: DropLocationStore_1.DropLocationType.BetweenBlocks,
            });
            return true;
        }
        return true;
    }
    var dropTargetRect = slate_react_1.ReactEditor.toDOMNode(editor, dropTargetNode).getBoundingClientRect();
    if (dropTargetNode.type === "sideBySide") {
        if (dropTargetRect.top < event.clientY && event.clientY < dropTargetRect.bottom) {
            return true;
        }
    }
    var halfWayPoint = dropTargetRect.top + dropTargetRect.height / 2;
    var insertAbove = event.clientY < halfWayPoint;
    dropLocationStore.setDropLocation({
        editorId: editor.uniqueId,
        path: insertAbove ? dropTargetPath : slate_1.Path.next(dropTargetPath),
        type: DropLocationStore_1.DropLocationType.BetweenBlocks,
    });
    return true;
};
exports.maybeUpdateVideoDropLocation = maybeUpdateVideoDropLocation;
var maybeInsertVideoFromSidebar = function (editor, dragStore, dropLocationStore, mediaUsageReporter) {
    var currentDragItem = dragStore.findDragItemFromCurrentDrag(DragStore_1.DragType.SidebarVideo);
    var dropLocation = dropLocationStore.dropLocation;
    if (currentDragItem && (dropLocation === null || dropLocation === void 0 ? void 0 : dropLocation.editorId) === editor.uniqueId) {
        var mediaData = currentDragItem.mediaData, mediaSource = currentDragItem.mediaSource;
        var videoNode = (0, Video_1.createVideoNode)(mediaData.url);
        var insertionPath = dropLocation.path;
        if (dropLocation.type === DropLocationStore_1.DropLocationType.TwoColumnPlaceholder) {
            (0, ImageActions_1.dismissSideBySidePlaceholderFromDrop)(editor, insertionPath);
        }
        slate_1.Transforms.insertNodes(editor, videoNode, { at: insertionPath });
        slate_1.Transforms.select(editor, insertionPath);
        (0, lodash_1.defer)(function () {
            slate_react_1.ReactEditor.focus(editor);
        });
        mediaUsageReporter.reportUsage(mediaSource, mediaData);
        dropLocationStore.clearDropLocation();
        return true;
    }
};
exports.maybeInsertVideoFromSidebar = maybeInsertVideoFromSidebar;
