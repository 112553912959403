"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogoUploader = void 0;
var styles = __importStar(require("./LogoUploader.css"));
var LogoUploader_css_1 = require("./LogoUploader.css");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var react_1 = __importStar(require("react"));
var icons_1 = require("@qwilr/kaleidoscope/icons");
var inversify_react_1 = require("inversify-react");
var lodash_1 = require("lodash");
var react_transition_group_1 = require("react-transition-group");
var dynamic_1 = require("@vanilla-extract/dynamic");
var BrandFetchDetails_1 = require("@CommonFrontendBackend/Account/Brand/BrandFetchDetails");
var ModalComponent_1 = require("Modal/ModalComponent");
var mobx_react_1 = require("mobx-react");
var LogoTooltipContent = function () {
    return (react_1.default.createElement(kaleidoscope_1.Stack, { direction: "vertical", justify: "center" },
        react_1.default.createElement(kaleidoscope_1.Text, { size: "s", strong: true, className: styles.tooltipHeading }, "Logo Recommendations"),
        react_1.default.createElement(kaleidoscope_1.Text, { size: "xs", className: styles.tooltipText }, "File type: .jpg, .png, or .svg"),
        react_1.default.createElement(kaleidoscope_1.Text, { size: "xs", className: styles.tooltipText }, "Resolution: At least 1080 x 1080 pixels"),
        react_1.default.createElement(kaleidoscope_1.Text, { size: "xs", className: styles.tooltipText }, "Maximum File Size: 25Mb")));
};
exports.LogoUploader = (0, mobx_react_1.observer)(function (_a) {
    var _b;
    var dataStore = _a.dataStore;
    var uploadService = (0, inversify_react_1.useInjection)("uploadService");
    var _c = __read((0, react_1.useState)(0), 2), currentLogoIdx = _c[0], setCurrentLogoIdx = _c[1];
    var _d = __read((0, react_1.useState)(false), 2), showLimitReachedModal = _d[0], setShowLimitReachedModal = _d[1];
    var fileInputRef = react_1.default.createRef();
    var hasReachedCountLimit = dataStore.logoUrls.length >= BrandFetchDetails_1.MAX_BRAND_FETCH_LOGOS_COUNT;
    var scrollLogo = function (direction) {
        var newLogoIdx = (0, lodash_1.clamp)(currentLogoIdx + (direction === "left" ? -1 : 1), 0, dataStore.logoUrls.length - 1);
        setCurrentLogoIdx(newLogoIdx);
    };
    var deleteLogo = function (index) {
        if (index <= dataStore.logoUrls.length - 1) {
            dataStore.deleteLogo(index);
            if (currentLogoIdx == dataStore.logoUrls.length) {
                scrollLogo("left");
            }
        }
    };
    var uploadLogo = function () { return __awaiter(void 0, void 0, Promise, function () {
        var file, localUrl;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (hasReachedCountLimit) {
                        setShowLimitReachedModal(true);
                        return [2];
                    }
                    if (!fileInputRef.current || !fileInputRef.current.files) {
                        return [2];
                    }
                    file = fileInputRef.current.files[0];
                    if (!file) {
                        return [2];
                    }
                    uploadService.validateFileUpload({ file: file });
                    return [4, uploadService.loadImageLocally(file, lodash_1.noop)];
                case 1:
                    localUrl = _a.sent();
                    dataStore.addLogo(localUrl);
                    setCurrentLogoIdx(0);
                    return [2];
            }
        });
    }); };
    var UploadButton = function (_a) {
        var size = _a.size, className = _a.className;
        return (react_1.default.createElement(kaleidoscope_1.Button, { isRound: true, isIconOnly: true, as: "label", type: kaleidoscope_1.ButtonType.Secondary, size: size, onKeyDown: function (event) {
                var _a;
                if (event.key === "Enter" || event.key === " ") {
                    (_a = fileInputRef === null || fileInputRef === void 0 ? void 0 : fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click();
                }
            }, tabIndex: 0, className: className, "aria-label": "Upload image" },
            react_1.default.createElement(icons_1.Upload, null),
            react_1.default.createElement("input", { type: "file", className: styles.uploadInput, ref: fileInputRef, accept: "image/*", onChange: uploadLogo, tabIndex: -1 })));
    };
    var SliderCount = function (_a) {
        var currentElement = _a.currentElement, totalElements = _a.totalElements;
        return (react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", gap: "xs", justify: "center", className: styles.sliderCount }, new Array(totalElements).fill(0).map(function (_, index) { return (react_1.default.createElement(react_transition_group_1.CSSTransition, { classNames: styles.sliderCountElement(), timeout: 500, key: "element--".concat(index) },
            react_1.default.createElement("div", { className: styles.sliderCountElement(index === currentElement ? { position: "current" } : { position: "default" }), onClick: function () {
                    setCurrentLogoIdx(index);
                } }))); })));
    };
    return (react_1.default.createElement("div", { className: styles.root },
        react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", gap: "xxs", className: styles.label },
            react_1.default.createElement(kaleidoscope_1.Label, { long: true, size: "s" }, "Upload your logo"),
            react_1.default.createElement(kaleidoscope_1.Tooltip, { wrapTarget: true, content: react_1.default.createElement(LogoTooltipContent, null), size: kaleidoscope_1.TooltipSize.Large, className: styles.logoTooltip },
                react_1.default.createElement(icons_1.Help, { size: icons_1.IconSize.Small, color: "default" }))),
        react_1.default.createElement("div", { className: styles.container },
            react_1.default.createElement("div", { className: styles.content },
                react_1.default.createElement(react_transition_group_1.Transition, { in: dataStore.logoUrls.length > 0 && currentLogoIdx !== 0, mountOnEnter: true, unmountOnExit: true, timeout: kaleidoscope_1.AnimationDuration.Short }, function (status) { return (react_1.default.createElement(kaleidoscope_1.IconButton, { className: styles.sliderButton({ position: "left", status: status }), icon: react_1.default.createElement(icons_1.ChevronLeft, null), isRound: true, type: kaleidoscope_1.ButtonType.Secondary, size: kaleidoscope_1.ButtonSize.Small, onClick: function () { return scrollLogo("left"); } })); }),
                react_1.default.createElement(react_transition_group_1.Transition, { in: dataStore.logoUrls.length > 0 && currentLogoIdx !== dataStore.logoUrls.length - 1, mountOnEnter: true, unmountOnExit: true, timeout: kaleidoscope_1.AnimationDuration.Short }, function (status) { return (react_1.default.createElement(kaleidoscope_1.IconButton, { className: styles.sliderButton({ position: "right", status: status }), icon: react_1.default.createElement(icons_1.ChevronRight, null), isRound: true, type: kaleidoscope_1.ButtonType.Secondary, size: kaleidoscope_1.ButtonSize.Small, onClick: function () { return scrollLogo("right"); } })); }),
                dataStore.logoUrls.length > 0 ? (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement(kaleidoscope_1.IconButton, { className: styles.deleteButton, icon: react_1.default.createElement(icons_1.Delete, null), isRound: true, type: kaleidoscope_1.ButtonType.Secondary, size: kaleidoscope_1.ButtonSize.Small, onClick: function () {
                            deleteLogo(currentLogoIdx);
                        } }),
                    react_1.default.createElement(UploadButton, { size: kaleidoscope_1.ButtonSize.Small, className: styles.smallUploadButton }),
                    showLimitReachedModal && (react_1.default.createElement(ModalComponent_1.AcknowledgeModal, { key: "upload-button-modal", buttonLabel: "Ok", callback: function () {
                            setShowLimitReachedModal(false);
                        }, isEscapable: true },
                        react_1.default.createElement(ModalComponent_1.StandardModalHeader, { title: "Limit Reached!", explainer: "Sorry, for now you can only upload up to ".concat(BrandFetchDetails_1.MAX_BRAND_FETCH_LOGOS_COUNT, " logos, but you can always upload more images later in your Image Library.") }))),
                    react_1.default.createElement("div", { className: styles.logoSlider, style: (0, dynamic_1.assignInlineVars)((_b = {},
                            _b[LogoUploader_css_1.logoIndex] = currentLogoIdx.toString(),
                            _b)) }, dataStore.logoUrls.map(function (l, i) { return (react_1.default.createElement(kaleidoscope_1.Card, { key: "logo-image-".concat(i), className: styles.logoCard },
                        react_1.default.createElement("img", { alt: "logo-image-".concat(i), className: styles.logo, src: l }))); })))) : (react_1.default.createElement("div", { className: styles.emptyCardWrapper },
                    react_1.default.createElement(kaleidoscope_1.Card, { className: styles.emptyCard },
                        react_1.default.createElement(UploadButton, { size: kaleidoscope_1.ButtonSize.Large, className: styles.uploadButton })))))),
        react_1.default.createElement(kaleidoscope_1.Expandable, { expanded: dataStore.logoUrls.length > 0, timeout: kaleidoscope_1.AnimationDuration.Short }, function (_a) {
            var expandableElementRef = _a.expandableElementRef;
            return (react_1.default.createElement("div", { id: "slider-count", ref: expandableElementRef, "aria-live": "polite" },
                react_1.default.createElement(SliderCount, { totalElements: dataStore.logoUrls.length, currentElement: currentLogoIdx })));
        })));
});
