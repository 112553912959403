import "Application/Library/AutomationLibrary/AutomationActivityLog.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Library/AutomationLibrary/AutomationActivityLog.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6XS326CMBQG8Hue4tws2S5qwOlUvNwj+ACmtAWKpWWlVZdl776IEykqf+Ilyffrd0rPZBtEX6kqhQ8/HsCBU5OGMJ2/rD0AtWc6FuoQQsopZXLt/XqTCwjGgulY8N4EwQAwc4DfD+YViAU7huCDD9gadUJECaVD2GP9ilD1gThRcrsNIrHIsqB4c475qI7JsU64REYVF1kWmHCZoLyGwc6Vi0oWmFIukx4GEGGyS7SykqI7E5ZWx5iwDSNKUqy/a83dzuWozv9bRcoYlbfT5TWdDZ/wU3PDCRY1zt0BV9WAd7xhR3ODVy7GTcxlyjQ3TiDqOn1jCWHl9VoL93DyzGS0E2NreGPDlq5llY2UpkwjjSm3ZQjz04Y3QvE5NGhJWvc8v/WDtW+HjTtbMrz27ss/7L1J793idERx6/eKzt5W2Lq1vG/DsiceeveEFV22RjMX5eNX+g+zF4eNNAYAAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var col1 = '_1bqhosl0';
export var col2 = '_1bqhosl1';
export var col3 = '_1bqhosl2';
export var col4 = '_1bqhosl3';
export var col5 = '_1bqhosl4';
export var icon = '_1bqhosl5';
export var expandableContent = '_1bqhosl6';
export var executionOverview = '_1bqhosl7';
export var executionErrorOverview = '_1bqhosl8';
export var executionErrorText = '_1bqhosl9';
export var statusText = _7a468({defaultClassName:'_1bqhosla',variantClassNames:{outcome:{success:'_1bqhoslb',failure:'_1bqhoslc',review:'_1bqhosld'}},defaultVariants:{},compoundVariants:[]});
export var statusIcon = _7a468({defaultClassName:'_1bqhosle',variantClassNames:{outcome:{success:'_1bqhoslf',failure:'_1bqhoslg',review:'_1bqhoslh'}},defaultVariants:{},compoundVariants:[]});
export var actionText = _7a468({defaultClassName:'_1bqhosli',variantClassNames:{outcome:{'partial-success':'_1bqhoslj','not-executed':'_1bqhoslk',success:'_1bqhosll',failure:'_1bqhoslm'}},defaultVariants:{},compoundVariants:[]});