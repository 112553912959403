"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeStrikethrough = exports.makeItalic = exports.makeBold = void 0;
var EditorTypes_1 = require("../EditorTypes");
var EditorMarks_1 = require("../Marks/EditorMarks");
var makeBold = function (editor) {
    toggleMark(editor, EditorTypes_1.MarkType.Bold);
};
exports.makeBold = makeBold;
var makeItalic = function (editor) {
    toggleMark(editor, EditorTypes_1.MarkType.Italic);
};
exports.makeItalic = makeItalic;
var makeStrikethrough = function (editor) {
    toggleMark(editor, EditorTypes_1.MarkType.Strikethrough);
};
exports.makeStrikethrough = makeStrikethrough;
var toggleMark = function (editor, format) {
    var marks = (0, EditorMarks_1.getCurrentMarks)(editor);
    var isMarkActive = marks.some(function (m) { return m.type == format; });
    if (isMarkActive) {
        (0, EditorMarks_1.removeMark)({ type: format, data: {} }, editor);
    }
    else {
        (0, EditorMarks_1.addMark)({ type: format, data: {} }, editor);
    }
};
