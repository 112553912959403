import "Application/Library/AutomationLibrary/SyncAccepterResponses/MappingTable.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Library/AutomationLibrary/SyncAccepterResponses/MappingTable.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VTy27CMBC88xV7QSoHVwmF0rrX/kE/ADm2CQvGjuwND1X99yqGJqY8VNRKPUXyzmR3Z2fuwyOiGWbw3gNQGCojdhxmRm9fehC/TKHXktBZDtKZemVfeh+9+z1v+DOed5vmuXBeac8KR+RWHPJqC8EZVLAW/o4x6Yxrqg1oOs0LM1ks8t0gafdw25gAG1Q05zAa95P+Hss53dAeoBQVP6BCJSTakm1Di8kxgiqhFNryOzDBLQZ/VDNdzex7u4AHqbURhGvdvC7qQDjbMeksaUscpLakfSLoKAp6UGm8V+k/97jdgeN0gzzLjlfIvl8tPds8tdbj6X8Sl9P8vPOEwdIyJL0KnbpXhP+dlU6BZ4PWzj2JQ3feEEVwpqbojUMIWD6s4ibkKg5SGHk3zvrArh0xdo5p4UfRQelsi6siqhByWXpXW8XOEELtZ0LqllMOupTelM+vYAuFdeAwalZqVXiKKlyY962WUoduPUo98XyN+YpBFEarlrpJqeLYL4VxcnntskkSVpf0Jb2lk64q7VqcMfEnpL+7GOIFAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var wrapper = 's6iil20';
export var table = 's6iil21';
export var tableRow = 's6iil22';
export var leftColumn = 's6iil23';
export var rightColumn = 's6iil24';
export var rightHeaderRow = 's6iil25';
export var integrationName = 's6iil26';
export var rightArrow = _7a468({defaultClassName:'s6iil27',variantClassNames:{highlight:{true:'s6iil28',false:'s6iil29'}},defaultVariants:{},compoundVariants:[]});
export var emptyFormState = 's6iil2a';
export var tokenText = 's6iil2b';