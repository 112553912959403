"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkIsVideoDragEvent = exports.checkIsImageDragEvent = void 0;
var DragStore_1 = require("Project/Stores/DragStore/DragStore");
var DragEventHasFile_1 = require("Utils/DragEventHasFile/DragEventHasFile");
var checkIsImageDragEvent = function (dragStore, event) {
    return !!dragStore.findDragItemFromCurrentDrag(DragStore_1.DragType.SidebarImage) ||
        !!dragStore.findDragItemFromCurrentDrag(DragStore_1.DragType.ImageWidgetFromEditor) ||
        (0, DragEventHasFile_1.dragEventHasFile)(event);
};
exports.checkIsImageDragEvent = checkIsImageDragEvent;
var checkIsVideoDragEvent = function (dragStore) {
    return !!dragStore.findDragItemFromCurrentDrag(DragStore_1.DragType.SidebarVideo);
};
exports.checkIsVideoDragEvent = checkIsVideoDragEvent;
