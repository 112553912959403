// Generated by CoffeeScript 1.12.7

/*
 * Exposes subscription controls for heroin injection
 */

(function () {
  module.exports = {
    initSubscription: function (make, startSubscription, updateSubscription) {
      var InitSubscription;
      InitSubscription = require("./Controls/InitSubscription");
      return make(InitSubscription, {
        startSubscription: startSubscription,
        updateSubscription: updateSubscription,
      });
    },
    startSubscription: function (make) {
      var StartSubscription;
      StartSubscription = require("./Controls/StartSubscription.js");
      return make(StartSubscription);
    },
    updateSubscription: function (make) {
      var UpdateSubscription;
      UpdateSubscription = require("./Controls/UpdateSubscription.js");
      return make(UpdateSubscription);
    },
    startBusinessTrial: function (make) {
      var StartBusinessTrial;
      StartBusinessTrial = require("./Controls/StartBusinessTrial.js");
      return make(StartBusinessTrial);
    },
    subscribeToPlan: function (make) {
      var SubscribeToPlan;
      SubscribeToPlan = require("./Controls/SubscribeToPlan.js");
      return make(SubscribeToPlan);
    },
    cancelSubscription: function (make) {
      var CancelSubscription;
      CancelSubscription = require("./Controls/CancelSubscription.js");
      return make(CancelSubscription);
    },
    isSubscribed: function (account) {
      return function () {
        var ref;
        return ((ref = account.stripe) != null ? ref.subToken : void 0) != null;
      };
    },
    SubscriptionSequencer: function () {
      return require("./Controls/SubscriptionSequencer");
    },
    subscriptionApiService: function (make) {
      return make(require("./SubscriptionService").SubscriptionService);
    },
    subscriptionService: function (initSubscription, cancelSubscription, account, isSubscribed) {
      return {
        start: initSubscription.init,
        cancel: cancelSubscription.cancel,
        isSubscribed: isSubscribed,
      };
    },
  };
}.call(this));
