"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.dismissSideBySidePlaceholderFromDrop = exports.maybeInsertImageFromSidebar = exports.maybeUpdateImageDropLocation = exports.maybeDropImageWidgetWithoutDropIndicator = exports.maybeDropImageWidget = exports.maybeSetDragImageWidget = exports.maybeOpenImageGallery = exports.maybeMoveForwardsInImage = exports.maybeMoveBackwardsInImage = void 0;
var lodash_1 = require("lodash");
var slate_1 = require("slate");
var slate_react_1 = require("slate-react");
var Utils_1 = require("../Utils");
var WidgetActions_1 = require("./WidgetActions");
var DragStore_1 = require("Project/Stores/DragStore/DragStore");
var IsDomElement_1 = require("Utils/IsDomElement/IsDomElement");
var DropLocationStore_1 = require("Project/Stores/DropLocationStore/DropLocationStore");
var SideBySideActions_1 = require("./SideBySide/SideBySideActions");
var DragUtils_1 = require("./DragUtils");
var AccordionActions_1 = require("./Accordion/AccordionActions");
var Image_1 = require("../Widgets/ImageWidget/Image");
var ImageNodeFactory_1 = require("../Widgets/ImageWidget/ImageNodeFactory");
var maybeMoveBackwardsInImage = function (editor) {
    var _a = __read(getSelectedImageEntry(editor), 2), imageNode = _a[0], imagePath = _a[1];
    if (isShowingPlaceholder(imageNode)) {
        return false;
    }
    if (isInnerSelectedImage(imageNode)) {
        setSelectedEmptyInner(false, editor, imageNode, imagePath);
        return true;
    }
    else {
        return false;
    }
};
exports.maybeMoveBackwardsInImage = maybeMoveBackwardsInImage;
var maybeMoveForwardsInImage = function (editor) {
    var _a = __read(getSelectedImageEntry(editor), 2), imageNode = _a[0], imagePath = _a[1];
    if (isShowingPlaceholder(imageNode)) {
        return false;
    }
    if (isInnerSelectedImage(imageNode)) {
        setSelectedEmptyInner(false, editor, imageNode, imagePath);
        return false;
    }
    else {
        setSelectedEmptyInner(true, editor, imageNode, imagePath);
        return true;
    }
};
exports.maybeMoveForwardsInImage = maybeMoveForwardsInImage;
var maybeOpenImageGallery = function (editor, di) {
    var _a = __read(getSelectedImageEntry(editor), 2), imageNode = _a[0], imagePath = _a[1];
    if (isInnerSelectedImage(imageNode)) {
        (0, WidgetActions_1.chooseImage)(editor, di, imagePath);
        setSelectedEmptyInner(false, editor, imageNode, imagePath);
        return true;
    }
    else {
        return false;
    }
};
exports.maybeOpenImageGallery = maybeOpenImageGallery;
var maybeSetDragImageWidget = function (editor, event, dragStore) {
    if (!(0, IsDomElement_1.isDOMElement)(event.target)) {
        return;
    }
    var sourceNode = slate_react_1.ReactEditor.toSlateNode(editor, event.target);
    if (!(0, Image_1.isImageNode)(sourceNode)) {
        return;
    }
    var sourceNodePath = slate_react_1.ReactEditor.findPath(editor, sourceNode);
    slate_1.Transforms.select(editor, sourceNodePath);
    dragStore.startDrag([
        {
            sourcePath: sourceNodePath,
            sourceNode: (0, lodash_1.cloneDeep)(sourceNode),
            sourceEditorId: editor.uniqueId,
            type: DragStore_1.DragType.ImageWidgetFromEditor,
        },
    ]);
    return true;
};
exports.maybeSetDragImageWidget = maybeSetDragImageWidget;
var maybeDropImageWidget = function (dragStore, dropLocationStore, editor) {
    var currentDragItem = dragStore.findDragItemFromCurrentDrag(DragStore_1.DragType.ImageWidgetFromEditor);
    var dropLocation = dropLocationStore.dropLocation;
    if (currentDragItem && dropLocation) {
        var destinationPathRef = slate_1.Editor.pathRef(editor, dropLocation.path);
        var sourceEditorId = currentDragItem.sourceEditorId, sourcePath = currentDragItem.sourcePath;
        if (editor.uniqueId === sourceEditorId) {
            slate_1.Transforms.removeNodes(editor, { at: sourcePath, voids: true });
        }
        if (editor.uniqueId === dropLocation.editorId) {
            slate_1.Transforms.insertNodes(editor, currentDragItem.sourceNode, { at: destinationPathRef.current });
            slate_1.Transforms.select(editor, slate_1.Path.previous(destinationPathRef.current));
            (0, lodash_1.defer)(function () {
                slate_react_1.ReactEditor.focus(editor);
            });
            if (dropLocation.type === DropLocationStore_1.DropLocationType.TwoColumnPlaceholder) {
                (0, exports.dismissSideBySidePlaceholderFromDrop)(editor, destinationPathRef.current);
            }
            dragStore.stopDrag();
            dropLocationStore.clearDropLocation();
            destinationPathRef.unref();
            return true;
        }
    }
    return;
};
exports.maybeDropImageWidget = maybeDropImageWidget;
var maybeDropImageWidgetWithoutDropIndicator = function (dragStore, event, editor) {
    var currentDragItem = dragStore.findDragItemFromCurrentDrag(DragStore_1.DragType.ImageWidgetFromEditor);
    if (currentDragItem) {
        var destinationRange = slate_react_1.ReactEditor.findEventRange(editor, event);
        var destinationRangeRef = slate_1.Editor.rangeRef(editor, destinationRange);
        var sourceEditorId = currentDragItem.sourceEditorId, sourcePath = currentDragItem.sourcePath;
        if (editor.uniqueId === sourceEditorId) {
            slate_1.Transforms.removeNodes(editor, { at: sourcePath, voids: true });
        }
        slate_1.Transforms.select(editor, destinationRangeRef.current);
        slate_react_1.ReactEditor.insertData(editor, event.dataTransfer);
        dragStore.stopDrag();
        destinationRangeRef.unref();
        return true;
    }
    return;
};
exports.maybeDropImageWidgetWithoutDropIndicator = maybeDropImageWidgetWithoutDropIndicator;
var maybeUpdateImageDropLocation = function (editor, event, dragStore, dropLocationStore) {
    if (!(0, IsDomElement_1.isDOMElement)(event.target)) {
        return;
    }
    var isImageDragEvent = (0, DragUtils_1.checkIsImageDragEvent)(dragStore, event);
    if (!isImageDragEvent) {
        return;
    }
    var eventTargetNode = slate_react_1.ReactEditor.toSlateNode(editor, event.target);
    var eventTargetPath = slate_react_1.ReactEditor.findPath(editor, eventTargetNode);
    var isContentNode = function (node) { return slate_1.Element.isElement(node) && slate_1.Editor.isBlock(editor, node); };
    var setDropLocation = function (location) {
        var existingImageWidget = dragStore.findDragItemFromCurrentDrag(DragStore_1.DragType.ImageWidgetFromEditor);
        if (existingImageWidget) {
            var sourcePath = existingImageWidget.sourcePath, sourceEditorId = existingImageWidget.sourceEditorId;
            var isSamePathAsSource = (0, lodash_1.isEqual)(sourcePath, location.path) || (0, lodash_1.isEqual)(slate_1.Path.next(sourcePath), location.path);
            var isSameEditor = editor.uniqueId === sourceEditorId;
            if (isSameEditor && isSamePathAsSource) {
                dropLocationStore.clearDropLocation();
                return;
            }
        }
        dropLocationStore.setDropLocation(location);
    };
    var dropTargetNodeEntry = (0, Utils_1.checkSelfAndAncestors)(editor, eventTargetPath, function (node) { return slate_1.Element.isElement(node) && node.type === "accordionHeading"; });
    if (dropTargetNodeEntry) {
        dropTargetNodeEntry = (0, Utils_1.checkSelfAndAncestors)(editor, eventTargetPath, AccordionActions_1.accordionMatcher);
    }
    if (!dropTargetNodeEntry) {
        dropTargetNodeEntry = (0, Utils_1.checkSelfAndAncestors)(editor, eventTargetPath, function (node) { return slate_1.Element.isElement(node) && node.type === "table"; });
    }
    if (!dropTargetNodeEntry) {
        dropTargetNodeEntry = (0, Utils_1.checkSelfAndAncestors)(editor, eventTargetPath, isContentNode);
    }
    if (!dropTargetNodeEntry) {
        return true;
    }
    var _a = __read(dropTargetNodeEntry, 2), dropTargetNode = _a[0], dropTargetPath = _a[1];
    if (["sideBySideColumn", "accordionBody"].includes(dropTargetNode.type)) {
        var lastChildPath = __spreadArray(__spreadArray([], __read(dropTargetPath), false), [dropTargetNode.children.length - 1], false);
        var _b = __read(slate_1.Editor.node(editor, lastChildPath), 1), lastChildNode = _b[0];
        var lastChildBottom = slate_react_1.ReactEditor.toDOMNode(editor, lastChildNode).getBoundingClientRect().bottom;
        var eventIsBelowLastChild = lastChildBottom < event.clientY;
        if (eventIsBelowLastChild) {
            setDropLocation({
                editorId: editor.uniqueId,
                path: slate_1.Path.next(lastChildPath),
                type: DropLocationStore_1.DropLocationType.BetweenBlocks,
            });
            return true;
        }
        return true;
    }
    var dropTargetRect = slate_react_1.ReactEditor.toDOMNode(editor, dropTargetNode).getBoundingClientRect();
    if (dropTargetNode.type === "sideBySide") {
        if (dropTargetRect.top < event.clientY && event.clientY < dropTargetRect.bottom) {
            return true;
        }
    }
    var halfWayPoint = dropTargetRect.top + dropTargetRect.height / 2;
    var insertAbove = event.clientY < halfWayPoint;
    setDropLocation({
        editorId: editor.uniqueId,
        path: insertAbove ? dropTargetPath : slate_1.Path.next(dropTargetPath),
        type: DropLocationStore_1.DropLocationType.BetweenBlocks,
    });
    return true;
};
exports.maybeUpdateImageDropLocation = maybeUpdateImageDropLocation;
var maybeInsertImageFromSidebar = function (editor, dragStore, dropLocationStore, mediaUsageReporter, blockType, editorTelemetry) {
    var currentDragItem = dragStore.findDragItemFromCurrentDrag(DragStore_1.DragType.SidebarImage);
    var dropLocation = dropLocationStore.dropLocation;
    if (currentDragItem && (dropLocation === null || dropLocation === void 0 ? void 0 : dropLocation.editorId) === editor.uniqueId) {
        var mediaData = currentDragItem.mediaData, mediaSource = currentDragItem.mediaSource, telemetryData = currentDragItem.telemetryData;
        var imageNode = (0, ImageNodeFactory_1.createImageNode)(mediaData.url, { alt: mediaData.altDescription });
        var insertionPath = dropLocation.path;
        if (dropLocation.type === DropLocationStore_1.DropLocationType.TwoColumnPlaceholder) {
            (0, exports.dismissSideBySidePlaceholderFromDrop)(editor, insertionPath);
        }
        slate_1.Transforms.insertNodes(editor, imageNode, { at: insertionPath });
        slate_1.Transforms.select(editor, insertionPath);
        (0, lodash_1.defer)(function () {
            slate_react_1.ReactEditor.focus(editor);
        });
        mediaUsageReporter.reportUsage(mediaSource, mediaData);
        dropLocationStore.clearDropLocation();
        var originWasFiltered = telemetryData.originWasFiltered, assetFolderType = telemetryData.assetFolderType;
        editorTelemetry.sidebarImageDraggedInto(blockType, (0, Utils_1.isInsideSideBySide)(editor), originWasFiltered, assetFolderType);
        return true;
    }
};
exports.maybeInsertImageFromSidebar = maybeInsertImageFromSidebar;
var getSelectedImageEntry = function (editor) {
    if (editor.selection == null) {
        throw new Error("Tried to get selected image when there is no selection");
    }
    if (slate_1.Range.isExpanded(editor.selection)) {
        throw new Error("Tried getting selected image when selection is expanded");
    }
    var imageEntry = (0, Utils_1.getClosestBlock)(editor, editor.selection.anchor.path);
    if (imageEntry == null) {
        throw new Error("Selection wasn't in a block");
    }
    var imageNode = imageEntry[0];
    if (!(0, Image_1.isImageNode)(imageNode)) {
        throw new Error("Selection wasn't in an image");
    }
    return [imageNode, imageEntry[1]];
};
var setSelectedEmptyInner = function (isSelected, editor, imageNode, path) {
    var updatedData = (0, lodash_1.cloneDeep)(imageNode.data);
    updatedData.selectedEmptyInner = isSelected;
    slate_1.Transforms.setNodes(editor, { data: updatedData }, { at: path });
};
var isInnerSelectedImage = function (imageNode) {
    return isShowingPlaceholder(imageNode) && !!imageNode.data.selectedEmptyInner;
};
var isShowingPlaceholder = function (imageNode) {
    return imageNode.data.src === "" && (imageNode.data.uploadId == null || imageNode.data.uploadId === "");
};
var dismissSideBySidePlaceholderFromDrop = function (editor, insertionPath) {
    var sideBySideNodeEntry = (0, Utils_1.checkAncestors)(editor, insertionPath, function (c) { return slate_1.Element.isElement(c) && c.type === "sideBySide"; });
    if (!sideBySideNodeEntry) {
        throw new Error("Tried to dismiss SBS placeholder from drop but no SBS was found");
    }
    (0, SideBySideActions_1.dismissSideBySidePlaceholder)(editor, sideBySideNodeEntry[1]);
};
exports.dismissSideBySidePlaceholderFromDrop = dismissSideBySidePlaceholderFromDrop;
