// Generated by CoffeeScript 1.12.7

/*
  This module defines the Block Menu for Splash Block.
 */

(function () {
  var isUsingTCDC;

  isUsingTCDC = require("@CommonFrontendBackend/FeatureFlag").isUsingTCDC;

  module.exports = function (make, StyleButton, BlockControls, usingFeature) {
    var blockControls, items, mediaButton, styleButton, superpowers;
    mediaButton = make(require("./Controls/MediaButton/MediaButton"));
    styleButton = make(StyleButton);
    blockControls = make(BlockControls);
    items = [
      {
        name: "Background",
        description: "Choose an image or video for this splash.",
        ctrl: make(require("./Controls/Background/Background")),
      },
    ];
    if (!isUsingTCDC(usingFeature)) {
      items.push({
        name: "Size",
        description: "Set how large your splash.",
        ctrl: make(require("./Controls/Size/Size")),
      });
    }
    superpowers = [
      {
        name: "Style library",
        ctrl: styleButton,
        selectable: false,
        select: styleButton.onClick,
        deselect: styleButton.onClose,
        overflow: true,
      },
      {
        name: "Block Controls",
        ctrl: blockControls,
        doNotWrapControl: true,
        visibleOnViewOnlyBlocks: true,
      },
      {
        name: "Swap splash",
        ctrl: mediaButton,
        selectable: false,
        select: mediaButton.onClick,
      },
    ];
    return {
      settings: {
        useItems: ["foldable", "navigationMenu"],
        items: items,
        superpowers: superpowers,
      },
    };
  };
}.call(this));
