"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PromptOrchestrator = void 0;
var react_1 = __importStar(require("react"));
var PromptTypes_1 = require("Prompt/PromptTypes");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var Prompt_1 = require("./Prompt");
var styles = __importStar(require("./PromptOrchestrator.css"));
var PromptOrchestrator = (function () {
    function PromptOrchestrator(useHistoryDataStore) {
        this.useHistoryDataStore = useHistoryDataStore;
    }
    PromptOrchestrator.prototype.getPrompt = function (promptableRef, id) {
        var staticProps = {
            onShow: this.getViewFn(id),
            onDismiss: this.getDismissFn(id),
        };
        switch (id) {
            case PromptTypes_1.PromptId.AddAgreement:
                return (react_1.default.createElement(PromptOrchestratorPrompt, { show: this.useHistoryDataStore.shouldShowPrompt(PromptTypes_1.PromptId.AddAgreement), targetRef: promptableRef, onDismiss: staticProps.onDismiss, onShow: staticProps.onShow },
                    react_1.default.createElement(kaleidoscope_1.Stack, { gap: "s" },
                        react_1.default.createElement(kaleidoscope_1.Heading, { level: "3", strong: true, className: styles.headingText }, "You can now add agreements"),
                        react_1.default.createElement(kaleidoscope_1.Text, { element: "p", size: "m", className: styles.bodyText },
                            react_1.default.createElement("span", null,
                                "Use Agreements for plain-text, print-friendly documents such as contracts, sales agreements, and more.",
                                " "),
                            react_1.default.createElement(kaleidoscope_1.Link, { href: "https://qwilr.com/blog/new-in-qwilr-agreements?utm_source=qwilr_app&utm_medium=in_editor_prompt&utm_campaign=agreements_launch" }, "Read more here")))));
        }
    };
    PromptOrchestrator.prototype.getDismissFn = function (id) {
        var _this = this;
        return function () { return _this.useHistoryDataStore.dismissedPrompt(id); };
    };
    PromptOrchestrator.prototype.getViewFn = function (id) {
        var _this = this;
        return function () { return _this.useHistoryDataStore.viewedPrompt(id); };
    };
    return PromptOrchestrator;
}());
exports.PromptOrchestrator = PromptOrchestrator;
var PromptOrchestratorPrompt = function (_a) {
    var children = _a.children, targetRef = _a.targetRef, onDismiss = _a.onDismiss, show = _a.show, onShow = _a.onShow;
    var _b = __read((0, react_1.useState)(false), 2), hasMounted = _b[0], setHasMounted = _b[1];
    var hasFiredOnShowRef = (0, react_1.useRef)(false);
    (0, react_1.useEffect)(function () {
        setTimeout(function () {
            setHasMounted(true);
        }, 1000);
    }, []);
    (0, react_1.useEffect)(function () {
        if (!hasFiredOnShowRef.current) {
            onShow();
            hasFiredOnShowRef.current = true;
        }
    }, [show]);
    return (react_1.default.createElement(Prompt_1.Prompt, { targetRef: targetRef, onDismiss: onDismiss, show: show && hasMounted }, children));
};
