import "Application/Project/Modules/Accept/Controls/Agreement/AgreementButton.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/Modules/Accept/Controls/Agreement/AgreementButton.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VV0W7iMBB85yv8UolKMbIptDR9qXSfcB9QOfEm2eLYke2U0BP/frIDIeFKe72TEARrZ3bWs7t53sK+sKIGR154VXPOu4z8mhHCGbuJD4R4K7QrjK3T/lEJD3dyzhISPrdPM0IOs8NscSJgEZeJfFta02qZkjdh55TmRhlLXWsLkcPLC8/Uw+srLyNBBVhW/hypvTXqJ74DdUMk38XQzHTUVUKa3Sm8/0dBwZvwaDQ/Q7IjxEqw1AqJrfurJLWwJeqUrJuOMMLIqumexjXyWONJtWi9CagdSl+lhIfg6zfQqxky7m8nzMvIfGJi7CZQvbbOY7GPmkH7lBQKOuq8sH4CvovgmGaaE3Ojh4zNNOPqGshD5wfQ6gKUVuYN7GfQHxY95kINFI9TCkay1nuj0wKt8zSvUMk0g8JY6Duod82bhioo/HfsO0Iz472pv4n+SKESXwi0oRH+XeE34SOJ66ikFh09tsyGsYtevY8hEl2jxD4lpUUZFIRf6qFuwjwH29pau5RYaED4+V1CeGGj1FI0w6Q1Ikdd0vqsZxtjGiEl6vIy7uw9V1PdD1FUYxyGiU2JyJxRrYdAZse74GuqzbS+TJl8+8HUo67Aop+o5RdX9TgZ69P0XQxjjZoOIetNP+zh8Bi3XF06IP7YiLbMxJwvHxPC75cJ4Q+bhLAFW08ry8kiVkP7lXplEa+aLiHxi12BLz+D0wilnxKwZEolNNaiN44v7sfvDtQFavRAhPJgtQiOjhhl366jy1qy/gILoz11+A7hAkdHhahR7Uf2jdjgP53/w9rpQh9lKvobhM7TsPYKFd4+oBQ2Dl1Ank8rlBJ0JK/QQ2xgSIk2Oyuap+mwDtVf6IiJhMJSpyRsr6DluQaJgswbCwVYRy3INgdJaxOMuI0Cv3Zu4p02Og7cpDdOh4fZ4TdOGcuIHggAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var container = '_1hm111x0';
export var divider = '_1hm111x1';
export var menuButton = '_1hm111x2';
export var icon = '_1hm111x3';
export var menuButtonText = '_1hm111x4';
export var popover = '_1hm111x5';
export var gridContainer = '_1hm111x6';
export var closeButton = '_1hm111x7';
export var image = '_1hm111x8';
export var buttonCard = _7a468({defaultClassName:'_1hm111x9',variantClassNames:{thumbnailLoaded:{false:'_1hm111xa'}},defaultVariants:{},compoundVariants:[]});
export var thumbnailPlaceholderImage = '_1hm111xc';
export var button = '_1hm111xd';
export var thumbnail = '_1hm111xe';
export var agreementCardLabel = '_1hm111xf';