"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidActionConfigValue = exports.buildActionConfig = exports.getMultiselectSelectedValues = void 0;
var zod_1 = require("zod");
var actionConfigValueSchema = zod_1.z.array(zod_1.z.object({
    id: zod_1.z.string().min(1),
    name: zod_1.z.string().min(1),
}));
var isValidActionConfigValue = function (value) {
    return actionConfigValueSchema.safeParse(value).success;
};
exports.isValidActionConfigValue = isValidActionConfigValue;
var buildActionConfig = function (field, selectedIds) {
    var _a;
    var selectedOptions = selectedIds
        .map(function (id) {
        var selectedOption = field.options.find(function (o) { return o.id === id; });
        return { id: id, name: selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.label };
    })
        .filter(function (option) { return option.name != null; });
    return _a = {},
        _a[field.name] = selectedOptions,
        _a;
};
exports.buildActionConfig = buildActionConfig;
var getMultiselectSelectedValues = function (config, field) {
    if (!(field.name in config)) {
        return [];
    }
    var configValues = config[field.name];
    if (!isValidActionConfigValue(configValues)) {
        return [];
    }
    var allAllowedIds = field.options.map(function (o) { return o.id; });
    var validValues = configValues.filter(function (v) { return allAllowedIds.includes(v.id); });
    return validValues.map(function (v) { return v.id; });
};
exports.getMultiselectSelectedValues = getMultiselectSelectedValues;
