import "Application/Project/QwilrEditor/RegistryElement/Resizer.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/RegistryElement/Resizer.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VVTXObMBC9+1fokhkztSjCIXbxqbeee+vJI6Q1lo0lKgk7Taf/vYOAgGySNnY/bki7evv03q4I14QvSrlfxuj7BKFSGWGFkimimVFFZWE1QegkuN2m6Ej1FOPuQBTUIavKFEX1V6asVYduQdk+16qSPEU6z6bkAZFkjuIkRu9RFMaJO/yEheTwmCKycrWFtKAxHEFakyKpJDTAmoPGmnJRmY5Fs/nZ7WFT6Q1lsF6TrFjsdoRsHLwqKRP2W0vJaiq7y7WRFotXmtb7uOgRqqANAjVC5thYKjnVvM/4GswG17wRajX5MQk7Ze+dFT37cOmFH1z4FwonPuTCnSlgY50ag8jSRbTItxehD/6hF7LoeZYTeqP0IW0+C2phzqd+95BghqIZinya2R/EAof1G66b3gjzRtcxU4XSNwJetuqrUxgn5WO93ELjRrc+GxMSRXf1Nqu0UTrtpuu1QXsex9gf4eaWrQMl1SCtp3Tu9+voMAsprKDFOHAjkVtgpqTVqvjIGEj7SR1B91rd+w5v/1rdvuTcLylcyb71WIrIPHQODLJ2l1mYkIu0/aDbGS3YWVuzAL07e3N50D+6SePv2IR8meIkuguG9sMJazDiCTwGxXD4/w+FQzOmNdybCFwhW0dEngyMUZFXUrlGv2cuYEZlUc0j3/5T/5kyL7Apr2dzkzieUT8BIDjbp6sIAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var snapAreaWidth = 'var(--_1d7pnk80)';
export var snapAreaXOffset = 'var(--_1d7pnk81)';
export var resizeableSnapArea = _7a468({defaultClassName:'_1d7pnk82',variantClassNames:{visible:{false:'_1d7pnk83',true:'_1d7pnk84'},highlighted:{false:'_1d7pnk85',true:'_1d7pnk86'}},defaultVariants:{},compoundVariants:[]});
export var resizeableSnapAreaContentStart = '_1d7pnk87';
export var resizeableSnapAreaContentEnd = '_1d7pnk88';
export var resizableSnapAreaContent = '_1d7pnk89';
export var snapAreaWide = '_1d7pnk8a';
export var snapAreaFull = '_1d7pnk8b';
export var resizeHandleSelectionRingOffset = 'var(--_1d7pnk8d)';
export var resizeHandle = _7a468({defaultClassName:'_1d7pnk8e',variantClassNames:{state:{idle:'_1d7pnk8f',hover:'_1d7pnk8g',active:'_1d7pnk8h'},inset:{true:'_1d7pnk8i',false:'_1d7pnk8j'},position:{left:'_1d7pnk8k',right:'_1d7pnk8l',topLeft:'_1d7pnk8m',topRight:'_1d7pnk8n',bottomLeft:'_1d7pnk8o',bottomRight:'_1d7pnk8p'}},defaultVariants:{},compoundVariants:[]});