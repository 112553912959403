"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokensPopover = void 0;
var react_1 = __importStar(require("react"));
var mobx_react_1 = require("mobx-react");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var FetchUtils_1 = require("@CommonFrontendBackend/Utils/FetchUtils");
var TokensPanel_1 = require("Project/Actions/Tokens/TokensPanel/TokensPanel");
var TokensPopover = (0, mobx_react_1.observer)(function (_a) {
    var accountIntegrationsDataStore = _a.accountIntegrationsDataStore, viewOnly = _a.viewOnly;
    (0, react_1.useEffect)(function () {
        accountIntegrationsDataStore.loadTokenIntegrationsIfNeeded();
    }, []);
    var showIconOnly = viewOnly;
    return (react_1.default.createElement(kaleidoscope_1.Popover, { size: kaleidoscope_1.PopoverSize.Large, offset: 20, autoStack: true, button: function (buttonProps) { return (react_1.default.createElement(kaleidoscope_1.Button, __assign({ type: kaleidoscope_1.ButtonType.Secondary, size: kaleidoscope_1.ButtonSize.Medium, isIconOnly: showIconOnly }, buttonProps),
            react_1.default.createElement(icons_1.Tokens, null),
            showIconOnly ? undefined : "Tokens")); } }, renderContent({
        accountIntegrationsDataStore: accountIntegrationsDataStore,
        viewOnly: viewOnly,
    })));
});
exports.TokensPopover = TokensPopover;
var renderContent = function (_a) {
    var tokenIntegrations = _a.accountIntegrationsDataStore.tokenIntegrations, viewOnly = _a.viewOnly;
    switch (tokenIntegrations.status) {
        case FetchUtils_1.RemoteDataStatus.NotRequested:
        case FetchUtils_1.RemoteDataStatus.Loading:
            return (react_1.default.createElement(kaleidoscope_1.Stack, { padding: "l" },
                react_1.default.createElement(kaleidoscope_1.Spinner, { size: kaleidoscope_1.SpinnerSize.Medium, primary: true })));
        case FetchUtils_1.RemoteDataStatus.Error:
            return (react_1.default.createElement(kaleidoscope_1.Stack, { padding: "l" },
                react_1.default.createElement(kaleidoscope_1.Text, { size: "s", align: "center", secondary: true }, tokenIntegrations.error.message)));
        case FetchUtils_1.RemoteDataStatus.Success:
            return react_1.default.createElement(TokensPanel_1.TokensPanel, { viewOnly: viewOnly, tokenIntegrations: tokenIntegrations.data });
        default:
            throw new Error("Unhandled RemoteDataStatus");
    }
};
