// Generated by CoffeeScript 1.12.7

/*
  This module defines the Text Block UI.
  @param block [Object] - the data model for this block.
 */

(function () {
  var blockConfigUtil;

  blockConfigUtil = require("@CommonFrontendBackend/StyleServiceV2/Config/blockConfigUtil");

  module.exports = function (block, make, createUi, Editor, viewState) {
    var mainContent;
    mainContent = make(Editor, {
      model: block.contentAreas.mainContent,
      options: {},
      configuration: "standard",
    });
    return (this.ui = createUi(require("./Text.html"), {
      viewState: viewState,
      mainContent: mainContent.ui,
    }));
  };
}.call(this));
