"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var unsplash_js_1 = require("unsplash-js");
var Types_1 = require("@CommonFrontendBackend/AssetLibrary/Types");
var UnsplashImageService = (function () {
    function UnsplashImageService($log, uploadsService) {
        this.$log = $log;
        this.uploadsService = uploadsService;
        this.unsplash = (0, unsplash_js_1.createApi)({
            accessKey: QwilrConfig.unsplashClientId,
        });
    }
    UnsplashImageService.prototype.listCollections = function (_a) {
        return __awaiter(this, arguments, Promise, function (_b) {
            var collections;
            var page = _b.page, _c = _b.perPage, perPage = _c === void 0 ? 10 : _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4, this.unsplashWrapper(this.unsplash.collections.list({ page: page, perPage: perPage }))];
                    case 1:
                        collections = _d.sent();
                        return [2, collections.filter(emptyCollectionsFilter).map(parseCollection)];
                }
            });
        });
    };
    UnsplashImageService.prototype.getCollection = function (collectionId) {
        return __awaiter(this, void 0, Promise, function () {
            var collection;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.unsplashWrapper(this.unsplash.collections.get({ collectionId: collectionId }))];
                    case 1:
                        collection = _a.sent();
                        return [2, parseCollection(collection)];
                }
            });
        });
    };
    UnsplashImageService.prototype.getCollectionPhotos = function (collectionId_1, _a) {
        return __awaiter(this, arguments, Promise, function (collectionId, _b) {
            var photos;
            var _c = _b.page, page = _c === void 0 ? 0 : _c, _d = _b.perPage, perPage = _d === void 0 ? 10 : _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        page = page + 1;
                        return [4, this.unsplashWrapper(this.unsplash.collections.getPhotos({ collectionId: collectionId, page: page, perPage: perPage }))];
                    case 1:
                        photos = _e.sent();
                        return [2, photos.map(parsePhoto)];
                }
            });
        });
    };
    UnsplashImageService.prototype.getPhoto = function (photoId) {
        return __awaiter(this, void 0, Promise, function () {
            var photo;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.unsplashWrapper(this.unsplash.photos.get({ photoId: photoId }))];
                    case 1:
                        photo = _a.sent();
                        return [2, parsePhoto(photo)];
                }
            });
        });
    };
    UnsplashImageService.prototype.searchPhotos = function (_a) {
        return __awaiter(this, arguments, Promise, function (_b) {
            var photos;
            var query = _b.query, _c = _b.page, page = _c === void 0 ? 0 : _c, _d = _b.perPage, perPage = _d === void 0 ? 10 : _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        page = page + 1;
                        return [4, this.unsplashWrapper(this.unsplash.search.getPhotos({ query: query, page: page, perPage: perPage }))];
                    case 1:
                        photos = _e.sent();
                        return [2, photos.map(parsePhoto)];
                }
            });
        });
    };
    UnsplashImageService.prototype.reportDownload = function (photo) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!photo.downloadLocation) return [3, 2];
                        return [4, this.getPhoto(photo.id)];
                    case 1:
                        photo = _a.sent();
                        _a.label = 2;
                    case 2: return [4, this.unsplashWrapper(this.unsplash.photos.trackDownload({ downloadLocation: photo.downloadLocation }))];
                    case 3: return [2, _a.sent()];
                }
            });
        });
    };
    UnsplashImageService.prototype.completeUrl = function (inputData) {
        return __awaiter(this, void 0, void 0, function () {
            var mediaSource, data, _a, photo, result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        mediaSource = inputData.mediaSource, data = inputData.data;
                        if (data.url) {
                            return [2, Promise.resolve()];
                        }
                        _a = mediaSource;
                        switch (_a) {
                            case Types_1.MediaSource.Unsplash: return [3, 1];
                            case Types_1.MediaSource.UploadedImage: return [3, 3];
                        }
                        return [3, 5];
                    case 1: return [4, this.getPhoto(data.id)];
                    case 2:
                        photo = _b.sent();
                        data.url = photo.urls.regular;
                        return [2];
                    case 3: return [4, this.getUpload(data.id)];
                    case 4:
                        result = _b.sent();
                        data.url = result.url;
                        return [2];
                    case 5:
                        this.$log.error("User is unable to upload this type ".concat(mediaSource));
                        return [2, Promise.resolve()];
                }
            });
        });
    };
    UnsplashImageService.prototype.getCollectionOrRandomise = function (collectionId) {
        return __awaiter(this, void 0, Promise, function () {
            var upperLimit, randomPageNumber, collections, randomIndex;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (collectionId) {
                            return [2, this.getCollection(collectionId)];
                        }
                        upperLimit = 20;
                        randomPageNumber = Math.trunc(Math.random() * upperLimit) + 1;
                        return [4, this.listCollections({ page: randomPageNumber, perPage: 10 })];
                    case 1:
                        collections = _a.sent();
                        randomIndex = Math.trunc(Math.random() * collections.length);
                        return [2, collections[randomIndex]];
                }
            });
        });
    };
    UnsplashImageService.prototype.getUpload = function (id) {
        return __awaiter(this, void 0, Promise, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.uploadsService.getImage(id)];
                    case 1:
                        result = _a.sent();
                        return [2, {
                                id: result.id,
                                url: result.url,
                                name: result.name,
                            }];
                }
            });
        });
    };
    UnsplashImageService.prototype.unsplashWrapper = function (unsplashPromise) {
        return __awaiter(this, void 0, void 0, function () {
            var response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4, unsplashPromise];
                    case 1:
                        response = _a.sent();
                        if (response.errors) {
                            this.$log.error("Error received from Unsplash API: ".concat(response.errors));
                            return [2, Promise.reject(response.errors)];
                        }
                        return [2, response.response.results || response.response];
                    case 2:
                        error_1 = _a.sent();
                        this.$log.error("Unknown Unsplash library error: ".concat(error_1));
                        return [2, Promise.reject(error_1)];
                    case 3: return [2];
                }
            });
        });
    };
    return UnsplashImageService;
}());
exports.default = UnsplashImageService;
var parseCollection = function (jsonCollection) {
    var _a, _b, _c, _d;
    return ({
        collectionId: jsonCollection.id || "",
        thumbnailUrl: ((_b = (_a = jsonCollection.cover_photo) === null || _a === void 0 ? void 0 : _a.urls) === null || _b === void 0 ? void 0 : _b.small) || "",
        coverId: ((_c = jsonCollection.cover_photo) === null || _c === void 0 ? void 0 : _c.id) || "",
        color: ((_d = jsonCollection.cover_photo) === null || _d === void 0 ? void 0 : _d.color) || "",
        title: jsonCollection.title || "",
        count: jsonCollection.total_photos || 0,
    });
};
var parsePhoto = function (photo) {
    var _a, _b, _c, _d, _e, _f, _g;
    return ({
        id: photo.id || "",
        color: photo.color || "",
        thumbnailUrl: ((_a = photo.urls) === null || _a === void 0 ? void 0 : _a.small) || "",
        urls: photo.urls || [],
        userName: ((_b = photo.user) === null || _b === void 0 ? void 0 : _b.name) || "",
        userPortfolio: ((_c = photo.user) === null || _c === void 0 ? void 0 : _c.portfolio_url) || null,
        unsplashProfile: ((_e = (_d = photo.user) === null || _d === void 0 ? void 0 : _d.links) === null || _e === void 0 ? void 0 : _e.html) || "",
        downloadUrl: ((_f = photo.urls) === null || _f === void 0 ? void 0 : _f.full) || "",
        downloadLocation: ((_g = photo.links) === null || _g === void 0 ? void 0 : _g.download_location) || null,
        altDescription: photo.alt_description || null,
    });
};
function emptyCollectionsFilter(unsplashCollection) {
    var _a;
    if (((_a = unsplashCollection.user) === null || _a === void 0 ? void 0 : _a.username) === "unsplashplus")
        return false;
    if (["R4lw_ARZpzo", "61Yc-9nCSa0"].includes(unsplashCollection.id))
        return false;
    return true;
}
