"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionsDropdown = void 0;
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var kaleidoscope_2 = require("@qwilr/kaleidoscope");
var Types_1 = require("@CommonFrontendBackend/Permissions/Types");
var Util_1 = require("@CommonFrontendBackend/Permissions/Util");
var kaleidoscope_3 = require("@qwilr/kaleidoscope");
var RemoveOptionItem_1 = require("Common/Permissions/PermissionsDropdown/RemoveOptionItem");
var permissionToText = (_a = {},
    _a[Types_1.Action.View] = "Can View",
    _a[Types_1.Action.Edit] = "Can Edit",
    _a[Types_1.Action.All] = "Full Access",
    _a);
var PermissionsDropdown = (function (_super) {
    __extends(PermissionsDropdown, _super);
    function PermissionsDropdown() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.permissionDropdownRef = react_1.default.createRef();
        _this.MaybeTooltip = function (_a) {
            var _b;
            var showTooltip = _a.showTooltip, children = _a.children, permission = _a.permission;
            if (showTooltip) {
                return (react_1.default.createElement(kaleidoscope_3.Tooltip, { wrapTarget: true, content: "".concat(getFriendlyName(permission), " is provided by the \"").concat((_b = _this.props.inheritedPermission) === null || _b === void 0 ? void 0 : _b.folderName, "\" folder"), size: kaleidoscope_3.TooltipSize.Large },
                    react_1.default.createElement(react_1.Fragment, null, children)));
            }
            return children;
        };
        _this.handleSelection = function (userId, permission) { return function () {
            return _this.props.handlePermissionChange(userId, permission);
        }; };
        _this.handleKeyDown = function (event) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (event.key === "Escape" && this.permissionDropdownRef.current.showingMenu) {
                    this.permissionDropdownRef.current.hideDropdown();
                    event.stopPropagation();
                }
                return [2];
            });
        }); };
        return _this;
    }
    PermissionsDropdown.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.scrollTop !== prevProps.scrollTop) {
            this.permissionDropdownRef.current.hideDropdown();
        }
    };
    PermissionsDropdown.prototype.render = function () {
        var _this = this;
        var _a = this.props, entityId = _a.entityId, directPermission = _a.directPermission, inheritedPermission = _a.inheritedPermission, scrollTop = _a.scrollTop, allowRemove = _a.allowRemove;
        var shouldShowInheritedPermission = (0, Util_1.isGreater)(inheritedPermission === null || inheritedPermission === void 0 ? void 0 : inheritedPermission.permission, directPermission);
        var effectiveAction = (0, Util_1.pickGreaterAction)(directPermission, inheritedPermission === null || inheritedPermission === void 0 ? void 0 : inheritedPermission.permission);
        return (react_1.default.createElement("div", { className: "permissions-dropdown", onKeyDown: this.handleKeyDown, tabIndex: 0 },
            react_1.default.createElement(kaleidoscope_1.Dropdown, { name: getFriendlyName(effectiveAction), ref: this.permissionDropdownRef },
                react_1.default.createElement("div", { className: "permissions-wrapper", style: { top: -scrollTop } },
                    react_1.default.createElement(this.MaybeTooltip, { showTooltip: shouldShowInheritedPermission, permission: effectiveAction },
                        this.props.availablePermissions.map(function (action) { return (react_1.default.createElement(kaleidoscope_2.DropdownItem, { key: action, name: getFriendlyName(action), onSelect: _this.handleSelection(_this.props.entityId, action), selected: effectiveAction === action, disable: shouldShowInheritedPermission })); }),
                        allowRemove && (react_1.default.createElement(RemoveOptionItem_1.RemoveOption, { disable: shouldShowInheritedPermission, handleOnClick: this.handleSelection(entityId, Types_1.Action.None) })))))));
    };
    PermissionsDropdown.defaultProps = {
        availablePermissions: [Types_1.Action.Edit, Types_1.Action.View],
        allowRemove: true,
    };
    return PermissionsDropdown;
}(react_1.default.Component));
exports.PermissionsDropdown = PermissionsDropdown;
function getFriendlyName(permissions) {
    return permissionToText[permissions] || "";
}
