"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutomationActivityLog = void 0;
var react_1 = __importStar(require("react"));
var luxon_1 = require("luxon");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var UseAutomationExecutionHistory_1 = require("./UseAutomationExecutionHistory");
var styles = __importStar(require("./AutomationActivityLog.css"));
var AutomationActivityLog = function () {
    var automationExecutionHistory = (0, UseAutomationExecutionHistory_1.useAutomationExecutionHistory)();
    var incrementPage = function () { return __awaiter(void 0, void 0, Promise, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4, automationExecutionHistory.setSize(function (prevSize) { return prevSize + 1; })];
                case 1:
                    _a.sent();
                    return [2];
            }
        });
    }); };
    var automationExecutionHistoryCombined = automationExecutionHistory.data
        ? automationExecutionHistory.data.reduce(function (all, automationExecutionHistory) {
            return __spreadArray(__spreadArray([], __read(all), false), __read((automationExecutionHistory.data || [])), false);
        }, [])
        : [];
    return (react_1.default.createElement("div", null, automationExecutionHistory.isLoading ? (react_1.default.createElement("div", { className: "space-inset-l" },
        react_1.default.createElement(kaleidoscope_1.Spinner, { primary: true }))) : automationExecutionHistoryCombined.length === 0 ? (react_1.default.createElement(kaleidoscope_1.EmptyState, { title: "No automations have run", subtitle: "Check back here for all successful and failed automation results once an automation has run.", isDashed: true })) : (react_1.default.createElement("div", null,
        react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", gap: "s", padding: "m" },
            react_1.default.createElement("div", { className: styles.col1 },
                react_1.default.createElement(kaleidoscope_1.Text, { strong: true }, "Automation name")),
            react_1.default.createElement("div", { className: styles.col2 },
                react_1.default.createElement(kaleidoscope_1.Text, { strong: true }, "Page name")),
            react_1.default.createElement("div", { className: styles.col3 },
                react_1.default.createElement(kaleidoscope_1.Text, { strong: true }, "Assigned to template")),
            react_1.default.createElement("div", { className: styles.col4 },
                react_1.default.createElement(kaleidoscope_1.Text, { strong: true }, "Date")),
            react_1.default.createElement("div", { className: styles.col5 },
                react_1.default.createElement(kaleidoscope_1.Text, { strong: true }, "Status"))),
        react_1.default.createElement("div", null, automationExecutionHistoryCombined.map(function (_a) {
            var item = __rest(_a, []);
            return (react_1.default.createElement("div", { className: "space-stack-s", key: item.id },
                react_1.default.createElement(LogItem, __assign({}, item))));
        })),
        react_1.default.createElement(kaleidoscope_1.Stack, { paddingTop: "l", align: "center" },
            react_1.default.createElement("div", null, automationExecutionHistory.data &&
                automationExecutionHistory.data[automationExecutionHistory.data.length - 1].pagination.nextCursor && (react_1.default.createElement(kaleidoscope_1.Button, { type: kaleidoscope_1.ButtonType.Secondary, onClick: incrementPage, buttonState: automationExecutionHistory.isValidating ? kaleidoscope_1.ButtonState.Waiting : kaleidoscope_1.ButtonState.Idle }, "Load more activities"))))))));
};
exports.AutomationActivityLog = AutomationActivityLog;
var LogItem = function (props) {
    var _a;
    var outcome = props.outcome, automation = props.automation, trigger = props.trigger, association = props.association, startTime = props.startTime;
    var success = outcome === "success";
    var _b = __read((0, react_1.useState)(false), 2), expanded = _b[0], setExpanded = _b[1];
    var toggleExpanded = function () { return setExpanded(!expanded); };
    return (react_1.default.createElement(kaleidoscope_1.Card, { className: "space-inset-m" },
        react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "center", gap: "s" },
            react_1.default.createElement("div", { className: styles.col1 },
                react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "center", gap: "s" },
                    react_1.default.createElement(kaleidoscope_1.IconButton, { icon: expanded ? react_1.default.createElement(icons_1.ChevronDown, null) : react_1.default.createElement(icons_1.ChevronRight, null), onClick: toggleExpanded }),
                    react_1.default.createElement(kaleidoscope_1.Text, null, automation.name))),
            react_1.default.createElement("div", { className: styles.col2 },
                react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "center", gap: "s" },
                    react_1.default.createElement(icons_1.Page, { className: styles.icon }),
                    react_1.default.createElement(kaleidoscope_1.Text, null, trigger.page.name))),
            react_1.default.createElement("div", { className: styles.col3 },
                react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "center", gap: "s" },
                    react_1.default.createElement(icons_1.Template, { className: styles.icon }),
                    react_1.default.createElement(kaleidoscope_1.Text, null, ((_a = association === null || association === void 0 ? void 0 : association.template) === null || _a === void 0 ? void 0 : _a.name) || "Template not found"))),
            react_1.default.createElement("div", { className: styles.col4 },
                react_1.default.createElement(kaleidoscope_1.Text, null, luxon_1.DateTime.fromISO(startTime).toLocaleString(luxon_1.DateTime.DATETIME_MED))),
            react_1.default.createElement("div", { className: styles.col5 },
                react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "center", gap: "s" },
                    react_1.default.createElement("div", { className: styles.statusIcon({ outcome: outcome }) }, success ? react_1.default.createElement(icons_1.Tick, null) : react_1.default.createElement(icons_1.Warning, null)),
                    react_1.default.createElement(kaleidoscope_1.Text, { className: styles.statusText({ outcome: outcome }) }, toExecutionOutcome(outcome))))),
        react_1.default.createElement(kaleidoscope_1.Expandable, { expanded: expanded }, function (_a) {
            var expandableElementRef = _a.expandableElementRef;
            return (react_1.default.createElement("div", { className: styles.expandableContent, ref: expandableElementRef },
                react_1.default.createElement(LogItemContent, __assign({}, props))));
        })));
};
var LogItemContent = function (props) { return (react_1.default.createElement(react_1.default.Fragment, null,
    props.outcome === "failure" && (react_1.default.createElement(kaleidoscope_1.Card, { className: styles.executionErrorOverview },
        react_1.default.createElement(kaleidoscope_1.Text, { className: styles.executionErrorText }, props.message))),
    react_1.default.createElement(kaleidoscope_1.Card, { className: styles.executionOverview },
        react_1.default.createElement("div", { className: "space-stack-xs" },
            react_1.default.createElement(kaleidoscope_1.Text, null,
                react_1.default.createElement(kaleidoscope_1.Text, { strong: true }, "Trigger: "),
                props.trigger.label)),
        props.actions.map(function (action, index) {
            var _a;
            return (react_1.default.createElement("div", { className: index < props.actions.length - 1 ? "space-stack-xs" : "", key: "".concat(props.executionId, "-action-").concat(index) },
                react_1.default.createElement(kaleidoscope_1.Text, { className: styles.actionText({ outcome: action.outcome }) },
                    react_1.default.createElement(kaleidoscope_1.Text, { className: styles.actionText({ outcome: action.outcome }), strong: true },
                        "Action ",
                        index + 1,
                        ":",
                        " "),
                    action.label,
                    " (",
                    toActionOutcome(action.outcome),
                    "). ", (_a = action.message) !== null && _a !== void 0 ? _a : "")));
        })))); };
var toActionOutcome = function (outcome) {
    var _a;
    var _b;
    return ((_b = (_a = {},
        _a["partial-success"] = "partially successful",
        _a["not-executed"] = "skipped",
        _a.success = "success",
        _a.failure = "failed",
        _a)[outcome]) !== null && _b !== void 0 ? _b : "");
};
var toExecutionOutcome = function (outcome) {
    var _a;
    return ((_a = {
        review: "Review",
        success: "Success",
        failure: "Failed",
    }[outcome]) !== null && _a !== void 0 ? _a : "");
};
