"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var EditorTypes_1 = require("./../EditorTypes");
var BehaviourBuilder_1 = require("./BehaviourBuilder");
var CommonActions = __importStar(require("./CommonActions"));
var slate_1 = require("slate");
exports.default = (function (di) {
    return {
        onShiftEnter: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("commonOnShiftEnter")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Enter)
            .withModifier(BehaviourBuilder_1.KeyModifier.Shift)
            .inBlockType(["blockQuote", "paragraph", "listItem", "heading1", "heading2"])
            .doAction(function (event, editor) {
            event.preventDefault();
            CommonActions.singleLineBreak(editor);
            return true;
        }),
        onEnter: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("commonOnEnter")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Enter)
            .inBlockType(["blockQuote", "heading1", "heading2"])
            .atEndOfBlock()
            .doAction(function (event, editor) {
            event.preventDefault();
            CommonActions.newParagraphBelow(editor);
            return true;
        }),
        onBackspaceEmptySingleton: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("commonBackspaceEmptySingleton")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Backspace)
            .inBlockType(["blockQuote", "heading1", "heading2"])
            .inFirstBlock()
            .inLastBlock()
            .inEmptyBlock()
            .doAction(function (event, editor) {
            event.preventDefault();
            slate_1.Transforms.setNodes(editor, { type: "paragraph" });
            return true;
        }),
        onBackspaceWithRangedSelection: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("backspaceWithRangedSelection")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Backspace)
            .selectionIsExpanded()
            .doAction(function (event, editor) {
            event.preventDefault();
            CommonActions.deleteSelectedRange(editor, "backward");
            return true;
        }),
        onDeleteWithRangedSelection: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("deleteWithRangedSelection")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Delete)
            .selectionIsExpanded()
            .doAction(function (event, editor) {
            event.preventDefault();
            CommonActions.deleteSelectedRange(editor, "forward");
            return true;
        }),
    };
});
