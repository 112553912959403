"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Telemetry_1 = require("Telemetry");
var event_tracker_types_1 = require("@qwilr/event-tracker-types");
var Types_1 = require("@CommonFrontendBackend/Analytics/Types");
var brandSetup = require("SettingsAngular/BrandSetup/BrandSetup.js");
var State = (function () {
    function State($state, projectService, integrationFocusCreator, invoke) {
        var _this = this;
        this.goToProjectAction = function (action, project, source, pageViewId) {
            switch (source || _this.$state.current.name) {
                case "dashboard": {
                    _this.silentlyGo("dashboardProjectAction", {
                        projectId: project._id,
                        action: action,
                        pageViewId: pageViewId,
                    });
                    break;
                }
                case "project": {
                    _this.silentlyGo("projectAction", {
                        id: project._id,
                        action: action,
                        pageViewId: pageViewId,
                    });
                    break;
                }
            }
            _this.performProjectAction(action, project, source, pageViewId);
        };
        this.$state = $state;
        this.projectService = projectService;
        this.integrationFocusCreator = integrationFocusCreator;
        this.invoke = invoke;
        this.noNotifyOptions = {
            notify: false,
            location: true,
            inherit: true,
        };
    }
    State.prototype.openBrandSetup = function (initialBrandSetupSection, allBrandSetupSections) {
        var _this = this;
        var previousSection = this.$state.params.section;
        this.invoke(brandSetup, null, {
            options: {
                gotoState: initialBrandSetupSection,
                onClose: function () {
                    var section;
                    if (allBrandSetupSections.includes(previousSection)) {
                        section = "all";
                    }
                    else {
                        section = previousSection;
                    }
                    _this.silentlyGo("settings", {
                        section: section,
                    });
                },
                onSectionOpen: function (section) {
                    _this.silentlyGo("settings", {
                        section: section,
                    });
                },
            },
        });
        this.silentlyGo("settings", {
            section: initialBrandSetupSection,
        });
    };
    State.prototype.goToDashboard = function () {
        this.$state.go("dashboard", { nav: "pages" });
    };
    State.prototype.goToIntegrationSettings = function (integration) {
        var _this = this;
        this.silentlyGo("settingsWithSubsection", {
            section: "integrations",
            subsection: integration.urlSafeName,
        });
        var focus = this.integrationFocusCreator.create(integration, function () {
            _this.silentlyGo("settings", {
                section: "integrations",
            });
        });
        focus.show();
    };
    State.prototype.silentlyGo = function (route, params) {
        if (route === "dashboard") {
            this.$state.go(route, {}, this.noNotifyOptions);
        }
        else {
            params = params || {};
            this.$state.go(route, params, this.noNotifyOptions);
        }
    };
    State.prototype.go = function (route, params) {
        this.$state.go(route, params);
    };
    State.prototype.performProjectAction = function (action, project, source, pageViewId) {
        if (!project) {
            this.$state.go("dashboard", { nav: "pages" });
        }
        var overlayOptions = this.getProjectOverlayOptions(project, source);
        switch (action) {
            case "share": {
                this.projectService.share(project, getOrigin(source), overlayOptions);
                break;
            }
            case "pdf": {
                this.projectService.exportToPDF(project, overlayOptions);
                break;
            }
            case "analytics": {
                var hash = window.location.hash;
                var params = new URLSearchParams(hash.substring(hash.indexOf("?") + 1));
                var origin = params.get("origin");
                var eventOrigin = void 0;
                if (origin == Types_1.EmailTelemetryOrigin.Engagement || origin == Types_1.EmailTelemetryOrigin.Notification) {
                    eventOrigin = event_tracker_types_1.EventOrigin.Email;
                }
                else {
                    eventOrigin = source === "dashboard" ? event_tracker_types_1.EventOrigin.QwilrDashboard : event_tracker_types_1.EventOrigin.QwilrEditor;
                }
                this.projectService.analyse(project, overlayOptions, eventOrigin, pageViewId);
                break;
            }
            default: {
                throw new Error("Project action not defined");
            }
        }
    };
    State.prototype.getProjectOverlayOptions = function (project, source) {
        var _this = this;
        var previousStateName = this.$state.current.name;
        return {
            onClose: function () {
                if (["dashboardProjectAction", "projectAction"].includes(previousStateName)) {
                    return _this.$state.go(source, {
                        id: project._id,
                    });
                }
                else {
                    return _this.silentlyGo(source || previousStateName, {
                        id: project._id,
                    });
                }
            },
        };
    };
    return State;
}());
exports.default = State;
var getOrigin = function (source, origin) {
    if (source === "project") {
        return Telemetry_1.ProjectShareViewedOrigin.ProjectNavBar;
    }
    else if (source === "dashboard") {
        return origin || source;
    }
    else {
        return null;
    }
};
