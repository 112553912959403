import "Application/Project/TailoredPageTour/TailoredPageTourPrompt.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/TailoredPageTour/TailoredPageTourPrompt.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71WwU7jMBC98xUjIUQjkdZuKS3pZfe4X4Gc2E5NHTvYbims+PeVkzR10qa7sBInyMw8z5s343F/bNgbN6RgFnLNX8rNC/y+AuBGF9U/ALokmXBvCeBV9W0zIlkCyH99XAE43Q9EncBpHfhxNa4ToCq81FY4oVUCXOwZ9TFCWeYa9HssFGX7BDCaz1ZHMB4C9wAAzhB1iGqYwY6YURzTrSHeHhdPTziVi+dnjF3UOBmxQuWxdURRYugx4iW6q8/k2hSfOCnVW5Wxo99EQTnTqpyucG2SpFZwhMbLEDPrYvBZDA4R91/IMq8wKck2udFbReNMS22SprbqI7Zbw0lQWx6tuhhRkJwlsDVydDv5aS1zdvLL2+zEEKFS/Rqn+dju8ts+0op3lkCmd8x4T0koFSo/pLe+FJXH8iirjIK42OmyH7vfB9Gqjm7HyDBJnNgxb30V1K0TmC1RuQ8EeawEaZyVhGsm8vVhYI9nkdRquXXVWQGP+pSHKrFk3HXti2hoZpH9z7ENaiB1U7WhzMSnJJYRWC0FrXmUxDDlVkeAqcv9BOKk/Db+mnNOOA64pd/MLdXO6WKQHuEzvgzoZSG94bq+kOs85IIC15xxyh8DcvSbyV1S20uH+SJgx07WT+viJ7usdeW9TX9yrZb+egI0XJady7quHzHpHwQECMjWaR/MtXLNbrnvIkSACGk8V/Yzy680oiDmrb1pKLxomwpFhS0leUtAKClUxXzHjBMZkTGRIlcJOLZ3TQeO+iaAy30tZyg6oWJr/QOHboJUcmBR9zrXbtBZp+ziH9b8uUoHWLULsmnOYUV2tVbDXde9WRlPA2fZG4nj1m5DTLilMUJdGq3hwlhlRGajObqBGOao3Af7+pzn9LHzghAZ5/4vU26UCZNJBpnUllk/fJQBcZAx5Zi5A5OnI4xngNECpvc4AnRzxjibnVphAmg8v+x7eBjwTf/m9P281GeiREFqCQ+/G6cWuDavxFBbayx8iTHbMeVsAkr7K/DxBwpUYY9xCgAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var backdrop = 'gofqpk0';
export var overlay = _7a468({defaultClassName:'gofqpk1',variantClassNames:{status:{entering:'gofqpk2',entered:'gofqpk3',exiting:'gofqpk4'}},defaultVariants:{},compoundVariants:[]});
export var card = 'gofqpk5';
export var caretTop = 'var(--gofqpk6)';
export var caretLeft = 'var(--gofqpk7)';
export var caretSize = 'var(--gofqpk8)';
export var caret = _7a468({defaultClassName:'gofqpk9',variantClassNames:{position:{top:'gofqpka',bottom:'gofqpkb',left:'gofqpkc',right:'gofqpkd'},status:{entering:'gofqpke',entered:'gofqpkf'}},defaultVariants:{},compoundVariants:[]});
export var closeButton = 'gofqpkg';
export var emoji = 'gofqpkh';
export var content = 'gofqpki';
export var text = 'gofqpkj';
export var addIcon = 'gofqpkk';
export var control = 'gofqpkl';
export var dot = _7a468({defaultClassName:'gofqpkm',variantClassNames:{active:{true:'gofqpkn',false:'gofqpko'}},defaultVariants:{},compoundVariants:[]});
export var buttonWrapper = 'gofqpkp';
export var ripple = 'gofqpkr';