"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.asTableNode = exports.asSideBySide = exports.as = exports.getNextSiblingBlock = exports.getPreviousSiblingBlock = exports.isInsideAccordionBody = exports.isInsideAccordionHeading = exports.isElementNode = exports.isInsideAgreement = exports.isInsideImage = exports.isInsideContentLoop = exports.isInsideAccordion = exports.isInsideSideBySide = exports.isListNodeEntry = exports.isSelected = exports.getLastText = exports.getOffsetFrom = exports.moveToStartOfNextText = exports.moveToEndOfPreviousBlock = exports.moveToEndOfPreviousText = exports.getStartBlock = exports.isBlockElement = exports.replaceNodes = exports.splitTextNode = exports.isAtStartOfNode = exports.isAtEndOfNode = exports.isInline = exports.isLeafInline = exports.checkSelfAndAncestors = exports.checkAncestors = exports.hasAncestorOf = exports.getNext = exports.getPrevious = exports.getClosestInline = exports.getClosestBlock = exports.getSelectedBlocks = exports.createConfiguredEditor = void 0;
var Models_1 = require("ContentTokens/Models");
var SlateUtils_1 = require("Project/QwilrEditor/Behaviours/SlateUtils");
var slate_1 = require("slate");
var Normalize_1 = require("./Schemas/Normalize");
var DefaultSchema_1 = require("./Schemas/DefaultSchema");
var SchemaEditor_1 = require("./Schemas/SchemaEditor");
var EditorWidgetRegistry_1 = require("Project/QwilrEditor/WidgetRegistry/EditorWidgetRegistry");
var lodash_1 = require("lodash");
var createConfiguredEditor = function (children, selection, schema) {
    if (children === void 0) { children = []; }
    var currentSchema = schema || DefaultSchema_1.schema;
    var editor = (0, SchemaEditor_1.withSchema)((0, slate_1.createEditor)(), currentSchema);
    editor.children = children;
    if (selection) {
        editor.selection = selection;
    }
    editor.isInline = function (element) {
        return !!(0, exports.isInline)(element);
    };
    var isVoid = editor.isVoid;
    editor.isVoid = function (_element) {
        var _a, _b, _c, _d, _e;
        var element = _element;
        if (((_a = (0, EditorWidgetRegistry_1.getGenericConfigByType)(element.type, true)) === null || _a === void 0 ? void 0 : _a.nodeType) === "void") {
            return true;
        }
        if ((_c = (_b = currentSchema.blocks[element.type]) === null || _b === void 0 ? void 0 : _b.isVoid) !== null && _c !== void 0 ? _c : false) {
            return true;
        }
        if ((_e = (_d = currentSchema.inlines[element.type]) === null || _d === void 0 ? void 0 : _d.isVoid) !== null && _e !== void 0 ? _e : false) {
            return true;
        }
        return isVoid(element);
    };
    (0, Normalize_1.addNormalization)(editor, currentSchema);
    return editor;
};
exports.createConfiguredEditor = createConfiguredEditor;
var getSelectedBlocks = function (editor) {
    if (!editor.selection) {
        return [];
    }
    var startBlockEntry = (0, exports.checkAncestors)(editor, slate_1.Range.start(editor.selection).path, exports.isBlockElement);
    var endBlockEntry = (0, exports.checkAncestors)(editor, slate_1.Range.end(editor.selection).path, exports.isBlockElement);
    var indexOfLastPathParamInStartBlock = startBlockEntry[1].length - 1;
    var basePath = startBlockEntry[1].slice(0, indexOfLastPathParamInStartBlock);
    var positionOfStartBlockInContainer = startBlockEntry[1][indexOfLastPathParamInStartBlock];
    var positionOfEndBlockInContainer = endBlockEntry[1][indexOfLastPathParamInStartBlock];
    if (!slate_1.Path.equals(startBlockEntry[1], endBlockEntry[1]) && !slate_1.Path.isSibling(startBlockEntry[1], endBlockEntry[1])) {
        return Array.from(slate_1.Editor.nodes(editor, {
            at: editor.selection,
            match: exports.isBlockElement,
        }));
    }
    var selectedBlocks = [];
    for (var i = positionOfStartBlockInContainer; i <= positionOfEndBlockInContainer; i++) {
        var path = __spreadArray(__spreadArray([], __read(basePath), false), [i], false);
        if (slate_1.Node.has(editor, path)) {
            var node = slate_1.Node.get(editor, path);
            selectedBlocks.push([node, path]);
        }
        else {
            console.log("getSelectedBlocks: Tried to get a node that doesn't exist");
        }
    }
    return selectedBlocks;
};
exports.getSelectedBlocks = getSelectedBlocks;
var getClosestBlock = function (rootNode, path) {
    return (0, exports.checkAncestors)(rootNode, path, exports.isBlockElement);
};
exports.getClosestBlock = getClosestBlock;
var getClosestInline = function (rootNode, path) {
    return (0, exports.checkAncestors)(rootNode, path, exports.isInline);
};
exports.getClosestInline = getClosestInline;
var getPrevious = function (editor, path, checker) {
    var _a = __read(slate_1.Editor.first(editor, path), 2), from = _a[1];
    var _b = __read(slate_1.Editor.first(editor, []), 2), to = _b[1];
    var _c = __read(slate_1.Editor.nodes(editor, {
        reverse: true,
        at: [from, to],
        match: checker,
        mode: "lowest",
        voids: true,
    }), 2), closestPrevious = _c[0], nextPrevious = _c[1];
    if (slate_1.Path.compare(closestPrevious[1], path) === 0) {
        if (!!nextPrevious) {
            return nextPrevious;
        }
    }
    else {
        return closestPrevious;
    }
    return null;
};
exports.getPrevious = getPrevious;
var getNext = function (editor, path, checker) {
    var _a = __read(slate_1.Editor.last(editor, path), 2), from = _a[1];
    var _b = __read(slate_1.Editor.last(editor, []), 2), to = _b[1];
    var _c = __read(slate_1.Editor.nodes(editor, {
        reverse: false,
        at: [from, to],
        match: checker,
        mode: "lowest",
        voids: true,
    }), 2), closestNext = _c[0], followingNext = _c[1];
    if (slate_1.Path.compare(closestNext[1], path) === 0) {
        if (!!followingNext) {
            return followingNext;
        }
    }
    else {
        return closestNext;
    }
    return null;
};
exports.getNext = getNext;
var hasAncestorOf = function (rootNode, path, type) {
    var typeArray = Array.isArray(type) ? type : [type];
    var foundAncestor = (0, exports.checkAncestors)(rootNode, path, function (curr) {
        return slate_1.Element.isElement(curr) && typeArray.includes(curr.type);
    });
    return !!foundAncestor;
};
exports.hasAncestorOf = hasAncestorOf;
var checkAncestors = function (rootNode, path, checker) {
    var _a;
    var ancestorEntries = Array.from(slate_1.Node.ancestors(rootNode, path, { reverse: true }));
    return (_a = ancestorEntries.find(function (nodeEntry) { return checker(nodeEntry[0]); })) !== null && _a !== void 0 ? _a : null;
};
exports.checkAncestors = checkAncestors;
var checkSelfAndAncestors = function (rootNode, path, checker) {
    var descendantNode = slate_1.Node.get(rootNode, path);
    var descendant = [descendantNode, path];
    return checker(descendantNode) ? descendant : (0, exports.checkAncestors)(rootNode, path, checker);
};
exports.checkSelfAndAncestors = checkSelfAndAncestors;
var isLeafInline = function (node) {
    return (0, exports.isInline)(node) && node.children.every(function (node) { return slate_1.Text.isText(node); });
};
exports.isLeafInline = isLeafInline;
var isInline = function (node) {
    return (node.type === Models_1.InlineType.Link ||
        node.type === Models_1.InlineType.LineBreak ||
        node.type === Models_1.InlineType.Variable ||
        node.type === Models_1.InlineType.TokenWidget ||
        node.type === Models_1.InlineType.Any);
};
exports.isInline = isInline;
var isAtEndOfNode = function (point, node) {
    var texts = Array.from(slate_1.Node.texts(node[0]));
    var lastText = texts[texts.length - 1];
    var pathToLastText = node[1].concat(lastText[1]);
    return slate_1.Path.equals(pathToLastText, point.path) && point.offset === lastText[0].text.length;
};
exports.isAtEndOfNode = isAtEndOfNode;
var isAtStartOfNode = function (point, node) {
    if (point.offset !== 0) {
        return false;
    }
    var texts = Array.from(slate_1.Node.texts(node[0]));
    var firstText = texts[0];
    var pathToFirstText = node[1].concat(firstText[1]);
    return slate_1.Path.equals(pathToFirstText, point.path);
};
exports.isAtStartOfNode = isAtStartOfNode;
var splitTextNode = function (editor, point) {
    var nodeToSplit = slate_1.Editor.node(editor, point);
    slate_1.Transforms.transform(editor, {
        type: "split_node",
        path: point.path,
        position: point.offset,
        properties: __assign({}, (0, lodash_1.omit)(nodeToSplit[0], "children", "text")),
    });
};
exports.splitTextNode = splitTextNode;
var replaceNodes = function (editor, nodes, options) {
    slate_1.Editor.withoutNormalizing(editor, function () {
        slate_1.Transforms.removeNodes(editor, { at: options.at });
        slate_1.Transforms.insertNodes(editor, nodes, { at: options.at });
    });
};
exports.replaceNodes = replaceNodes;
var isBlockElement = function (node) {
    return slate_1.Element.isElement(node) && !(0, exports.isInline)(node);
};
exports.isBlockElement = isBlockElement;
var getStartBlock = function (editor) {
    if (!editor.selection) {
        return null;
    }
    var startPoint = slate_1.Range.start(editor.selection);
    return (0, exports.getClosestBlock)(editor, startPoint.path);
};
exports.getStartBlock = getStartBlock;
var moveToEndOfPreviousText = function (editor) {
    if (!editor.selection) {
        return;
    }
    var previousText = (0, exports.getPrevious)(editor, slate_1.Range.start(editor.selection).path, slate_1.Text.isText);
    if (!previousText) {
        return;
    }
    var offset = previousText[0].text.length;
    slate_1.Transforms.select(editor, { path: previousText[1], offset: offset });
};
exports.moveToEndOfPreviousText = moveToEndOfPreviousText;
var moveToEndOfPreviousBlock = function (editor) {
    if (!editor.selection) {
        return;
    }
    var previousBlock = (0, exports.getPrevious)(editor, slate_1.Range.start(editor.selection).path, exports.isBlockElement);
    if (!previousBlock) {
        return;
    }
    var lastText = (0, exports.getLastText)(previousBlock);
    slate_1.Transforms.select(editor, { path: lastText[1], offset: lastText[0].text.length });
};
exports.moveToEndOfPreviousBlock = moveToEndOfPreviousBlock;
var moveToStartOfNextText = function (editor) {
    if (!editor.selection) {
        slate_1.Transforms.select(editor, slate_1.Editor.start(editor, [0]));
        return;
    }
    var nextText = (0, exports.getNext)(editor, slate_1.Range.start(editor.selection).path, slate_1.Text.isText);
    if (!nextText) {
        return;
    }
    var offset = 0;
    slate_1.Transforms.select(editor, { path: nextText[1], offset: offset });
};
exports.moveToStartOfNextText = moveToStartOfNextText;
var getOffsetFrom = function (editor, parentBlockPath, path) {
    var e_1, _a;
    var nodeIterable = slate_1.Node.children(editor, parentBlockPath);
    var offset = 0;
    try {
        for (var nodeIterable_1 = __values(nodeIterable), nodeIterable_1_1 = nodeIterable_1.next(); !nodeIterable_1_1.done; nodeIterable_1_1 = nodeIterable_1.next()) {
            var node = nodeIterable_1_1.value;
            if (slate_1.Path.equals(node[1], path)) {
                break;
            }
            else if (!slate_1.Path.isParent(node[1], path)) {
                offset += slate_1.Node.string(node[0]).length;
            }
            else if (slate_1.Path.isParent(node[1], path)) {
                offset += (0, exports.getOffsetFrom)(editor, node[1], path);
                break;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (nodeIterable_1_1 && !nodeIterable_1_1.done && (_a = nodeIterable_1.return)) _a.call(nodeIterable_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return offset;
};
exports.getOffsetFrom = getOffsetFrom;
var getLastText = function (root) {
    var allNodes = Array.from(slate_1.Node.nodes(root[0]));
    var last = allNodes[allNodes.length - 1];
    if (slate_1.Text.isText(last[0])) {
        return [last[0], root[1].concat(last[1])];
    }
    else {
        throw new Error("We found a non-text element as the final node");
    }
};
exports.getLastText = getLastText;
var isSelected = function (editor, path) {
    if (!editor.selection) {
        return false;
    }
    var startPoint = slate_1.Range.start(editor.selection);
    var endPoint = slate_1.Range.end(editor.selection);
    return slate_1.Path.compare(path, startPoint.path) >= 0 && slate_1.Path.compare(path, endPoint.path) <= 0;
};
exports.isSelected = isSelected;
function isNodeEntry(nodeEntry, type) {
    var _a = __read(nodeEntry, 1), _b = _a[0], node = _b === void 0 ? {} : _b;
    return node.type === type;
}
function isListNodeEntry(nodeEntry) {
    return isNodeEntry(nodeEntry, "listItem");
}
exports.isListNodeEntry = isListNodeEntry;
var isInsideSideBySide = function (editor, range) {
    if (!range && !editor.selection) {
        return false;
    }
    return (0, SlateUtils_1.hasBlockTypeAsAncestor)({
        selectionPath: slate_1.Range.end(range !== null && range !== void 0 ? range : editor.selection).path,
        blockType: "sideBySide",
        editor: editor,
    });
};
exports.isInsideSideBySide = isInsideSideBySide;
var isInsideAccordion = function (editor, range) {
    if (!range && !editor.selection) {
        return false;
    }
    return (0, SlateUtils_1.hasBlockTypeAsAncestor)({
        selectionPath: slate_1.Range.end(range !== null && range !== void 0 ? range : editor.selection).path,
        blockType: "accordion",
        editor: editor,
    });
};
exports.isInsideAccordion = isInsideAccordion;
var isInsideContentLoop = function (editor) {
    if (!editor.selection) {
        return false;
    }
    return (0, SlateUtils_1.hasBlockTypeAsAncestor)({
        selectionPath: slate_1.Range.end(editor.selection).path,
        blockType: "contentLoop",
        editor: editor,
    });
};
exports.isInsideContentLoop = isInsideContentLoop;
var isInsideImage = function (editor) {
    if (!editor.selection) {
        return false;
    }
    return (0, exports.hasAncestorOf)(editor, slate_1.Range.end(editor.selection).path, "image");
};
exports.isInsideImage = isInsideImage;
var isInsideAgreement = function (editor) {
    return editor.schema.name === "agreement";
};
exports.isInsideAgreement = isInsideAgreement;
var isElementNode = function (e) {
    return !!e.type;
};
exports.isElementNode = isElementNode;
var isInsideAccordionHeading = function (editor) {
    if (!editor.selection) {
        return false;
    }
    return (0, SlateUtils_1.hasBlockTypeAsAncestor)({
        selectionPath: slate_1.Range.end(editor.selection).path,
        blockType: "accordionHeading",
        editor: editor,
    });
};
exports.isInsideAccordionHeading = isInsideAccordionHeading;
var isInsideAccordionBody = function (editor) {
    if (!editor.selection) {
        return false;
    }
    return (0, SlateUtils_1.hasBlockTypeAsAncestor)({
        selectionPath: slate_1.Range.end(editor.selection).path,
        blockType: "accordionBody",
        editor: editor,
    });
};
exports.isInsideAccordionBody = isInsideAccordionBody;
var getPreviousSiblingBlock = function (editor, path) {
    var currentBlockEntry = (0, exports.checkSelfAndAncestors)(editor, path, exports.isBlockElement);
    if (!currentBlockEntry) {
        return undefined;
    }
    var _a = __read(currentBlockEntry, 2), currentBlockPath = _a[1];
    var hasPreviousSibling = slate_1.Path.hasPrevious(currentBlockPath);
    if (!hasPreviousSibling) {
        return undefined;
    }
    var previousBlock = slate_1.Editor.previous(editor, { at: currentBlockPath, match: exports.isBlockElement });
    return previousBlock;
};
exports.getPreviousSiblingBlock = getPreviousSiblingBlock;
var getNextSiblingBlock = function (editor, path) {
    var currentBlockEntry = (0, exports.checkSelfAndAncestors)(editor, path, exports.isBlockElement);
    if (!currentBlockEntry) {
        return undefined;
    }
    var _a = __read(currentBlockEntry, 2), currentBlockPath = _a[1];
    var nextSiblingPath = slate_1.Path.next(currentBlockPath);
    if (slate_1.Editor.hasPath(editor, nextSiblingPath)) {
        var nextBlock = slate_1.Editor.node(editor, nextSiblingPath);
        if (!isElementEntry(nextBlock)) {
            return undefined;
        }
        return nextBlock;
    }
    return undefined;
};
exports.getNextSiblingBlock = getNextSiblingBlock;
var isElementEntry = function (node) {
    return slate_1.Element.isElement(node[0]);
};
function as(type, node) {
    if (node.type === type)
        return node;
    throw new Error("Type assertion error. Expected ".concat(type, " but was ").concat(node.type));
}
exports.as = as;
function asSideBySide(node) {
    return as("sideBySide", node);
}
exports.asSideBySide = asSideBySide;
function asTableNode(node) {
    return as("table", node);
}
exports.asTableNode = asTableNode;
