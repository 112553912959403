import "Application/SettingsAngular/Notifications/V2Notifications.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/SettingsAngular/Notifications/V2Notifications.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8WUwY7bIBCG73mKkaqVEilEgE1ie09bVX2AvkCEbeKQYrCAtI6qfffK2K7trLKXrrRHmH8Gvn8YdkdCW5lzheHPCuC3LP05A4Lx0/PqdbUboyREa96iQREnSdM+rwC4kpVG0ovaZeC8Fb44L1LpvHC077POQlZnP60bXpZSVxkM69zYUlhkeSmvLoNf3K4R6jd/hD1UGO2tUccjydXhciFEbLrEwihjM/iC8ddvL99DKV78rKy56hINQVvlfI23QJJ0C+TAtoB3FIf0mttKapQb7009nusaXkhdodZNp8nNAjIKkF60HgVDMlDi5Bdkd7VmN6+XteKplrdcu5OxdQbaaLGQsSA7KdGinDvpMmBd0wBqqccukQPu/Jxl7UPWo0s9BjzMuxjTuy6m/9m1XpMBaVpwRsmy69E6SYAkMVAWbR42ck0jAjRmQBkLqlK6RvFbFpx580ALob2w3fbl6rw83cKNhPZTaAadfAI0SRMg6R5onL5HHXfUGCiLP5w6/QRqyhhQnELyLnQnYhholHw4NB8+uDD+tmdld7OT9xqp0egGo3eSIkgGc8ZfZELtbQpIg1n/7Lkt562EChruz/2QS6V6Cw5ACAaKOwNe/wJSYdiLvAUAAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var wrapper = '_12xibal0';
export var content = '_12xibal1';
export var framedNotificationIcon = '_12xibal2';
export var v2NotificationCard = '_12xibal3';
export var toggleStyle = '_12xibal4';
export var dropDownStyle = '_12xibal5';
export var customNumberSelect = '_12xibal6';
export var engagementIconStyle = '_12xibal7';
export var viewershipIconStyle = '_12xibal8';
export var transactionIconStyle = '_12xibal9';
export var inputUnitText = '_12xibala';
export var settingsRow = _7a468({defaultClassName:'_12xibalb',variantClassNames:{hasDivider:{true:'_12xibalc'}},defaultVariants:{},compoundVariants:[]});
export var blueSvg = '_12xibald';