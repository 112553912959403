"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.moveToSiblingWidget = exports.removeWidgetAtPath = exports.newParagraphBelowWidgetAtPath = void 0;
var slate_1 = require("slate");
var Utils_1 = require("../Utils");
var EditorWidgetRegistry_1 = require("../WidgetRegistry/EditorWidgetRegistry");
var Paragraph_1 = require("../WidgetRegistry/Widgets/Paragraph");
var newParagraphBelowWidgetAtPath = function (editor, path) {
    var paragraphLocation = slate_1.Path.next(path);
    slate_1.Transforms.insertNodes(editor, (0, Paragraph_1.createParagraphNode)(), { at: paragraphLocation });
    var textLocation = __spreadArray(__spreadArray([], __read(paragraphLocation), false), [0], false);
    slate_1.Transforms.select(editor, { path: textLocation, offset: 0 });
};
exports.newParagraphBelowWidgetAtPath = newParagraphBelowWidgetAtPath;
var removeWidgetAtPath = function (editor, path) {
    slate_1.Transforms.select(editor, slate_1.Editor.start(editor, path));
    slate_1.Transforms.removeNodes(editor, { at: path });
};
exports.removeWidgetAtPath = removeWidgetAtPath;
var moveToSiblingWidget = function (editor, path, direction) {
    var selectedNodeEntry = slate_1.Editor.node(editor, path);
    if (!selectedNodeEntry) {
        return;
    }
    var _a = __read(selectedNodeEntry, 1), selectedNode = _a[0];
    if (!slate_1.Element.isElement(selectedNode)) {
        return;
    }
    var config = (0, EditorWidgetRegistry_1.getGenericConfigByType)(selectedNode.type);
    if (!config) {
        throw new Error("Cannot move to sibling widget on a non-widget-registry node");
    }
    if (config.nodeType === "layoutElement") {
        if (direction === "forwards") {
            var nextTextNode = Array.from(slate_1.Editor.nodes(editor, { at: path, match: slate_1.Text.isText, voids: true }))[0];
            if (!nextTextNode) {
                return;
            }
            var beginningOfNode = slate_1.Editor.start(editor, nextTextNode[1]);
            slate_1.Transforms.select(editor, beginningOfNode);
        }
        else {
            var prevTextNode = (0, Utils_1.getPrevious)(editor, path, slate_1.Text.isText);
            if (!prevTextNode) {
                return;
            }
            var endOfNode = slate_1.Editor.end(editor, prevTextNode[1]);
            slate_1.Transforms.select(editor, endOfNode);
        }
    }
    else {
        slate_1.Transforms.select(editor, path);
        slate_1.Transforms.move(editor, { unit: "line", reverse: direction === "backwards" });
    }
};
exports.moveToSiblingWidget = moveToSiblingWidget;
