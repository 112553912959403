"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAutomationIntegrationDisplayName = void 0;
var getAutomationIntegrationDisplayName = function (integrationName, automationsMetadata) {
    var integration = automationsMetadata.find(function (automationsMetadata) {
        return automationsMetadata.integrationName === integrationName;
    });
    return integration ? integration.displayName : integrationName;
};
exports.getAutomationIntegrationDisplayName = getAutomationIntegrationDisplayName;
